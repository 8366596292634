import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { LicenseManager } from "@ag-grid-enterprise/core";
import JssProvider from "react-jss/lib/JssProvider";
import { create } from "jss";
import { createGenerateClassName, jssPreset } from "@material-ui/core/styles";
import { AG_GRID_KEY } from "@icarius-utils/properties";
import { App } from "./app";
import store from "./store";
import "swiper/dist/css/swiper.min.css";
import "./styles/index.css";
import './styles/tailwind.css';
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham-dark.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";

LicenseManager.setLicenseKey(AG_GRID_KEY);

const generateClassName = createGenerateClassName();
const jss = create(jssPreset());
jss.options.insertionPoint = document.getElementById("jss-insertion-point");

ReactDOM.render(
  <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
      <App />
    </Provider>
  </JssProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
