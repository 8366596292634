import { getLocalizedErrorString } from "@icarius-localization/strings";

const errors = {
  DEFAULT_EXCEPTION: getLocalizedErrorString("defaultException"),
  INVALID_TOKEN_PROVIDED: getLocalizedErrorString("invalidToken"),
  NO_TOKEN_PROVIDED: getLocalizedErrorString("tokenMissing"),
  INVALID_PARAMETERS: getLocalizedErrorString("invalidParams"),
  USER_NOT_REGISTERED: getLocalizedErrorString("userNotFound"),
  PinValidationError: getLocalizedErrorString("pinValidationError"),
  INVALID_PARAMS: getLocalizedErrorString("invalidParams"),
  USER_NOT_FOUND: getLocalizedErrorString("userNotFound"),
  SERVER_ERROR: getLocalizedErrorString("serverError"),
  SOCIETY_NOT_FOUND: getLocalizedErrorString("societyNotFound"),
  INVALID_CREDENTIALS: getLocalizedErrorString("loginInvalidCredentials"),
  USER_ALREADY_EXISTS: getLocalizedErrorString("userAlreadyExists"),
  GOOGLE_DATA_ERROR: getLocalizedErrorString("googleDataError"),
  UNAUTHORIZED: getLocalizedErrorString("unAuthorized"),
  NO_EMPLOYEES: getLocalizedErrorString("emptyEmployees"),
  INVALID_IMAGES: getLocalizedErrorString("invalidImages"),
  INVALID_DATA: getLocalizedErrorString("invalidData"),
  ClientNotExist: getLocalizedErrorString("clientNotExist"),
  VALIDATION_ERROR: getLocalizedErrorString("validationError"),
  TOTEM_EXISTS: getLocalizedErrorString("totemAlreadyExists"),
};

export const getErrorStringFromError = error => {
  try {
    if (error.msg) return error.msg;
    return errors[error.response.data.status]
      ? errors[error.response.data.status]
      : getLocalizedErrorString("defaultError");
  } catch (e) {
    return getLocalizedErrorString("defaultError");
  }
};
