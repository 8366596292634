const stringsES = {
  spanish: "Español",
  english: "English",
  Personal: "Personal",
  create: "Crear",
  chat: "Chat",
  charts: "Gráficos",
  assignPIN: "Asignación de PIN",
  roleAccess: "Roles de acceso",
  icariusTech: "Tecnología de ICARIUS",
  createdBy: "Cargado por",
  contractEndDate: "Fecha término de contrato",
  maxCharPossible: "Max. permitido:",
  cellphoneVisible: "Su celular será visible",
  cellphoneVisibleShort: "Celular",
  civilStateVisible: "Su estado civil será visible",
  civilStateVisibleShort: "Estado civil",
  bornDateVisible: "Su fecha de nacimiento será visible",
  bornDateVisibleShort: "Fecha de nacimiento",
  personalAddressVisible: "Su dirección particular será visible",
  personalAddressVisibleShort: "Dirección particular",
  showUnreadAlerts: "No leídos",
  nineBoxSettings: "Definición de 9-Boxes",
  talentClassification: "Clasificación del talento",
  performanceEvaluations: "Evaluaciones del desempeño",
  performanceEvaluationsCalibration: "Calibración del desempeño",
  performanceSummaryAndResults: "Resumen y resultados",
  forms: "Formularios",
  performanceManagement: "Gestión del desempeño",
  talentAcquisition: "Adquisición del talento",
  showAllAlerts: "Todos",
  alertsFilterBy: "Filtrar por",
  references: "Referencias",
  comingSoon: "Proximamente",
  completeFields: "Existen valores no completados en el formulario",
  offsetY: "Desplazamiento Y",
  offsetX: "Desplazamiento X",
  alertChangeProfilePhoto:
    "Apreciado colaborador: Si usted quiere cargar o cambiar su fotografía, le solicitamos que sea una fotografía personal y para uso corporativo. Muchas gracias por su colaboración.",
  zoom: "Zoom",
  rotation: "Rotación",
  news: "Noticias",
  uploadNewPhoto: "Cargar nueva foto",
  modifyMyPhoto: "Modificar foto de perfil",
  correctlyModifiedPhoto: "Su foto se modificó correctamente",
  dataUpdate: "Actualización de datos",
  correctlyModifiedPersonalData: "Su información personal se actualizó correctamente",
  myPersonalData: "Datos personales",
  rightPannelSwitchIndication: "Indique si los siguientes datos personales serán visibles en el Directorio",
  help: "Ayuda",
  personales: "Personales",
  contrato: "Contrato",
  remuneracion: "Remuneración",
  ubicacion: "Ubicación",
  tiempos: "Tiempos",
  timeZones: "Zonas horarias",
  //Chat
  selectContactToStartConversation: "Seleccione un contacto",
  startConversation: "Inicie una conversación",
  messagePlaceholder: "Escribe un mensaje...",
  sombrear: "Resaltar",
  //Color
  selectColorTitle: "Seleccione un color",
  theme: "Temas y colores",
  color: "Color",
  green: "Verde",
  red: "Rojo",
  lightBlue: "Celeste",
  blue: "Azul",
  gold: "Oro",
  orange: "Naranja",
  silver: "Plata",
  teal: "Teal",
  purple: "Violeta",
  pink: "Rosa",
  brown: "Marrón",

  of: "de",
  pinWillChange: "Cambio o asignación de PIN",
  recoverPinDialogText: "El pin para el usuario {user} y cliente {client} se cambiará o asignará",
  recoverPinDialogTextFull: "El pin para el usuario {user}, cliente {client} y sociedad {society} se cambiará o asignará",
  albanyTechnologies: "Albany Technologies",

  //contacto
  contact: "Contacto",
  undefinedContact: "No se han definido datos de contacto",
  aboutUsTitle: "ICARIUS (ODESA)",

  //My Prople
  thisPage: "Exportar página",
  allPages: "Exportar todos",
  myPeopleTableTitle: "Mi Personal",
  everyone: "Todos",
  activeAndPlantPersonnel: "Vigentes y de planta ",
  id: "Identificador",
  code: "Código de empleado",
  name: "Nombre",
  nameAndSurname: "Apellido y nombres",
  liquidoNeto: "Liquido/Neto",
  income: "Fecha de ingreso",
  coin: "Moneda",
  salary: "Sueldo/Salario",
  filter: "Filtrar",
  close: "Cerrar",
  contactQr: "QR de contacto",
  export: "Exportar",
  searchLabel: "Buscar",
  filterBy: "Filtrar por",
  noEmployeesToShow: "Sin empleados para mostrar",
  newAnnotation: "Ingrese nueva anotación",
  noAnnotationsOfThisType: "No existen anotaciones del tipo seleccionado",
  noAnnexesForThisFilter: "No existen archivos para los filtros seleccionados",
  selectType: "Seleccionar tipo / Resetear",
  selectInstance: "Seleccionar instancia / Resetear",
  iconNewAnnotation: "Nueva anotación",
  iconEditAnnotation: "Editar anotación",
  merit: "Mérito",
  demerit: "Demérito",
  noType: "Anotación sin clasificación",
  noSubtype: "Sin subtipo",
  subtypeTitle: "Por subtipo de anotación",
  categoryTitle: "Por categoría de la anotación",
  meritsTitle: "Por méritos y deméritos",
  noAnnotations: "No posee anotaciones",
  annotationsConfirm: "¿Desea eliminar la anotación?",
  visibleColaborador: "Visible para el colaborador",
  invisibleColaborador: "No visible para el colaborador",
  availableColaborador: "Disponible para el colaborador",
  notAvailableColaborador: "No disponible para el colaborador",
  maxChar: "Max. permitido: 512",
  currentChar: "Cant. caracteres:",
  enterText: "Ingrese Texto",
  fieldRequeriment: "Todos los campos son obligatorios.",
  type: "Tipo",
  instance: "Instancia",
  subtype: "Subtipo",
  lastStatus: "Último status",
  status: "Status",
  viewAnnotationTitle: "Número de anotación, categoría y subtipo",
  viewAnnotationTime: "Fecha y hora de creación",
  viewAnnotationNewTime: "Fecha y hora de modificación",
  viewAnnotationVisibility: "Visibilidad",
  viewAnnotationContent: "Contenido de la anotación",
  viewAnnexVisibility: "Disponible",
  iconNewAnnex: "Nuevo archivo",
  viewAnnexTitle: "Número de archivo, instancia y tipo",
  viewAnnexContent: "Texto del archivo",
  selectAnnex: "Seleccione archivo",
  viewAnnexFile: "Documento del archivo",
  annexConfirm: "¿Desea eliminar el archivo?",
  iconEditAnnex: "Editar archivo",
  annexNoType: "Sin tipo",
  maxCharAnnex: "Max. permitido: 250",
  documentsListTitle: "Listado de archivos",
  noAnnexYet: "Aún no ha seleccionado archivo",
  clicIndication: "Clic en gráficos para desplegar listado",
  downloadAnnexFolder: "Descargar carpeta",
  downloadAnnexFolderConfirm: "¿Desea descargar la carpeta?",
  noAnnexes: "No posee archivos",
  annexFechaVencimiento: "Fecha de vencimiento",
  annexDateHelper: "Si ingresa fecha de Vencimiento, la misma debe ser superior a la fecha actual",
  annexRequired: "Obligatorio",
  annexNotRequired: "No Obligatorio",
  expires: "Vence el:",
  by: ", por ",
  notAvailable: "No disponible",
  newEmployee: "Nuevo empleado",
  scheduleProgrammed: "Horario programado para hoy",
  assistanceStatus: "Estado de asistencia",
  //
  documents: "Documentos",
  contracts: "Contratos",
  payroll: "Nómina",
  absences: "Ausencias",
  times: "Tiempos",
  familiarGroup: "Grupo familiar",
  fatherLastName: "Apellido paterno",
  firstName: "Primer nombre",
  employeeNumber: "Número de empleado",
  officePhone: "Teléfono de oficina",
  particularPhone: "Teléfono particular",
  bloodType: "Grupo sanguíneo",
  age: "Edad",
  passportNumber: "Número de pasaporte",
  expirationPassport: "Expiración del pasaporte",
  motherLastName: "Apellido materno",
  secondName: "Segundo nombre",
  extension: "Extensión",
  donante: "Donador",
  contractCode: "Código de contrato",
  contractAmount: "Cantidad de contrataciones",
  antiquityDate: "Fecha de antigüedad",
  contractMotive: "Motivo de contratación",
  legalMotive: "Motivo de termino legal",
  calculationMotive: "Motivo de termino calculo",
  familyAllowanceSection: "Tramo asignacion familiar",
  additionalMonthVacation: "Meses adicional vacaciones",
  additionalVacationDate: "Fecha adicional vacaciones",
  additionalDaysVacation: "Días adicional vacaciones",
  previsionInstitution: "Institución de previsión",
  affiliationDate: "Fecha afiliación previsional",
  healthInstitution: "Institución de salud",
  heavyWork: "Trabajo pesado",
  extremeZoneReduction: "Rebaja zona extrema",
  extremeZonePercentage: "Porcentaje zona extrema",
  taxOtherCountry: "Impone en otro país",
  valueOtherCountry: "Valor cotizado en otro país",
  ineClassif: "Clasificación INE",
  specialCapabilities: "Capacidades especiales",
  businessSalary: "Sueldo empresarial",
  icariusMenuProfile: "Perfil menú ICARIUS",
  calendarType: "Tipo de calendario",
  scheduleControlType: "Tipo de control horario",
  cardCode: "Código de tarjeta/credencial",
  freedExtraHours: "Horas extras liberadas",
  canMarkMobile: "Puede marcar desde Móviles",
  canMarkICARIUS: "Puede marcar desde ICARIUS",
  canCapturePosition: "Puede capturar geoposición",
  updateOK: "Datos actualizados exitosamente",
  createdOK: "Empleado creado exitosamente",
  namesAndLastnames: "Apellidos y nombres",
  employeeMasterSidebarTitle: "Datos complementarios",
  accessPerm: "Permisos de acceso",
  otherData: "Otros datos",
  contratacionType: "Tipo de contratación",
  creationUserPhotoInfo: "Los cambios se reflejarán al crear el usuario",
  icariusProfile: "Perfil ICARIUS",
  groups: "Grupos",
  personalesTitle: "personales",
  ubicacionTitle: "ubicacion",
  tiemposTitle: "tiempos",
  contratoTitle: "contrato",
  remuneracionTitle: "remuneracion",

  //Receipts
  receiptsTableTitle: "Recibos",
  myreceiptsMessage: "Su comprobante de pago ha sido descargado y usted ya tiene registrada la aceptación del mismo con fecha {accepted_date} a las {accepted_time} hrs",
  receipts: "Comprobantes de pago",
  certificates: "Certificados",
  receiptsViewAll: "Todo",
  receiptsViewSignPending: "Pendientes de firma",
  receiptSignNotFound: "Documentos solicitados no encontrados",
  receiptSignFiltered: "Los datos se han filtrado con éxito para firmarlos digitalmente",
  receiptUnauth: "Usted no está no habilitado como firmante de documentos",
  receiptWrongPing: "PIN ingresado incorrecto",
  receiptWrongDocument: "Ocurrió un problema con alguno de los documentos seleccionados, intente nuevamente",
  receiptSuccess: "Recibos firmados exitosamente",

  // Certificates
  certificatesViewAll: "Todo",
  certificatesViewSignPending: "Documentos para firmar",
  certificateSignNotFound: "Documentos solicitados no encontrados",
  certificateSignFiltered: "Los datos se han filtrado con éxito para firmarlos digitalmente",
  certificateUnauth: "Usted no está no habilitado como firmante de documentos",
  certificateWrongPing: "PIN ingresado incorrecto",
  certificateWrongDocument: "Ocurrió un problema con alguno de los documentos seleccionados, intente nuevamente",
  certificateSuccess: "Certificados firmados exitosamente",

  //People grid
  hrefState: "",
  employeeCode: "Código de empleado",
  names: "Nombres",
  internalNumber: "Número interno",
  state: "Estado",
  email: "Email",
  fType: "Tipo de funcionario",
  position: "Cargo",
  positionDate: "Fecha asignación cargo",
  function: "Función",
  functionDate: "Fecha asignación función",
  civilState: "Estado civil",
  sex: "Sexo",
  nationality: "Nacionalidad",
  category: "Categoría",
  managementCenter: "Centro de publicación",
  specialty: "Especialidad",
  bornDate: "Fecha de nacimiento",
  linkedin: "LinkedIn",
  photo: "",
  direcMaps: "",
  address: "Dirección",
  comuna: "Comuna",
  provicia: "Provincia",
  region: "Región",
  country: "País",
  postalCode: "Código postal",
  cellphone: "Celular",
  phoneExtension: "Extensión telefónica",
  contractType: "Tipo de contrato",
  incomeDate: "Fecha de contratación",
  outcomeDate: "Fecha de egreso",
  paymentLocation: "Lugar de pago",
  jobLocation: "Lugar de trabajo",
  management: "Gerencia",
  department: "Departamento",
  section: "Sección",
  paymentMethod: "Forma de pago",
  bank: "Banco",
  bankAccount: "Cuenta bancaria",
  bankAccountType: "Tipo de cuenta",
  salaryUnit: "Unidad salarial",
  jornadaType: "Tipo de jornada",
  hoursPerDay: "Horas diarias contrato",
  hoursPerMonth: "Horas mensuales contrato",
  laborUnion: "Sindicato",
  retired: "Jubilado",
  shift: "Turno",
  shiftDate: "Fecha asignación turno",
  comments: "Comentarios",
  opinion: "Opinión",
  workPhone: "Teléfono del trabajo",
  phone: "Teléfono",
  responsibleEmail: "Email del responsable de la respuesta",
  responsiblePhone: "Teléfono del responsable de la respuesta",
  responsibleName: "Responsable de la respuesta",
  requester: "Solicitante",
  toExcel: "a Excel",
  toJson: "a JSON",
  toXml: "a XML",
  toTxt: "a TXT",
  toPdf: "a PDF",
  plantPersonnel: "Personal de planta",
  contratista: "Contratista",
  centroDeGestion: "Centro de publicación",
  noFaceFound: "La imagen no parece contener un rostro humano",
  fileSameName: "Ya existe un archivo con el mismo nombre",
  fileTypeNotValid: "El tipo de archivo no está admitido",
  vigente: "Vigente",
  noVigente: "No vigente",
  allAnnotations: "Todas las anotaciones",
  annotationsByDate: "Desde última contratación",
  allAnnexes: "Todos los archivos",
  annexesByDate: "Desde última contratación",

  //Change pin
  changingPin: "Cambiar mi PIN",
  currentPin: "PIN actual",
  newPin: "Nuevo PIN",
  newPinConfirmation: "Repita su nuevo PIN",
  pinCorrectlyChanged: "PIN cambiado correctamente. Utilice su nuevo PIN en el próximo acceso, ya sea en la Plataforma WEB o en la APP",
  pinRegenerationConfirmed:
    "Revise nuevamente su casilla de correo porque se le ha enviado un PIN de acceso transitorio.",
  mailSent:
    "Se han enviado a su correo electrónico las indicaciones para la restauración de su PIN de acceso.",
  //Dialog
  portal: "Portal del {role}",
  toolbarTitle: "Albany Technologies",
  disagree: "Cancelar",
  agree: "Aceptar",
  atention: "Atención",
  yes: "Si",
  no: "No",
  sentTo: "Enviada a",
  managerPhone: "Teléfono del receptor",
  managerEmail: "Email del receptor",
  lastActionTime: "Hora última acción",
  lastActionDate: "Fecha última acción",
  //Login
  loginSubtitle: "Auto consulta colaborativa del personal",
  version: "Versión",
  loginAs: "Ingresar como",
  collaborator: "Colaborador",
  collaborators: "colaboradores",
  manager: "Manager",
  employer: "Empleador",
  recoverPinAccessCode: "Recuperar PIN de acceso",
  copyright: "Copyright (C) {year} ICARIUS",
  allRightsReserved: "Todos los derechos reservados",
  credits: "Créditos",
  icariusMail: "soporte@icariusglobal.com",
  albanyWebsite: "albany.company",
  icariusWebsite: "www.icariusglobal.com",
  icariusForoWebsite: "forum.icariusglobal.com",
  client: "Cliente",
  user: "Usuario",
  pin: "PIN",
  key: "Clave",
  selectSocietyTitle: "Seleccione una sociedad",
  society: "Sociedad",

  //Main - Sliders
  favouriteSliderTitle: "Enlaces de utilidad",
  downloadSliderTitle: "Documentos de interés",
  pollSliderTitle: "Encuestas",
  countersSliderTitle: "Números",
  //Default Nav
  directory: "Directorio",
  alerts: "Alertas y avisos",
  notices: "Avisos",
  myPin: "Mi PIN",
  closeSession: "Cerrar sesión",
  menuConfigurator: "Configuración de menú",
  onboardingProfiles: "Perfiles de onboarding",
  //Nav
  typesOfPublicationFormats: "Tipos de formatos para las publicaciones",
  typesOfOnboardingActions: "Tipos de acciones de onboarding",
  typesOfPublicationChannels: "Tipos de canales para las publicaciones",
  classificationApplicantsTypes: "Tipos de clasificación para postulantes",
  loginTitle: "Log In",
  mainTitle: "Inicio",
  myAssistanceTitle: "Mi asistencia",
  assistanceTitle: "Marcas de asistencia",
  myPeopleTitle: "Mi personal",
  nineBoxClassification: "Clasificación 9-Boxes",
  chatTitle: "Chat",
  certificateFullTitle: "Comprobantes y certificados",
  myCertificateFullTitle: "Mis comprobantes y certificados",
  myCertificatesConfirmationTitle: "¿Acepta el certificado generado?",
  receiptsFullTitle: "Recibos y comprobantes de pago",
  receiptsShortTitle: "Comprobantes de pago",
  myReceiptsFullTitle: "Mis recibos y comprobantes de pago",
  myHistoricalReceiptsFullTitle: "Mis recibos y comprobantes de pago históricos",
  myReceiptsConfirmationTitle: "¿Acepta el recibo generado?",
  confirmReceipt: "Motivo por el cuál no está conforme con el recibo generado",
  deniedDocumentMessage: "Por favor ingrese el motivo del rechazo del documento",
  certificateTitle: "Certificados",
  myCertificateTitle: "Mis certificados",
  noCertificates: "No posee certificados",
  selectCertificate: "Comprobante o certificado",
  noReceipts: "No existen recibos con firma digital disponibles para descarga",
  selectReceipt: "Recibo o comprobante de pago",
  selectCalcMethod: "Proceso de cálculo",
  confirmCertificateReceipt:
    "Motivo por el cuál no está conforme con el certificado generado",
  holidaysTitle: "Cta. cte. vacaciones",
  loansTitle: "Cta. cte. préstamos",
  loansTitleLong: "Cuenta corriente de préstamos",
  myAnnotationsTitle: "Mis anotaciones",
  myAnnexesTitle: "Mi carpeta digital",
  myInfoTitle: "Mis datos",
  receiptsTitle: "Recibos",
  myReceiptsTitle: "Mis recibos",
  myHistoricalReceiptsTitle: "Mis recibos históricos",
  requestsTitle: "Solicitudes",
  requestsControlTitle: "Control de solicitudes",
  schedulerTitle: "Hoja de tiempos",
  decisionCubes: "Cubos de gestión",
  kpiIndicators: "Indicadores (KPI)",
  query: "Consultas",
  eventsIncome: "Importación de eventos",
  eventsAuth: "Autorización de eventos",
  employeeMaster: "Maestro de personas",
  myKpiIndicators: "Mis indicadores (KPI)",
  myPending: "Mis pendientes",
  myPendingEmployer: "Mis pendientes",
  myQueries: "Mis consultas",
  queries: "Consultas",
  myDigitalSign: "Mi firma digital",
  myDecisionCubes: "Mis cubos de gestión",
  groupEvents: "Eventos por grupos",
  individualEvents: "Eventos individuales",
  parametrization: "Parametrización",
  myRequests: "Mis solicitudes",
  myRequestsManager: "Mis solicitudes",
  myRequestsSecondGrid: "myRequestsSecondGrid",
  myPendingSecondGrid: "myPendingSecondGrid",
  timeManagement: "Gestión de tiempos",
  engagement: "Engagement",
  managementNav: "Gestión y análisis",
  calculationManagement: "Gestión de cálculos",
  Remunerations: "Remuneraciones",
  settings: "Ajustes",
  changeTheme: "Cambiar tema",
  requestedDocuments: "Documentos solicitados",
  signedDocuments: "Documentos firmados",
  digitalReceipts: "Recibos digitales",
  digitalCertificates: "Certificados digitales",
  themeAndColors: "Color corporativo",
  generalSettings: "Parámetros generales",

  //Polls
  next: "Siguiente",
  previous: "Anterior",
  pollInfo: "Pregunta {actualNumber} de {totalNumber}",
  submit: "Enviar",
  gratitudMessage: "¡Muchas gracias por participar en nuestra encuesta!",
  alreadyAnswered: "Usted ya participó de esta encuesta",
  aboveMaximumPoll: "Contiene respuestas que sobrepasan el limite",
  required: "Requerido",
  errorConnection: "Ocurrio un error al comunicarse. Intente nuevamente",
  anonymousPoll: "Esta encuesta es anónima y de carácter no vinculante",
  //Grid
  gridCopy: "Copiar",
  gridCopyWithHeaders: "Copiar con encabezados",
  gridExport: "Exportar",
  group: "Grupo",
  //Directorio
  directoryTableTitle: "Directorio",
  tarjetero: "Tarjetero",
  directAccess: "Acceso directo",
  messageFrom: "Mensaje de ",
  read: "Leído",
  markAsRead: "Marcar como leído",
  markAsUnread: "Marcar como no leído",
  selectQuery: "Seleccionar consulta",
  annotations: "Anotaciones",
  annex: "Carpeta Digital",
  confirmDelete: "¿Desea eliminar la plantilla?",
  confirmUpdate: "¿Desea actualizar la plantilla?",
  save: "Guardar",
  statistics: "Estadística",
  template: "Nombre de plantilla",
  createTemplate: "Crear plantilla",
  deleteTemplate: "Eliminar plantilla",
  exportTemplate: "Exportar plantilla",
  updateTemplate: "Actualizar plantilla",
  exportQuery: "Exportar consulta",
  importQuery: "Importar consulta",
  importTemplate: "Importar plantilla",
  selectTemplate: "Seleccionar plantilla / Resetear",
  templateExclusiveToMe: "Exclusiva para mi",
  templateAllCollaborators: "Todos los colaboradores",
  templateAllManagers: "Todos los managers",
  templateAllEmployers: "Todos los empleadores",
  templateOnlyEmployersPlantPersonnel: "Solo empleadores de planta",
  templateOnlyProvidersEmployers: "Solo empleadores proveedores",
  templateCreate: "Plantilla creada con éxito",
  templateImport: "Plantilla importada con éxito",
  templateUpdate: "Plantilla actualizada con éxito",
  templateDelete: "Plantilla borrada con éxito",
  //encuestas
  pollReview: "Análisis de encuestas",
  question: "Pregunta",
  visualizationRol: "Rol de visualización",
  delete: "Eliminar",
  confirmDeletAlert: "¿Desea eliminar la alerta o aviso?",
  shrink: "Contraer todo",
  expand: "Expandir todo",
  answerPollNotice: "Debe responder la pregunta para continuar",
  myAssistenceTitle: "Mi asistencia",
  everyPollAnswer: 'Todas las respuestas de "{answer}"',
  countVoters: "Cantidad de votantes",
  cant: "Cantidad",
  questions: "Preguntas",
  noPollReviewData: "Aún no se han registrado datos de encuestas.",
  hour: "Hora",
  assistancePlace: "Lugar de asistencia",
  date: "Fecha",
  votos: "Votos",
  noAlertsOrNotification: "No existen alertas o avisos.",
  noUnreadAlertsOrNotification: "No existen alertas o avisos sin leer.",
  info: "Información",
  clickHereForDetailedAnswers: "Haga clic aquí para leer las respuestas",
  respuestaParentesis: " respuesta)",
  respuestasParentesis: " respuestas)",
  answer: "Respuesta",
  responsible: "Responsable",
  //KPIs
  noKPIdata: "No existen KPI disponibles por el momento.", //"Aún no se han registrado datos de KPI.",
  aboutUs: "Acerca de ICARIUS",
  inDate: "Fecha de entrada",
  inHour: "Hora de entrada",
  outOfZoneIN: "Fuera de zona (ENTRADA)",
  outDate: "Fecha de salida",
  outHour: "Hora de salida",
  outOfZoneOUT: "Fuera de zona (SALIDA)",
  generate: "Generar",
  total: "Total",
  permissions: "Permisos",
  //Loans
  loansDetailEmployer: "loansDetailEmployer",
  loansDetailCollaborator: "loansDetailCollaborator",
  numberLoansGranted: "Cantidad de préstamos otorgados",
  totalValueLoansGranted: "Valor total de préstamos otorgados",
  totalPaid: "Total pagado",
  totalPendingBalance: "Total saldo pendiente",
  totalInstallments: "Total cuotas",
  totalPaidInstallments: "Total cuotas pagadas",
  totalPendingInstallments: "Total cuotas pendientes",
  referenceCode: "Código de Referencia",
  referenceInstallment: "Cuota de referencia",
  installmentValue: "Valor de la cuota",
  periodDiscount: "Período de descuento",
  installmentState: "Estado de la cuota",
  installmentNumber: "N° de cuota",
  grantedValueLoan: "Valor del préstamo otorgado",
  noLoansToShow: "El formato actual de la grilla no permite graficar. Para poder hacerlo, deje la grilla en su formato de visualización inicial.",
  value: "Valor",
  //Solicitudes
  vacationRequestTitle: "Vacaciones",
  permissionRequestTitle: "Permisos",
  loanRequestTitle: "Anticipos",
  expenseRequestTitle: "Reintegro de gastos",
  permissionType: "Tipo de permiso",
  vacationsType: "Tipo de vacaciones",
  holidays: "Vacaciones", //No tocar
  expenses: "Gastos", //No tocar
  advance: "Anticipo", //No tocar
  permission: "Permisos", //No tocar
  raise: "Aumento de sueldo", //No tocar
  firing: "Desvinculación", //No tocar
  requestRequested: "Solicitada", //No tocar
  requestCancelled: "Anulada", //No tocar
  requestChangedProposed: "Propuesta de cambio", //No tocar
  requestChangeAccepted: "Cambio aceptado", //No tocar 
  requestChangeRejected: "Cambio rechazado",//No tocar
  requestApproved: "Aprobada", //No tocar
  requestRejected: "Rechazada", //No tocar
  loanType: "Tipo de anticipo",
  loanType2: "Tipo de préstamo",
  startDate: "Fecha de inicio",
  newDelegation: "Crear delegación",
  startDateValidation: "La fecha debe ser posterior al día actual",
  delegationEndDateValidation: "La fecha debe ser posterior a la fecha de inicio",
  editStartDateValidation: "La fecha debe ser posterior a la fecha original",
  addressee: "Destinatario",
  endDate: "Fecha de término",
  amountOfDays: "Cantidad de días",
  timePerDay: "Tiempo por día",
  detail: "Detalle",
  loanDetail: "Detalle del préstamo",
  amountLoanRequested: "Valor del anticipo solicitado",
  amountLoanRequestedValidation: "El valor debe ser mayor a 0 y menor a ",
  expectedDateOfPay: "Fecha esperada de cobro",
  fieldRequired: "Campo obligatorio",
  expenseDeleteConfirmation: "¿Desea borrar el gasto?",
  newExpenseItem: "Nuevo gasto",
  noExpenses: "Aún no ha creado ningún gasto.",
  expenseAmount: "Valor del gasto",
  requestsInHistory: "Históricas",
  requestsInProgress: "En proceso",
  requestHistory: "Historial de la solicitud",
  cantCreateNewRequest: "No es posible realizar una nueva solicitud de este tipo, porque ya existe una en curso",
  legalVacation: "Vacaciones legales",
  additionalVacation: "Vacaciones adicionales",
  fullDay: "Día completo",
  halfDay: "Medio día",
  cancelRequest: "Anular",
  answerPropose: "Responder propuesta",
  approveRequest: "Aprobar",
  rejectRequest: "Rechazar",
  editRequest: "Editar",
  optionalField: "Opcional",
  maxCharRequests: "Max. permitido: 1024",
  howToSelectMoreDays: "Si desea ingresar más días que los Solicitados, rechace la solicitud indicándole al Colaborador la cantidad de días que propone en el campo de Detalle.",
  requestEditTitle: "Propuesta de Cambio en la Solicitud de",
  requestCreateTitle: "Solicitud de",
  requestBy: "Responsable del último status",
  sendMessage: "Enviar mensaje",
  youNeedAtLeastOneRow: "Debe seleccionar al menos un registro.",
  requestMailConfirm: "¿Desea enviar, a los responsables seleccionados, un mensaje de advertencia para la pronta atención de las solicitudes?",
  mailsHaveBeenSent: "El mensaje fue enviado con éxito",
  docsHaveBeenDeleted: "Se eliminaron con éxito {numberOfDocuments} documentos pendientes de aprobación",
  delegatorName: "Nombre de quien delega",
  delegatorCode: "Código de quien delega",
  delegatorEmail: "Email de quien delega",
  delegatedName: "Nombre del destinatario",
  delegatedCode: "Código del destinatario",
  delegatedEmail: "Email del destinatario",
  delegationDateFrom: "Fecha desde",
  delegationDateTo: "Fecha hasta",
  delegationSent: "Delegación enviada",
  delegationReceived: "Delegación recibida",
  delegation: "Delegación",
  delegationsTitle: "Delegar solicitudes",
  noDelegationsRows: "Usted no tiene delegaciones enviadas ni recibidas.",
  confirmDeleteDelegation: "¿Desea eliminar la delegación?",
  proposeChange: "Proponer cambio",
  answerChangeTitle: "¿Aprueba el cambio propuesto?",
  acceptRequest: "¿Aprueba la solicitud?",
  cancelRequestTitle: "¿Desea anular la solicitud?",
  insertComment: "Ingrese un comentario",
  delegationAlready: "Ya existe una delegación para ese usuario en esa fecha",
  matches: "Coincidencias",
  datesCollide: "El rango de fechas seleccionado se superpone con fechas de solicitudes previas",
  days: "días",

  //Query myQueries
  reply: "Responder",
  goBack: "Volver",
  errorExecutingQuery: "Error al ejecutar la consulta de usuario",
  noQueryGroups: "No existen grupos de consultas disponibles por el momento",
  invalidData: "Hay datos inválidos o incompletos",
  validData: "Todos los datos son válidos",

  //GRILLAS
  moreOrEqualThan: "Mayor igual",
  lessOrEqualThan: "Menor igual",
  notContains: "No contiene",
  contains: "Contiene",
  notEqual: "No igual",
  equal: "Igual",
  year: "Año",
  month: "Mes",
  localidad: "Localidad",

  gridVideo: "Grillas – Parte {}",
  pinChangedLogoutRequest: "El PIN ha sido modificado con éxito. Si tienes acceso a más de una sociedad para el mismo cliente, considera que el nuevo PIN asignado deberá ser utilizado para ingresar en todas las sociedades a partir de este momento. Si deseas permanecer en ICARIUS, por favor, vuelve a ingresar al sistema.",

  //Parameters,
  parameters: "Parámetros",
  // Sociedad
  changeSociety: "Cambiar sociedad",
  orderByUnread: "Ordenar por no leídos",
  visualizationGrid: "Grilla de visualización",
  viewMoreData: "Ver protección de datos",

  //Notes
  tagName: "Nombre de la etiqueta",
  tagTitle: "Etiquetas",
  createTag: "Nueva etiqueta",
  editTags: "Editar etiquetas",
  orderByNotes: "Ordenar por",
  ascOrder: "Ascendente",
  desOrder: "Descendente",
  archived: "Archivados",
  createNote: "Nueva tarea",
  labelCorrectlyDeleted: "Etiqueta eliminada correctamente",
  labelCorrectlyModified: "Etiqueta modificada correctamente",
  labelCorrectlyCreated: "Etiqueta creada correctamente",
  title: "Título",
  writeHereYourNote: "Escribe aquí tu tarea",
  edited: "Ult. edición",
  archive: "Archivar",
  unarchive: "Desarchivar",
  changeLabels: "Modificar etiquetas",
  addChecklist: "Agregar tarea",
  addImage: "Agregar imagen",
  addReminder: "Agregar recordatorio",
  deleteNote: "Eliminar tarea",
  cancel: "Cancelar",
  clear: "Limpiar",
  today: "Hoy",
  ok: "Aceptar",
  addItem: "Agregar ítem",
  addLabel: "Agregar etiqueta",
  confirmDeleteNote: "¿Desea eliminar la tarea?",
  //TotemMagement
  totemManagementTitle: "Gestión de tótems",
  noTotems: "No existen tótems disponibles",
  home: "Inicio",
  myNotes: "Mis tareas",
  notes: "Tareas",
  totemCorrectlyUpdated: "Tótem correctamente actualizado",
  totemCorrectlyDeleted: "Tótem correctamente eliminado",
  totemCorrectlyAdded: "Tótem correctamente agregado",
  entrada: "Entrada",
  salida: "Salida",
  promptDeleteTotem: "¿Desea eliminar el tótem?",
  totem: "Tótem",
  assistanceTotem: "Tótem de asistencia",
  newAssistanceTotem: "Nuevo tótem",
  totemCode: "Código",
  totemID: "ID tótem",
  workplace: "Lugar de asistencia",
  editTotem: "Editar tótem",
  createTotem: "Crear tótem",
  newTotem: "Nuevo tótem",
  totemAsociatedInThisDevice: "Este es el tótem está asociado a este equipo",
  noTotemFound: "Este tótem de registro de asistencia ha sido deshabilitado.",
  registryOK: "{Move} registrada con éxito.",
  invalidAssistanceCredentials: "Usted no puede registrar asistencia con el Cliente, Usuario y PIN que ha ingresado.",
  assistanceRegistryDisabled: "El registro de asistencia está deshabilitado. Póngase en contacto con un administrador.",
  assistanceLicenceExpired: "La licencia de uso de ICARIUS expiró. Póngase en contacto con un administrador.",
  cantRegisterAssistance: "Usted no está habilitado para marcar asistencia.",
  cantRegisterAssistanceVacation: "Usted no puede marcar asistencia hoy ya que se encuentra de vacaciones/licencia/permiso",
  registerBlocked: "Se ha bloqueado el registro de asistencia. Comuníquese con Recursos Humanos",
  registerMarkedInasistance: "No puede marcar asistencia porque ya existen inasistencias registradas para el día de hoy",
  //Holidays
  vacationsTitle: "Cta. cte. vacaciones",
  myVacationsTitle: "Cta. cte. vacaciones",
  vacationsTitleLong: "Cuenta corriente de vacaciones",
  viewVacationAccountability: "Ver saldos",
  totalAvailableBalance: "Saldo total disponible para solicitar",
  totalPlusUnusedAvailableBalance: "Saldo total disponible en caso de desvinculación (a la fecha de consulta)",
  additionalBalance: "Saldo adicionales",
  legalOthersBalance: "Saldo legales y otros",
  totalDebitUsedDays: "Total débitos (Días utilizados)",
  totalCredit: "Total créditos (Días ganados)",
  totalAdditionalDays: "Total de días adicionales",
  usedAdditionalDays: "Días utilizados (adicionales)",
  totalOtherDays: "Total de otros días",
  usedLegalDays: "Días utilizados (legales y otros)",
  totalOtherLegalDays: "Total de días legales",
  usedTotalDays: "Días utilizados o reservados totales",
  availableLegalDays: "Días legales y otros disponibles",
  availableAdditionalDays: "Días adicionales disponibles",
  legalDays: "Legales",
  additionalDays: "Adicionales",
  otherDays: "Otros",
  debits: "Débitos",
  description: "Descripción",
  pendingDays: "Días pendientes",
  movement: "Movimiento",
  dailyQuantity: "Cantidad diaria",
  daysQuantity: "Cantidad de días",
  availableTotalDays: "Días totales disponibles",
  // Firma digital
  digitalSignature: "Firma digital",
  DigitalSignatureTitle: "Firmantes",
  newSigner: "Nuevo firmante",
  promptDeleteSigner: "¿Desea eliminar el firmante?",
  authorizedDocuments: "Documentos autorizados",
  dateFrom: "Fecha desde",
  hourFrom: "Hora desde",
  dateEnd: "Fecha hasta",
  hourEnd: "Hora hasta",
  activeSigner: "Firmante activo",
  inactiveSigner: "Firmante inactivo",
  editSigner: "Modificar firmante",
  noSigners: "No hay firmantes registrados",
  signer: "Firmante",
  active: "Activo",
  inactive: "Inactivo",
  signerCorrectlyDeleted: "Firmante correctamente eliminado",
  signerCorrectlyAdded: "Firmante correctamente creado",
  signerCorrectlyUpdated: "Firmante correctamente actualizado",
  edit: "Editar",
  autoGeneratedBySign: "Auto generado por firma Digital - Firmante: {signer}",
  autoGeneratedBySystem: "Auto generado por el sistema",
  requestsColabInstructivo: "Solicitudes del colaborador",
  ctaCteVacacionesInstructivo: "Instructivo Cta. cte.",
  confirmDeleteAllReadAlerts: "¿Confirma que desea eliminar todas las alertas y avisos leídos?",
  deleteAllReadAlerts: "Eliminar todos los leídos",
  nameSurnameLegalSigner: "Firmante legal",
  captionLegalName: "Si el firmante legal es distinto al seleccionado, ingrese sus apellidos y nombres",
  expectedDateOfDisengagement: "Fecha esperada para la desvinculación",
  disengagementOperativeType: "Motivo para el registro en las anotaciones del colaborador",
  disengagementLegalType: "Tipo de desvinculación legal",
  detailDisengagement: "Detalle el causal de la solicitud de desvinculación",
  mailRequestedDocuments: "¿Desea enviar a los destinatarios correspondientes un mensaje para la pronta atención de los documentos pendientes de aprobación?",
  noDocumentsForMail: "No se encuentran documentos pendientes de aprobación",

  //Request Documents
  requestDocuments: "Solicitar documentos",
  requestDocumentsTitle: "Solicitar documento con firma digital",
  request: "Solicitar",
  noDocumentsRequest: "No existen documentos con firma digital disponibles por el momento",
  selectDocument: "Seleccione documento",
  documentRequestDuplicated: "Ya existe una solicitud en curso para este tipo de documento",
  documentRequestOK: "Solicitud enviada",

  requestedDocumentsTitle: "Documentos solicitados",
  requestDate: "Fecha de solicitud",
  requestHour: "Hora de solicitud",
  typeRequestedDoc: "Tipo de documento solicitado",
  codeRequestedDoc: "Código de documento solicitado",
  sign: "Firmar",
  confirmPin: "Confirmar PIN",

  //Signed Documents  
  download: "Descargar",
  signDate: "Fecha de la firma",
  signTime: "Hora de la firma",
  signDocType: "Tipo de documento",
  signDocCod: "Código de documento",
  signDocName: "Nombre de documento",
  signRef: "Referencia",
  signerCode: "Código del firmante",
  signerFullName: "Apellido y nombre del firmante",
  signerCodeTo: "Código del destinatario",
  signerToFullName: "Apellido y nombres del destinatario",
  succesfullySignedDocs: "Se han firmado digitalmente {cant} documentos",

  // Solicitudes II
  requestReceptor: "Receptor de la solicitud",
  disengagementRequestedFor: "Desvinculación solicitada para",
  reasonToRegisterInDigitalFolder: "Motivo para registrar en las anotaciones del colaborador",
  disengagement: "desvinculación",
  payRise: "aumento de sueldo",
  disengagementTitle: "Desvinculación",
  payRiseTitle: "Aumento de sueldo",
  payRiseRequestedFor: "Aumento solicitado para",
  myReceiptsConfirmDocNotExist: "Aún no está disponible el documento firmado digitalmente. ¿Desea descargarlo de todos modos sin firma digital?",
  previewDocuments: "Previsualizar",
  previewDocumentsTooltip: "Previsualizar antes de firmar",
  signTooltip: "Firmar documentos previsualizados",
  confirmAcceptRequestedDocuments: "¿Confirma realizar la firma digital de los documentos seleccionados?",
  confirmDeleteRequestedDocuments: "¿Confirma realizar la eliminación de los documentos seleccionados?",
  reason: "Motivo",
  legalReason: "Motivo legal",
  expectedDate: "Fecha esperada",
  newProposedSalary: "Valor solicitado",
  requestedBy: "Solicitada por",
  derivate: "Derivar",
  derivationSuccessfull: "La solicitud se ha derivado con éxito",
  derivateRequestTitle: "Derivación de solicitud",
  firingLegalType: "Tipo de desvinculación legal",
  multipleSeverances: "Se grabaron los datos de la Desvinculación en el Maestro de Personas, sin embargo se encontró más de UN TIPO DE FINIQUITO habilitado como NOMINA y se HABILITÓ AL COLABORADOR en todos los tipos encontrados, para revisar esta situación, acceda a la función: MAESTRO DE PERSONAS – Datos complementarios – Botón: Nóminas",
  frequency: "Frecuencia",
  actualSalary: "Sueldo actual",
  salaryCoin: "Moneda del sueldo",
  payRiseExpectedDate: "Fecha esperada de aumento",
  newSalaryValue: "El nuevo valor del sueldo",

  signedDocumentsMailErrorTitle: "Los siguientes mail no se han podido enviar",
  signedDocumentsDeleteErrorTitle: "Los siguientes documentos no se han podido eliminar",
  signedDocumentsDocumento: "Documento",
  signedDocumentsDestinatario: "Destinatario",
  signedDocumentsMotivo: "Motivo",
  signedDocumentsNotRequired: "Aprobación no requerida",
  signedDocumentsInvalidDocument: "Documento inválido",
  errorAwaitingColaboratorConfirmationDeleteDocs: "No es posible eliminar éste documento porque pertenece a una solicitud que ya ha sido aprobada y se está esperando el conforme del Colaborador",

  settingDocuments: "Preparando documentos",

  labelRowsPerPage: "Alertas por página",
  nextPage: "Página siguiente",
  previousPage: "Página anterior",
  moreThan: "mas de",
  deletedDocumentsMotivo: "Motivo",
  confirmDeleteDocument: "Confirmación de eliminación de documentos",
  invalidDataDeleteDocs: "No se encuentra como aprobante del codigo de documento el destinatario enviado",
  invalidStatusDeleteDocs: "El documento no está pendiente de firma",
  invalidSignerDeleteDocs: "Firmante inválido",

  generateAndDownloadNoSign: "Generar y descargar",
  generateAndDownloadWithSign: "Generar y descargar con firma digital",
  youCanOnlyDeleteDocumentsWithPendingApproval: "Solamente se pueden eliminar documentos pendientes de aprobación",
  qrExampleDigitalSignCertificatesReceipt: "Ejemplo de su firma digital que se insertará en el documento - Aceptación o Rechazo",

  socketDisconnect: "Ha ocurrido un error inesperado. A continuación será redirigido al login de acceso de la plataforma.",
  youNeedAtLeastOneRowAprovalPending: "Debe seleccionar al menos un registro. El estado sólo puede ser PENDIENTE DE APROBACIÓN.",

  requestCreatedOK: "Solicitud creada con éxito",
  requestDeletedOK: "Solicitud anulada con éxito",
  requestChangeSentSnack: "Propuesta de cambio enviada con éxito",
  requestChangeAcceptedSnack: "Aceptación de la propuesta de cambio enviada con éxito",
  requestChangeRejectedSnack: "Rechazo de la propuesta de cambio enviada con éxito",
  requestAccetedSnack: "La solicitud fue aprobada con éxito",
  requestRejectedSnack: "La solicitud fue rechazada con éxito",
  newSalaryMustBeGreaterThanPreviousSalary: "El sueldo propuesto debe ser superior al sueldo actual del Colaborador",

  signUnauthDocuments: "Usted no está no habilitado para firmar los documentos seleccionados",
  idFiscal: "ID Fiscal",
  companyName: "Nombre empresa",
  quantityDocsSigned: "Documentos firmados",
  dateTimeSign: "Fecha y hora de firma",
  idFiscalReceptor: "ID Fiscal receptor",
  receiver: "Receptor",
  viewCompanies: "Ver empresas",
  documentInspection: "Fiscalización de documentos",

  registerAssistance: "Registrar asistencia",
  yourLastAssistanceEntryIs: "Su último registro de asistencia ha sido el día: ",
  lastAssistanceEntryOrigin: "El origen de dicho registro fue:",
  lastAssistanceEntryOriginTotemICARIUS: "Tótem de ICARIUS",
  lastAssistanceEntryOriginWebICARIUS: "Web de ICARIUS",
  lastAssistanceEntryOriginAppICARIUS: "App de asistencia de ICARIUS",
  lastAssistanceEntryOriginClock: "Reloj de captura de terceros",
  lastAssistanceEntryOriginManualManager: "Registro manual del Manager",
  lastAssistanceEntryOriginManualEmployer: "Registro manual del Empleador",
  exit: "SALIDA",
  entrance: "ENTRADA",

  //Fiscalizacion
  fiscalizationLoginTitle: "Acceso a la fiscalización de documentos digitales",
  fiscalizationIngresar: "Ingresar",
  fiscalizationCambiar: "Cambiar",
  fiscalizationRecuperarPass: "Recuperar contraseña",
  fiscalizationEnd: "Terminar fiscalizacion",
  fiscalizationMailMsg: "Puede acceder a fiscalizar documentos desde el mail que le llegará a su casilla de correos",
  fiscalizationInvalidToken: "Token inválido. El tiempo programado para el acceso ha expirado.",
  fiscalizationPassMail: "Puede acceder a recuperar su contraseña desde el mail que le llegará a su casilla de correos",
  fiscalizationPassUpdated: "Pin actualizado",
  fiscalizationRecoveryCodeInvalid: "Codigo de recuperación inválido",
  fiscalizationPinReq: "Su PIN debe tener entre 6 y 20 caracteres",

  collabData: "Datos del colaborador",
  viewButton: "Ver",

  noNotes: "No se encontraron tareas",

  headerSaluteWithName: "¡Hola {name}!",
  headerSaluteNoName: "¡Hola!",

  selectTypeOfDocument: "Seleccione tipo de documento",
  noSignable: "Usted no está no habilitado para firma digital",
  certificateDigitalFullTitle: "Comprobantes y certificados - Firma Digital",
  receiptsDigitalFullTitle: "Recibos y comprobantes de pago - Firma Digital",

  noDocumentsToGenerate: "No se encontraron documentos para generar",
  sendNote: "Enviar",
  shareNote: "Compartir",
  commentAction: "Comentar",
  writeHereYourComment: "Añadir un comentario...",
  editCommentAction: "Guardar",
  attachments: "Archivos adjuntos",
  attachFileToNote: "Agregar adjunto",
  accept: "Aceptar",
  refuse: "Rechazar",
  newSharedNote: "Nueva tarea compartida",
  newSentNote: "Nueva tarea recibida",
  numberOfComments: "{number} comentarios",
  numberOfCommentsSingular: "{number} comentario",
  sharedWith: "Compartida con",

  //myGroups
  myGroupsTitle: "Mis grupos",
  newGroup: "Nuevo grupo",
  noGroups: "Aún no perteneces a ningún grupo",
  groupMembers: "Miembros",
  groupCreateTitle: "Crear nuevo grupo",
  groupEditTitle: "Editar grupo",
  groupName: "Nombre del grupo",
  groupDescription: "Descripción / función del grupo",
  groupAddMembers: "Añadir miembros",
  groupDeleteQuestion: "¿Está seguro que desea borrar el grupo?",
  groupCheckValues: "Datos invalidos, por favor revise lo ingresado", //VALIDATION_ERROR
  groupSearchLabel: "Buscar colaborador",
  groupCurrentMembers: "Miembros actuales",
  groupLeave: "Salir del grupo",
  groupValidationError: "Datos invalidos", //VALIDATION_ERROR
  groupInvalidGroup: "No se encontró al grupo seleccionado", //INVALID_GROUP
  groupInvalidParticipants: "Hubo un error con los usuarios seleccionados. Por favor, intente nuevamente", //INVALID_PARTICIPANTS
  groupUnauth: "Ud. no es el dueño del grupo", //UNAUTHORIZED
  groupAtLeastOneUser: "Para crear o modificar, el grupo debe tener al menos un integrante",
  groupLeaveQuestion: "¿Está seguro que desea abandonar el grupo?",
  groupLeaveExplanation: "Al ser creador del mismo, el grupo será eliminado para todos",
  groupMembersTitle: "Miembros del grupo",
  groupImagesTitle: "Seleccionar imagen",
  groupSelectedMembers: "miembros seleccionados",
  groupsExplanation: "Los grupos ayudarán a organizarte mejor",
  groupsNewGroup: "Crear nuevo grupo",
  societyPeopleAndGroupsSearchLabel: "Buscar grupo o colaborador",

  viewComments: "Ver comentarios",
  viewCollaborators: "Compartida con",
  unauth: "Usted no está autorizado para realizar la acción",
  attachmentFailedOperation: "Ocurrió un error intentando eliminar el archivo adjunto",
  fileLimitExceeded: "Se excedió el limite de 12 archivos por tarea",
  fileSizeExceeded: "El archivo adjunto excede los 5 MB",
  imageSizeExceeded: "La imagen excede los 5 MB",
  invalidFile: "Archivo no admitido",
  noFilesUploaded: "Error en la subida de archivos",
  invalidColaboratorsGroups: "Revise la lista de colaboradores y/o grupos",
  invalidColaborators: "Revise la lista de colaboradores",
  invalidGroups: "Revise la lista de grupos",
  validationError: "Datos inválidos",
  invalidParticipants: "Hubo un error con los usuarios seleccionados. Por favor, intente nuevamente",
  commentNotFound: "Comentario no encontrado",
  noteNotFound: "Tarea no encontrada",
  assignResponsible: "Asignar responsable",
  cantAssignResponsible: "No puede asignar responsable",
  changeResponsible: "Cambiar responsable",
  cantChangeResponsible: "No se puede cambiar al responsable",
  notAssigned: "Sin responsable asignado",

  exportQueryOnly: "Exportar solo consulta",
  exportQueryAndTemplates: "Exportar consulta y plantillas",
  queryImport: "Consulta importada exitosamente",
  subTasks: "Subtareas",
  noSubtasks: "No hay subtareas creadas",
  noSubtasksTooltip: "Sin subtareas definidas",
  openSubtasks: "Abrir subtareas",
  tasks: "Progreso",
  subtask: "Subtarea",
  subtaskProgress: "Progreso de subtarea",
  task: "Tarea",
  taskProgress: "Progreso de tarea",
  taskUpdateDate: "Fecha de actualización",
  note: "Tarea",
  progressFilter: "Filtro de progreso",
  progressFilterNoFilter: "Sin filtro",
  progressFilterAbove40: "Más de 40%",
  progressFilterAbove70: "Más de 70%",
  progressFilterLessEqualThan40: "Menor o igual a 40%",
  subitemEndDateWarning: "La fecha de término de cada subtarea no puede ser menor a su fecha de progreso, ni mayor a la fecha de término de la tarea padre",
  itemEndDateWarning: "La fecha de término de cada tarea no puede ser menor a su fecha de progreso, ni a la fecha de progreso o fecha de término de sus subtareas",
  taskFinished: "Finalizada",
  taskEndsToday: "Hoy finaliza el plazo",
  taskDelayed: "Con atraso",
  taskDelay: "Días de atraso",
  taskInTime: "Con tiempo a favor",
  taskDaysRemaining: "Días para finalizar",
  taskNoFilter: "Sin filtro",
  noteInvalidDates: "Hay tareas/subtareas con fechas invalidas",
  noteInvalidResponsible: "No te encuentras autorizado para modificar algunas de las tareas/subtareas",
  deleteSubtaskConfirm: "¿Desea eliminar la subtarea?",
  deleteTaskConfirm: "¿Desea eliminar la tarea?",

  notaSharedOk: "La tarea se ha compartido con éxito",
  notaSentOk: "La tarea ha sido enviada con éxito",
  noteSendAction: "Enviar",
  noteShareAction: "Compartir",

  //Parametros generales
  colaboratorsCanModify: "Los colaboradores podrán modificar:",
  theirPic: "Su fotografía",
  theirSexCivilNationalityCell: "Su sexo, estado civil, nacionalidad y celular",
  theirBancAndAccount: "Su banco y cuenta bancaria",
  theirAddress: "Su dirección personal",
  appCanMarkOutside: "APP Asistencia puede marcar fuera de zona",
  webLogo: "Logo para plataforma WEB",
  documentsLogo: "Logo para plantillas de documentos",
  mailsLogo: "Logo para correos",
  useDigitalSign: "Usar firma digital",
  acceptFiscalization: "Aceptar fiscalización remota",
  typeOfSignature: "Tipo de firma digital de colaborador",
  accessGroupAdmin: "Grupo para acceso de administradores",
  rrhhEmail: "Email genérico de RRHH",
  latLongSociety: "Latitud/longitud del horario de la sociedad",
  footerRRHHMail: "Pie de correo del área RRHH",
  contactoPhone: "Teléfono de contacto",
  contactMail: "Email de contacto",
  contactCell: "Celular de contacto",
  contactAddress: "Dirección de contacto",
  twitter: "Twitter",
  instagram: "Instagram",
  facebook: "Facebook",
  digitalSignDisclaimer: "Disclaimer para firma digital",
  latLongCities: "Latitud/longitud de horarios de ciudades",
  employerMasterOrderFields: "Orden de campos del Maestro de Empleados",

  //ABM Carpeta Digital
  digitalFolderSettingsTitle: "Definición de carpeta digital",
  digitalFolderSettingsPageTitle: "Definición de carpeta digital (Documentos obligatorios) y documentos que pueden cargar los colaboradores",
  generics: "Obligatorios genéricos",
  genericsUpdatedOK: "Documentos actualizados exitosamente",
  byPosition: "Obligatorios por cargo",
  documentsThatCanBeUploadedByCollaboratorsThemselves: "Documentos que pueden cargar los colaboradores",
  documentPosition: "Cargo",
  documentInstance: "Instancia",
  documentMandatory: "Documento obligatorio",
  selectPosition: "Seleccionar cargo / Resetear",
  documentDeletedOK: "Documento eliminado exitosamente",
  documentDeleteQuestion: "¿Está seguro que desea eliminar la obligatoriedad del documento?",
  noDocumentsForPosition: "No se encontraron documentos",
  createNewDocument: "Agregar por cargo",
  editDocument: "Editar por cargo",
  documentCreatedOK: "Documento creado exitosamente",
  documentUpdatedOK: "Documento actualizado exitosamente",
  digitalFolderValidation: "Todos los campos son obligatorios",
  documentSamePositionInstanceError: "El tipo de documento ya está asignado para el mismo cargo e instancia",
  taskControl: "CONTROL DE TAREAS",

  uploadImage: "Cargar una imagen",

  externalDocuments: "Documentos externos",
  externalDocumentsLong: "Documentos externos para firmar",
  documentRole: "Rol",
  uploadExternalDocuments: "Subir documentos",
  previewExternalDocuments: "Previsualizar documentos",
  signExternalDocuments: "Firmar documentos",
  deleteExternalDocuments: "Eliminar documentos",
  confirmDeleteExternalDocuments: "¿Desea eliminar los documentos?",
  confirmSignExternalDocumentsTitle: "Confirmación de firma de documentos",
  confirmSignExternalDocuments: "¿Confirma realizar la firma digital de los documentos seleccionados?",
  selectExternalFiles: "Seleccionar archivos para subir",
  filesMustBePdf: "Los archivos deben tener extensión .pdf",
  filesMustStartWithCode: "Los archivos deben comenzar con el codigo del empleado o número interno seguido de guión bajo (Ej. 12345_nombre_archivo.pdf)",
  selectDocumentRole: "Seleccione rol destinatario",
  externalDocumentTitle: "Ingrese título del documento",
  uploadButtonText: "Subir",
  documentUploadedOK: "Documentos subidos exitosamente",
  errorUploadFile: "Existen archivos que no corresponden a {role} de su dependencia.",
  managers: "managers",
  employers: "empleadores",

  //Digital Documents
  myDigitalDocuments: "Mis documentos digitales",
  digitalDocuments: "Documentos digitales",
  noDigitalDocuments: "Desde la función de Documento Digitales, usted podrá firmar y eventualmente descargar, los documentos que requieran su firma digital. Tanto los documentos que usted haya solicitado al empleador para que él los firme digitalmente, como aquellos que el propio empleador haya firmado y puesto a su disposición para su firma. En estos momentos, no existe ninguno disponible para acceder en esas categorías.",
  digitalDocumentConfirmationTitle: "¿Acepta el documento generado?",
  rejectDocument: "Ingrese el motivo por el cuál no está conforme con el documento generado",

  documentInvalidTemplate: "No se encontró la plantilla relacionada con el documento",
  dateApprobation: "Fecha de aprobación",
  timeApprobation: "Hora de aprobación",

  permissionTimeRange: "El rango valido de tiempo es de 00:10 minutos a {max}:{maxMin} horas",
  permissionTimeNotGreater: "Tiempo por día no puede ser mayor al tiempo por dia solicitado originalmente",
  fileMustBeImage: "El archivo debe ser una imagen (.jpg, .jpeg, .png, .bpm o .gif)",
  activationDeactivation: "Activación y desactivación ICARIUS",
  PG: "Habilitar ICARIUS para esta sociedad",
  CLIENTE: "Código de cliente",
  STOP: "Servicio detenido",
  STOPTIME: "Hora de reanudación para mostrar",
  STOPMSG: "Mensaje para mostrar",
  requests: "Solicitudes",
  VACMAXCTACTE: "Solicitar vacaciones solo hasta el saldo",
  VACPLUSCTACTE: "Máximo de vacaciones adicionales al saldo",
  PORMAXANT: "Porcentaje máximo para anticipos",
  RECEP: "Receptores por cargo o por grupos",
  identificationAndSocietyData: "Identificación y datos de la sociedad",
  decimalFieldsDefinition: "DEFINICIÓN DE DECIMALES PARA CAMPOS NUMÉRICOS",
  EMAILRRHH: "Email genérico de RRHH",
  GO_LOCALTIME: "Latitud/Longitud horario de la sociedad",
  GO_DTO_RRHH: "Pie de correo área RRHH",
  GO_TELEFONO: "Teléfono de contacto",
  GO_EMAIL: "Email de contacto",
  GO_CELULAR: "Celular de contacto",
  GO_ADDRESS: "Dirección de contacto",
  GO_TWITTER: "X (Twitter)",
  GO_FACEBOOK: "Facebook",
  GO_INSTAGRAM: "Instagram",
  GO_LINKEDIN: "Linkedin",
  JUSTIFY_QUALIFICATIONS: "El evaluador debe justificar las calificaciones mínimas y máximas?",
  SHOW_BEHAVIOR_SKILLS: "Mostrar comportamiento de indicadores en competencias",
  WEIGHT_DISTRIBUTION_FOR_NON_EXISTENT: "Distribución de ponderados para evaluaciones no existentes",
  ALLOW_COMPLAINTS_CHANNEL: "Habilitar el envío de denuncias",
  SHOW_RECOGNITION_POINTS_IN_ANALYTICS: "Mostrar puntos de reconocimientos en analítica del desempeño",
  SHOW_SALARY_DATA_IN_ANALYITICS: "Mostrar datos salariales en analítica del desempeño",
  START_HOLIDAYS_ON_NON_WORKING_DAY: "Puede iniciar vacaciones en día no hábil",
  ALLOW_HALF_DAY_HOLIDAYS: "Pueden solicitar vacaciones de 1/2 días",
  digitalSign: "De firma digital",
  goalsAndObjectives: "DE LAS METAS Y OBJETIVOS DE DESEMPEÑO",
  COLMETASYOBJ: "Puede el colaborador definir metas y objetivos",
  FIRMADIGITAL: "Usar firma digital",
  FISCALIZACION: "Aceptar fiscalización remota",
  FIRMACOL: "Tipo de firma digital colaborador",
  DISCLAMER: "Disclamer para firma digital",
  timezones: "De zonas horarias",
  GO_CITYHOUR: "Latitud/Longitud horarios de ciudades",
  SOCIETYNAME: "Nombre de la sociedad",
  TAXID: "Identificador fiscal",
  QTY_DEC_AMOUNTS: "Importes",
  QTY_DEC_PRICES: "Precios",
  QTY_DEC_RATES: "Tasas",
  QTY_DEC_QUANTITIES: "Cantidades",
  QTY_DEC_PERCENTAGES: "Porcentajes",
  logosAndCustomization: "De logos y personalización",
  lightWebsiteLogo: "Logo para plataforma web con tema light",
  darkWebsiteLogo: "Logo para plataforma web con tema dark",
  templateLogo: "Logo para plantillas de documentos",
  emailLogo: "Logo para correos",
  permissionsGeneralParameters: "De permisos y accesos a los datos",
  colaboratorPermissions: "Datos que podrá modificar el propio colaborador",
  geographicStructureSettings: "De la organización geográfica",
  GEO_DIVISION1: "División geográfica 1",
  GEO_DIVISION2: "División geográfica 2",
  GEO_DIVISION3: "División geográfica 3",
  hideFieldsPeopleMasterGeneralParameters: "Ocultar:",
  peopleMasterGeneralParameters: "DEL MAESTRO DE PERSONAS",
  SHOW_CONTRACT_DATA: "Muestra datos del CONTRATO",
  SHOW_SALARY: "Muestra datos de la REMUNERACION",
  SHOW_ABSENT_COLABORATORS_GRID_TO_COLABORATORS: "Mostrar cuadro de colaboradores ausentes en rol del colaborador",
  SHOW_TIME_DATA: "Muestra datos de TIEMPOS",
  SHOW_SPECIAL_CLASSIFICATIONS: "Muestra datos de CLASIFICACIONES ESPECIALES",
  ASSUME_DATES: "Asumir fecha de contratación para otras fechas",
  SHOW_OFFICE_PHONE: "Teléfono de oficina",
  SHOW_PERSONAL_PHONE: "Teléfono particular",
  SHOW_PASSPORT_DATA: "Número de pasaporte y expiración",
  SHOW_DOMICILIARY_DATA: "Datos domiciliarios (Excepto la dirección)",
  SHOW_CONTRACT_CODE: "Código de contrato",
  SHOW_CONTRACT_MOTIVE: "Motivo de contratación",
  SHOW_SECTION: "Sección",
  SHOW_PUBLICATION: "Centro de publicación",
  SHOW_IS_RETIRED: "Jubilado",
  SHOW_WORKER_TYPE: "Tipo de trabajador",
  SHOW_FAMILY_ALLOWANCE_DATA: "Tramo de asignación familiar",
  SHOW_EXTREME_ZONE: "Rebaja por zona extrema y porcentaje",
  SHOW_ADDITIONAL_VACATIONS_DATA: "Meses adicionales de vacaciones, fecha y días",
  SHOW_BUSSINESS_SALARY: "Sueldo empresarial",
  SHOW_HARD_WORK: "Trabajo pesado",
  SHOW_IMPOSED_IN_ANOTHER_COUNTRY: "Impone en otro país y su valor cotizado",
  SHOW_SCHEDULED_NOTICE: "Marcar con aviso programado",
  SHOW_DONOR_BLOOD_TYPE: "Grupo sanguíneo y donador",
  SHOW_DIVISION: "Unidad de negocio",
  colaboratorsWillBeAbleToModifiy: "Los colaboradores podrán modificar",
  GO_UPDATEPHOTO: "Su fotografía",
  EDIT_SEX: "Su sexo",
  EDIT_CIVILSTATUS: "Su estado civil",
  EDIT_NATIONALITY: "Su nacionalidad",
  EDIT_CELLPHONE: "Su celular",
  EDIT_LINKEDIN: "Su LinkedIn",
  EDIT_PERSONAL_EMAIL: "Su Email personal",
  GO_UPDATEBANK: "Su banco y Cuenta bancaria",
  GO_UPDATEDIR: "Su dirección personal",
  ORDFIELDMAFU: "Orden campos del Maestro de empleados",
  GRUPOEMP: "Grupo para acceso de administradores",
  HISTORICAL_DATA_TRACEABILITY: "Histórico de trazabilidad de datos",
  SOCIAL_FEED_PUBLICATIONS: "Publicaciones en muro social",
  ALERTS_AND_NOTICES: "Alertas y avisos",
  PENDING_REMINDERS: "Acciones pendientes",
  mobileApps: "De las apps",
  historicalDataDuration: "De las vigencias de datos históricos",
  MARCAFUERAZONA: "APP Asistencia puede marcar fuera de zona",
  tooltipWebLogo: ".png min 200x200px",
  tooltipTemplateLogoLine1: "*.jpg con fondo blanco",
  tooltipTemplateLogoLine2: "max 300x200px",
  tooltipTemplateLogoLine3: "min 75x75px.",
  tooltipEmailLogoLine1: "*.jpg con fondo blanco",
  tooltipEmailLogoLine2: "max 750x750px",
  fillClientGeneralSettings: "Verifique el código de cliente",
  fillStopMsgGeneralSettings: "Verifique el mensaje para mostrar cuando el servicio está detenido",
  fillStopTimeGeneralSettings: "Verifique el horario de reanudación para mostrar cuando el servicio está detenido",
  fillHREmailGeneralSettings: "Verifique el email de RRHH para envíos genéricos",
  fillHRDTOGeneralSettings: "Verifique el pie de correo para el área de RRHH",
  fillContactEmailGeneralSettings: "Verifique el email de contacto",
  fillMaxPercentageAdvance: "Verifique el porcentaje máximo de anticipos",
  fillDisclaimerGeneralSettings: "Verifique el disclaimer para firma digital",
  fillFirmaColGeneralSettings: "Verifique el tipo de firma de colaborador",
  dataSucessfullySaved: "Los datos han sido grabados con éxito",

  //ABM ENCUESTAS
  pollSettings: "Definición de encuestas",
  newPoll: "Nueva encuesta",
  pollCreation: "Creación de encuesta",
  pollFrom: "Desde",
  pollTo: "Hasta",
  pollType: "Tipo",
  pollRole: "Rol de visualización",
  pollFamily: "Familia",
  pollReference: "Referencia",
  pollQuestion: "Pregunta",
  pollQuestionType: "Tipo de pregunta",
  pollQuestionOption: "Opción",
  pollAddOption: "+ Agregar opción",
  pollName: "Nombre de la encuesta",
  pollDescription: "Descripción",
  deletePollOption: "Eliminar opción",
  pollOption: "Opción",
  confirmDeletePoll: "¿Desea eliminar la encuesta?",
  newPollQuestion: "Nueva pregunta",
  pollDeletedOK: "Encuesta eliminada con éxito",
  pollCreatedOK: "Encuesta creada con éxito",
  pollModifiedOK: "Encuesta editada con éxito",
  questionAtLeastTwoOption: "Debe tener al menos 2 opciones",
  pollFieldsRequiredAndOptions: "Todos los campos son obligatorios, y las preguntas con opciones deben tener mas de una",
  pollDuplication: "Duplicar",
  pollDuplicationTitle: "Duplicar encuesta",
  pollFieldsRequired: "Todos los campos son obligatorios",
  pollDuplicationMessage: "Por favor, ingrese el nombre y las fechas de la encuesta.",
  pollDuplicationMessage2: "Estos datos reemplazaran los originales.",
  pollDeleteDialogTitle: "Eliminar encuesta",
  pollDeleteIsInUse: "La encuesta que eliminará {name} ya contiene votaciones. ¿Desea eliminarla de todos modos?",
  pollDeleteQuestion: "¿Esta seguro que desea eliminar la encuesta {name}?",

  // Type of Score
  typeOfScoreFieldsRequiredAndOptions: "Todos los campos son obligatorios y debe haber al menos una nota",
  confirmDeleteTypeOfScore: "¿Desea eliminar el tipo de nota?",
  newTypeOfScoreQuestion: "Nueva nota",
  typeOfScoreDeletedOK: "Tipo de nota eliminada con éxito",
  typeOfScoreCreatedOK: "Tipo de nota creada con éxito",
  typeOfScoreModifiedOK: "Tipo de nota editada con éxito",
  typeOfScoreFieldsRequired: "Todos los campos son obligatorios",
  typeOfScoreDeleteDialogTitle: "Eliminar tipo de nota",
  typeOfScoreDeleteItem: "¿Esta seguro que desea eliminar el tipo de nota {name}?",

  // Type of Score
  ratingScaleFieldsRequiredAndOptions: "Todos los campos son obligatorios y debe haber al menos una calificación",
  confirmDeleteRatingScale: "¿Desea eliminar el tipo de escala?",
  newRatingScaleQuestion: "Nueva calificación",
  ratingScaleDeletedOK: "Tipo de escala eliminada con éxito",
  ratingScaleCreatedOK: "Tipo de escala creada con éxito",
  ratingScaleModifiedOK: "Tipo de escala editada con éxito",
  ratingScaleFieldsRequired: "Todos los campos son obligatorios",
  ratingScaleDeleteDialogTitle: "Eliminar tipo de escala",
  ratingScaleDeleteItem: "¿Esta seguro que desea eliminar el tipo de escala {name}?",

  //Access Settings
  accessSettingsTitle: "Accesos y propiedades",
  manangersAndEmployers: "Managers y empleadores",
  newManager: "Nuevo manager",
  selectManager: "Seleccionar manager",
  filterByManager: "Filtrar por manager",
  managerActive: "Activo",
  accessGroups: "Grupos de acceso",
  isEmployer: "Staff de RR.HH. (Empleador)",
  noManagerFound: "No se encontró manager",
  managersUpdatedOK: "Actualización finalizada con éxito",
  managersCreatedOK: "Creación finalizada con éxito",
  managerDeletedOK: "Eliminación finalizada con éxito",
  errorEmployeeInUseAlready: "Ya existen filas con el empleado seleccionado",
  errorTwoEmployersAlready: "No pueden existir más de un Manager o Empleador que compartan el mismo correo",
  viewAccessGroups: "Ver grupos",
  currentGroups: "Grupos actuales",
  groupsWarning: "En caso de no seleccionar ningun grupo, se dará acceso a todos los grupos",
  findGroupByName: "Buscar grupo por nombre",

  onlineUsers: "Usuarios en línea",
  onlineCollab: "Colaboradores",
  onlineManagers: "Managers",
  onlineEmployers: "Empleadores",

  noteGlobalProgress: "Progreso global de la tarea",
  noteOwner: "Propietario de la tarea",
  noteMailSent: "Mail enviado con éxito",
  noteMailOwner: "Solo puede enviar mensajes a los responsables de las tareas en aquellas Notas que son de su propiedad",
  noteTaskGridData: "Progreso de la tarea",
  noteOwner2: "Propietario de la nota",
  noteGlobalProgress2: "Progreso global de la nota",
  note2: "Nota",

  mailMyPeopleWillBeSent: "Se enviará a {amount} colaborador/es",
  mailTextPlaceholder: "Escribe aquí tu mensaje",
  mailLinkPlaceholder: "Insertar link",
  mailCoordPlaceholder: "Insertar coordenadas",
  mailUploadFile: "Subir adjunto",
  mailLinkText: "Activar link",
  mailUndoLinkText: "Desactivar link",
  mailCoordText: "Activar coordenadas",
  mailUndoCoordText: "Desactivar coordenadas",
  mailSend: "Enviar",
  mailFieldInvalid: "El campo no puede estar vacío",
  mailCoordInvalid: "Ejemplo de coordenadas válidas: -33.42560642629101, -70.6139463736171",
  mailInvalidFileType: "El archivo no puede ser .exe",
  mailInvalidFileSize: "El archivo no puede superar los 5mb",
  mailFileAttached: "1 archivo adjunto",
  mailMyPeopleSent: "Mails enviados con éxito",

  //Annotations Analysis
  annotationsAnalysisTitle: "Análisis de anotaciones",
  annoText: "Texto de anotación",
  noDataToChart: "El formato actual de la grilla no permite graficar. Para poder hacerlo, deje la grilla en su formato de visualización inicial.",

  sendingMail: "Enviando mail...",

  duplicate: "Duplicar",

  selectColabFire: "Seleccionar colaborador: Solicitud de desvinculación",
  selectColabRise: "Seleccionar colaborador: Solicitud de aumento de sueldo",
  selectedColab: "Colaborador seleccionado",

  //Historical processes
  historicalProcessesTitle: "Procesos históricos",
  currentProcessesTitle: "Procesos actuales",
  processCode: "Código de proceso",
  processDescription: "Descripción",
  processType: "Tipo de proceso",
  processSubtype: "Tipo de subproceso",
  processDateFrom: "Fecha desde",
  processDateTo: "Fecha hasta",
  processPeriod: "Período",
  processPaymentDate: "Fecha de pago",
  processStatus: "Estado",
  processDateLast: "Fecha último proceso",
  processTimeLast: "Hora último proceso",
  processNew: "Crear proceso",
  processEdit: "Editar proceso",
  processCodeChar: "No se permiten espacios ni los siguientes caracteres especiales: / \\ _ : * ¿ ? “ | < > = +",
  processCodeUse: "El código no puede estar en uso",
  processGridLabel: "Seleccionar los colaboradores que serán procesados.",
  processCreatedOK: "Proceso de cálculo creado con éxito",
  processEditedOK: "Proceso de cálculo editado con éxito",
  processModifyConfirm: "Si ha modificado datos del proceso, o bien si ha cambiado la selección de colaboradores que participarán en este proceso, recuerde que debe hacer un reproceso del cálculo ¿Desea continuar?",
  processNoExist: "No existe el proceso de cálculo que se desea modificar",
  processDeletedOK: "Procesos de cálculo eliminados con éxito",
  processDeleteConfirm: "¿Está seguro que desea eliminar los procesos de cálculo?",
  processCalculate: "Calcular",
  calcProcessTraceability: "Trazabilidad del proceso de cálculo",
  processExecutePartialConfirm: "¿Está seguro que desea ejecutar un proceso de cálculo PARCIAL para {amount} colaboradores?",
  processExecuteTotalConfirm: "¿Está seguro que desea ejecutar un proceso de cálculo TOTAL para {amount} colaboradores?",

  youNeedOneRow: "Debe seleccionar un unico proceso",

  //Digital folder Analysis
  digitalFolderAnalysisTitle: "Análisis de carpeta digital",
  digitalFolderNotAvailable: "Archivos faltantes",
  digitalFolderAvailable: "Archivos existentes",
  digitalFolderSelectInstance: "Seleccionar instancia",
  digitalFolderEveryInstance: "Todas las instancias",
  digitalFolderInstanceReq: "Debe seleccionar Al ingreso, Al egreso o Ciclo de vida",
  digitalFolderFechadecarga: "Fecha de carga",
  digitalFolderHoradecarga: "Hora de carga",
  digitalFolderInstancia: "Instancia",
  digitalFolderTipodedocumentooarchivo: "Tipo de documento o archivo",
  digitalFolderTipodedocumentooarchivoFaltante: "Tipo de documento o archivo faltante",
  digitalFolderTextodescriptivo: "Texto descriptivo",
  digitalFolderNombredelarchivo: "Nombre del archivo",
  digitalFolderFechadevencimiento: "Fecha de vencimiento",
  digitalFolderResponsabledelacarga: "Responsable de la carga",
  digitalFolderSituacion: "Situación",

  accessByGroups: "Acceso por grupos",
  tasksAndTelecommuting: "Tareas y teletrabajo",

  //consulta empleados procesos
  employeesProcessesTitle: "Colaboradores procesados",
  employeesProcessDeleteConfirm: "¿Está seguro que desea eliminar a los colaboradores seleccionados del proceso de cálculo?",
  employeesProcessDeletedOK: "Se han eliminado los colaboradores del proceso de cálculo",
  employeesProcessResult: "Resultado",
  employeesProcessOthers: "Otros",
  employeesProcessDetail: "Detalle",
  employeesProcessReferences: "Referencias",
  employeesProcessDialogTitle: "Consulta",
  employeesProcessName: "Nombre",
  employeesProcessAmount: "Cantidad",
  employeesProcessBaseCalculo: "Base de cálculo",
  employeesProcessHaberesAportes: "Haberes con aportes",
  employeesProcessDescuentosLegales: "Descuentos legales",
  employeesProcessHaberesSinAporte: "Haberes sin aportes",
  employeesProcessDescuentosVarios: "Descuentos varios",
  employeesProcessConcepto: "Concepto",
  employeesProcessCodigoConcepto: "Código del concepto",
  employeesProcessNombreConcepto: "Nombre del concepto",
  employeesProcessPorcentaje: "Porcentaje",
  employeesProcessValor: "Valor",
  employeesProcessFecha: "Fecha",
  employeesProcessValueType: "Tipo de valor",
  employeesProcessAnnexComment: "Comentario anexo",
  employeesProcessPeriodoImputacion: "Período de imputación",
  employeesProcessCentroBeneficio: "Centro de costo",
  employeesProcessProyecto: "Proyecto",
  employeesProcessDimension2: "Dimensión 2",
  employeesProcessDimension3: "Dimensión 3",
  employeesProcessDimension4: "Dimensión 4",
  employeesProcessDimension5: "Dimensión 5",
  employeesProcessCodigoReferencia: "Código de referencia",
  employeesProcessReferencia1: "Referencia 1",
  employeesProcessReferencia2: "Referencia 2",
  employeesProcessCantidadConvertida: "Cantidad convertida",
  employeesProcessPeriodoReliquidacion: "Período de reliquidación",
  employeesProcessMesesReliquidar: "Meses a reliquidar",
  employeesProcessEntidad: "Entidad",
  employeesProcessTipoComprobante: "Tipo de comprobante",
  employeesProcessSerieComprobante: "Serie del comprobante",
  employeesProcessNumeroComprobante: "Número del comprobante",
  employeesProcessOrigen: "Origen",
  employeesProcessValorNumerico: "Valor numérico",
  employeesProcessValorAlfanumerico: "Valor alfanumérico",
  employeesProcessValorFecha: "Valor fecha",
  employeesProcessComentarios: "Comentarios",
  employeesProcessTipoValor: "Tipo de valor",
  employeesProcessCodigoAccion: "Código de acción",
  employeesProcessObjetoAccion: "Objeto de acción",
  employeesProcessFechaAccion: "Fecha de la acción",
  employeesProcessAccionesSQL: "Acciones de SQL",
  employeesProcessTotal: "Líquido/Neto",

  //geoanalysis
  geoanalysisTitle: "Geoanálisis",
  noGeoanalysisGroups: "No existen grupos de consultas de geoanálisis disponibles por el momento",
  openMap: "Abrir mapa",
  closeMap: "Cerrar mapa",
  exportAsExcel: "Exportar para My Maps",

  accessosSegmentadoPorGrupos: "Accesos segmentado por grupos",

  selectColumn: "Seleccionar columna",

  deleteManagerQuestion: "¿Esta seguro que desea eliminar al Manager de la lista?",
  deleteEmployerQuestion: "¿Esta seguro que desea eliminar al Empleador de la lista?",

  publicationSettings: "Publicaciones del inicio",
  newPublication: "Nueva publicación",
  publicationName: "Nombre de la publicación",
  publicationFrom: "Desde",
  publicationTo: "Hasta",
  publicationRole: "Rol de visualización",
  publicationText: "Texto",
  publicationLink: "Link",
  publicationDownload: "Descarga",
  publicationType: "Tipo",
  publicationNumber: "Número",
  publicationImage: "Imagen",
  confirmDeletePublication: "¿Desea eliminar la publicación?",
  publicationDeleteDialogTitle: "Eliminar publicación",
  publicationCreation: "Creación de publicación",
  publicationDeletedOK: "Publicación eliminada con éxito",
  publicationCreatedOK: "Publicación creada con éxito",
  publicationModifiedOK: "Publicación editada con éxito",
  publicationExplanation: "Las publicaciones te ayudaran a mantener informada a tu organización",
  noPublications: "Aún no hay publicaciones creadas",

  publicationNewsModal: "Noticias destacadas",
  validationURLPreview: "La URL indicada no posee imagen de previsualización. Por favor, cargue una imagen.",

  publicationInvalidFileType: "Tipo de archivo inválido",
  publicationFileTypeExplanation: "Debe ser de alguno de los siguientes tipos: jpg, bmp, png, jpeg, rar, zip, gz, tar, xls, xlsx, txt, rtf, doc, docx, pdf, html",

  downloadAttachment: "Descargar archivo adjunto",
  answerPoll: "Responder encuesta",

  generatePublications: "Generar publicaciones",

  //filters
  filterbranches: "Sucursales",
  filtercategory: "Categorías",
  filtercontractType: "Tipo de contratos",
  filterfunction: "Funciones",
  filterfunctionType: "Tipo de funcionarios",
  filtergender: "Sexo",
  filtermanagement: "Gerencias",
  filtermaritalStatus: "Estado civil",
  filterpositions: "Cargos",
  filterretiredPersonal: "Jubilados",
  filtershifts: "Turnos",
  filterspecialty: "Especialidades",
  filterunion: "Sindicatos",
  filterworkplaces: "Lugares de trabajo",
  filtermanagementCenters: "Centros de publicación",

  filtersOK: "Filtros actualizados con éxito",

  orgChart: "Organigrama",
  positionEditor: "Estructura de cargos",
  cargosMenu: "Cargos",

  //Centers Settings
  centersSettings: "Centros de publicación",
  newCenter: "Nuevo Centro de publicación",
  centerCode: "Código",
  centerName: "Nombre",
  cantDeleteCenter: "No es posible eliminar el Centro de publicación porque está en uso",
  deleteCenterQuestion: "¿Esta seguro que desea eliminar al Centro de publicación de la lista?",
  noCentersFound: "No se encontraron Centros de publicación",
  centerCodePlaceholder: "Ingrese código",
  centerNamePlaceholder: "Ingrese nombre",
  centerDuplicatedData: "No puede existir más de un centro de publicación que compartan el mismo código o nombre",
  centersUpdatedOK: "Actualización finalizada con éxito",
  centersCreatedOK: "Creación finalizada con éxito",
  centerDeletedOK: "Eliminación finalizada con éxito",

  //Permissions settings
  permissionsSettings: "Permisos para solicitudes",
  newPermission: "Nuevo permiso",
  permissionName: "Nombre del permiso",
  permissionCode: "Código del permiso",
  confirmDeletePermission: "¿Desea eliminar el permiso?",
  permissionDeleteDialogTitle: "Eliminar permiso",
  permissionCreation: "Creación de permiso",
  permissionDeletedOK: "Permiso eliminado con éxito",
  permissionCreatedOK: "Permiso creado con éxito",
  permissionModifiedOK: "Permiso editado con éxito",
  noPermissions: "Aún no hay permisos creados",
  permissionDuplicatedData: "No puede existir más de un permiso que comparta el mismo código o nombre",

  positionEditorId: "ID",
  positionEditorPositionName: "Nombre del cargo",
  positionEditorPositionQuantity: "Cantidad de puestos",
  positionEditorOccupiedPositions: "Puestos ocupados",
  positionEditorFreePositions: "Puestos libres",
  positionEditorResponsabilityDegree: "Grado de responsabilidad",
  positionEditorSuperior: "Cargo superior",
  positionEditorAssistant: "Asistente",

  //Family Settings
  familySettings: "Definición de familiares",
  newFamily: "Nuevo tipo de familiar",
  familyCode: "Código",
  familyName: "Nombre",
  cantDeleteFamily: "No es posible eliminar el tipo de familiar porque está en uso",
  deleteFamilyQuestion: "¿Está seguro que desea eliminar el tipo de familiar de la lista?",
  noFamilyFound: "No se encontraron definición de familiares",
  familyCodePlaceholder: "Ingrese código",
  familyNamePlaceholder: "Ingrese nombre",
  familyDuplicatedData: "No puede existir más de un tipo de familiar que comparta el mismo código o nombre",
  familyUpdatedOK: "Actualización finalizada con éxito",
  familyCreatedOK: "Creación finalizada con éxito",
  familyDeletedOK: "Eliminación finalizada con éxito",

  //Functions Settings
  functionsSettings: "Definición de funciones",
  newFunction: "Nueva función",
  deleteFunctionQuestion: "¿Esta seguro que desea eliminar la función de la lista?",
  noFunctionsFound: "No se encontraron funciones",
  functionCode: "Código",
  functionCodePlaceholder: "Ingrese código",
  functionName: "Nombre",
  functionNamePlaceholder: "Ingrese nombre",
  functionsUpdatedOK: "Actualización finalizada con éxito",
  functionsCreatedOK: "Creación finalizada con éxito",
  functionsDeletedOK: "Eliminación finalizada con éxito",
  functionsDuplicatedData: "No puede existir más de una función que comparta el mismo código o nombre",
  cantDeleteFunction: "No es posible eliminar la función porque está en uso",

  //Advances settings
  advancesSettings: "Anticipos para solicitudes",
  newAdvance: "Nuevo anticipo",
  noAdvances: "Aún no hay anticipos creados",
  advanceCreation: "Creación de anticipos",
  advanceName: "Nombre del anticipo",
  advanceType: "Tipo del anticipo",
  advanceCode: "Código del anticipo",
  advanceDeleteDialogTitle: "Eliminar anticipo",
  confirmDeleteAdvance: "¿Desea eliminar el anticipo?",
  advanceDeletedOK: "Anticipo eliminado con éxito",
  advanceCreatedOK: "Anticipo creado con éxito",
  advanceModifiedOK: "Anticipo editado con éxito",
  advanceDuplicatedData: "No puede existir más de un anticipo que comparta el mismo código o nombre",

  //Document publication
  documentPublication: "Publicación de documentos",
  newDocumentPublication: "Nuevo documento",
  documentReceipts: "Recibos",
  documentCertificates: "Certificados y Comprobantes",
  documentRequests: "Documentos de solicitudes",
  documentExternal: "Documentos externos",
  documentAssistance: "Informes de asistencia",
  documentByRequest: 'Documentos a pedido',
  documentsGenerated: 'Documentos auto generados durante descarga de carpeta digital',
  documentPublicationName: "Nombre del documento",
  documentPublicationRole: "Rol de visualización",
  documentPublicationType: "Tipo",
  confirmDeleteDocumentPublication: "¿Desea eliminar el documento publicado?",
  documentPublicationDeleteDialogTitle: "Eliminar publicación",
  documentPublicationCreation: "Publicación de documento",
  documentPublicationDeletedOK: "Publicación eliminada con éxito",
  documentPublicationCreatedOK: "Publicación creada con éxito",
  documentPublicationModifiedOK: "Publicación editada con éxito",
  noDocumentPublication: "Aún no hay documentos creados",
  documentIncludedFolder: "Incluido en descarga de carpeta digital",
  documentPublicationWorkflow: "Relacionado al workflow",
  documentPublicationCode: "Plantilla relacionada",
  documentPublicationSign: "Con firma digital",

  //Contract type settings
  contractTypesSettings: "Tipos de contrato",
  newContractType: "Nuevo tipo de contrato",
  noContractTypes: "No se encontraron tipos de contrato",
  contractTypeCode: "Código de contrato",
  contractTypeName: "Nombre de contrato",
  contractTypeCalification: "Calificación",
  contractTypeDeleteDialogTitle: "Eliminar tipo de contrato",
  confirmDeleteContractType: "¿Desea eliminar el tipo de contrato?",
  contractTypeCreation: "Creación de tipo de contrato",
  contractTypeDuplicatedData: "No puede existir más de un tipo de contrato que comparta el mismo código o nombre",
  contractTypeDeletedOK: "Tipo de contrato eliminado con éxito",
  contractTypeCreatedOK: "Tipo de contrato creado con éxito",
  contractTypeModifiedOK: "Tipo de contrato editado con éxito",
  cantDeleteContractType: "No es posible eliminar el tipo de contrato porque está en uso",

  //Concept groups
  conceptGroups: "Grupos para conceptos",
  newConceptGroup: "Nuevo grupo",
  conceptGroupNamePlaceholder: "Ingrese nombre",
  conceptGroupName: "Nombre",
  conceptGroupCode: "Número",
  noConceptGroupsFound: "No se encontraron grupos para conceptos",
  conceptsInUse: "No se puede modificar el concepto por estar en uso",

  //Contractor settings
  contractorCode: "Código de contratista",
  contractorName: "Nombre de contratista",
  contractorAddress: "Dirección",
  contractorContactName: "Nombre de contacto",
  contractorContactMail: "Mail de contacto",
  contractorContactPhone: "Telefono de contacto",
  contractorType: "Tipo",
  contractorSettings: "Contratistas",
  newContractor: "Nuevo contratista",
  contractorDeleteDialogTitle: "Eliminar contratista",
  confirmDeleteContractor: "¿Desea eliminar al contratista?",
  contractorCreation: "Creación de contratista",
  noContractors: "No se encontraron contratistas",
  contractorDuplicatedData: "No puede existir más de un contratista que comparta el mismo código o nombre",
  contractorDeletedOK: "Contratista eliminado con éxito",
  contractorCreatedOK: "Contratista creado con éxito",
  contractorModifiedOK: "Contratista editado con éxito",
  cantDeleteContractor: "No es posible eliminar al contratista porque está en uso",

  //Functionary type settings
  functionaryTypesSettings: "Tipos de funcionario",
  newFunctionaryType: "Nuevo tipo de funcionario",
  noFunctionaryTypes: "No se encontraron tipos de funcionario",
  functionaryTypeCode: "Código de funcionario",
  functionaryTypeName: "Nombre de funcionario",
  functionaryTypeProcess: "Procesos automáticos",
  functionaryTypeRequiresClient: "Requiere cliente/proveedor",
  functionaryTypePlant: "Personal de planta",
  functionaryTypeNoPlant: "Externos o Subcontratos",
  functionaryTypeDeleteDialogTitle: "Eliminar tipo de funcionario",
  confirmDeleteFunctionaryType: "¿Desea eliminar el tipo de funcionario?",
  functionaryTypeCreation: "Creación de tipo de funcionario",
  functionaryTypeDuplicatedData: "No puede existir más de un tipo de funcionario que comparta el mismo código o nombre",
  functionaryTypeDeletedOK: "Tipo de funcionario eliminado con éxito",
  functionaryTypeCreatedOK: "Tipo de funcionario creado con éxito",
  functionaryTypeModifiedOK: "Tipo de funcionario editado con éxito",
  cantDeleteFunctionaryType: "No es posible eliminar el tipo de funcionario porque está en uso",

  locationSettings: "Definición de lugares de trabajo",

  //Works and tasks
  worksAndTasksSettings: "Definición de obras/faenas",

  divisionsSettings: "Unidades de negocio",

  //Concepts Formulas Functions
  conceptsAndFormulas: "Conceptos y fórmulas",
  conceptsFormulasFunctions: "Conceptos, fórmulas y funciones",

  //accumulatorSettings
  accumulatorSettings: "Definición de acumuladores",

  //organizationalDefinitions
  organizationalDefinitions: "Definición de la organización",

  //allergies exams diseases
  allergiesExamsDiseases: "Definición de perfil médico",

  //calculationReferences
  calculationReferences: "Referencias de cálculo",

  //entry exit types
  entryExitTypes: "Definición de contrataciones",

  // publications
  publications: "Publicaciones",

  // requestsSettings,
  requestsSettings: "Definición de solicitudes",

  thirdPartySettings: "Definición de terceros",
  locationSettingsBox: "Definición de ubicaciones",

  organizationStructure: "Estructura departamental",
  geographicalStructure: "Organización geográfica",

  issueTypesSettings: "Definición de incidencias",
  //issue Types Settings
  absenceTypes: "Tipos de inasistencia",
  permissionTypes: "Tipos de permiso",
  licenceTypes: "Tipos de licencia",

  //positionsClassification
  positionsClassification: "Clasificaciones de cargos",

  managementDefinition: "Definición de gestión",

  alertsSettings: "Definición de Alertas/Avisos",

  conceptsSetSettingsTitle: "Conjunto de conceptos",
  thirdPartyClassification: "Clasificación de terceros",
  thirdParty: "Terceros",
  laborUnions: "Sindicatos",
  paymentTypes: "Formas de pago",

  documentTemplates: "Plantillas para documentos",
  assetsAndDiscountsScreenSelector: "Haberes y descuentos",
  assetsAndDiscountsCreation: "Creación de haberes y descuentos",

  digitalWarehouseFileTypes: "Tipos de archivo de bodega digital",

  trainingData: "Definición de formación",
  schooling: "Escolaridades",
  studies: "Estudios",
  specializations: "Especializaciones",
  languages: "Idiomas",
  benefitsSettingsBox: "Beneficios",
  affiliationInstitutionDefinition: "Definición de instituciones de afiliación",
  retirementDefinition: "Previsión",
  healthDefinition: "Salud",

  ratingsAndGeneralValues: "Índices y valores generales",
  familyAsignations: "Asignaciones familiares",
  ratings: "Índices",
  taxTable: "Tabla de impuestos",

  processScheduling: "Programación de procesos actuales",

  entryByImport: "Ingreso via importación",
  filterDepartamento: "Departamento",
  filterCargo: "Cargo",
  "filterObra o Faena": "Obra o Faena",
  filterGerencia: "Gerencia",
  "filterLugar de trabajo": "Lugar de trabajo",
  "filterTurno": "Turno",
  "filterHorario": "Horario",
  "filterColaborador": "Colaborador",
  filterAcumuladores: "Acumuladores",
  "filterGrupos para conceptos": "Grupos para conceptos",
  "filterConjunto de conceptos": "Conjunto de conceptos",
  "filterEstructura de ingreso": "Estructura de ingreso",
  digitalWarehouse: 'Bodega digital',
  kpiDefinition: 'Definición de KPI',
  geoQuery: 'Consultas para geoanálisis',
  queryDefinition: 'Definición de consultas',
  generalValues: 'Valores generales',
  conceptStructure: 'Estructura de ingreso de conceptos',
  conceptsSet: 'Conjunto de conceptos',
  extraHoursAuthorization: "Autorización de horas extras",
  holidayTable: 'Tabla de vacaciones',
  extraHoursTable: 'Tipos de horas extras',
  festivitiesTable: 'Feriados y días festivos',
  groupClassification: 'Clasificación de grupos',
  payrollTypesSettings: 'Tipos de nómina',
  entryPerConcept: 'Ingreso por concepto',
  entryPerSociety: 'Ingreso por sociedad',
  assetsDiscountsSummary: 'Resumen de haberes y descuentos',
  attendanceReport: 'Consulta de presentismo',
  entryPerConceptSet: 'Ingreso por conjunto de conceptos',
  entryTypes: 'Motivos de ingreso',
  exitTypes: 'Motivos de egreso',
  allergiesSettings: 'Alergias',
  examsSettings: 'Exámenes',
  diseasesSettings: "Enfermedades",
  categoriesSettings: 'Categorías',
  specialtiesSettings: 'Especialidades',
  userAlertsSettings: 'Alertas y avisos del usuario',
  systemAlertsSettings: 'Alertas y avisos del sistema',
  massEntry: "Ingreso masivo",
  conceptsAndFormulasScreenSelector: 'Conceptos, fórmulas y funciones',
  calculationsFunctions: 'Funciones de cálculo',

  CALC_ONLINE: 'Habilitar procesos en línea',
  SHOW_9BOXES_C: 'Muestra clasificación 9-Boxes al colaborador',
  MANAGER_GESPER: 'Habilitar a managers la gestión directa de permisos',
  ORGCHART: 'Publicar organigrama',
  MANAGER_SEE_UNPUBLISHED_PROCESSES: 'Manager pueden ver procesos de cálculo sin publicar',
  SHOW_POINTS_TO_REDEEM: 'Muestra puntos para canje en el Medallero',
  MANAGER_GESVAC: 'Habilitar a managers la gestión directa de vacaciones',
  specialProcessDefinition: 'Definición de procesos especiales',
  accountingImputations: "Imputaciones contables",
  typesOfBenefits: "Tipos de beneficios",
  typesOfScore: "Notas para evaluaciones",
  ratingScales: "Escalas para calificaciones",
  benefitsForPlans: "Beneficios para planes",
  recognitionPlansAssignment: "Asignación de reconocimientos",
  organizationalBenefits: "Beneficios organizacionales",
  dataCapture: "Ingreso de datos vía API",
  myDesktop: "Mi escritorio",
  specialProcessExecution: 'Ejecución de procesos especiales',
  myPeopleSchedule: 'Gestión de horarios',
  mySchedule: 'Mis horarios programados',
  rebuildAccumulators: "Recomposición de acumuladores",
  timeDefinition: "Definición de tiempos",
  turnDefinition: "Definición de turnos",
  schedulesDefinition: "Definición de horarios",
  schedulesProgramming: "Programación de horarios",
  historicAccumulators: "Acumuladores históricos",
  assetsDiscountsEntryByInstallments: "Ingreso por cuotas",
  alertsTemplates: "Plantillas de WhatsApp",
  calendarDefinition: "Definición de calendarios",
  issueManagementScreenSelector: "Gestión de incidencias",
  issueSummary: "Resumen de incidencias",
  issueLicences: "Gestión de licencias médicas",
  issuePermissions: "Gestión de permisos",
  issueAbsences: "Gestión de inasistencias",
  issueVacations: "Gestión de vacaciones",
  consentTypes: "Tipos de consentimiento",
  benefitsCenter: "Centros de costo",
  projects: "Proyectos",
  consentPublication: "Publicación de consentimientos",

  VACATIONS_ENABLED: 'Habilitar solicitud de vacaciones',
  PERMISSIONS_ENABLED: 'Habilitar solicitud de permisos',
  LOANS_ENABLED: 'Habilitar solicitud de anticipos',
  historicalProcesses: "Procesos históricos",
  myApprovedConsents: "Mis consentimientos aprobados",
  consentsScreenSelector: "Consentimientos",
  documentsScreenSelector: "Documentos",
  pollScreenSelector: "Encuestas",
  PROCESS_USER: 'Código de usuario para cálculos en alertas',
  userTablesDefinition: 'Editores de tablas de usuario',
  extensibility: 'Extensibilidad',
  catalogs: 'Catálogo de base de datos',
  ESQVAC: 'Esquema de cálculo de vacaciones',
  userTables: 'Editor de tablas de usuario',
  relatedTablesDefinition: 'Definición de tablas relacionadas',
  extensibilityScreenSelector: 'Modelamiento',
  systemTablesDefinition: 'Editores de tablas del sistema',
  systemTables: 'Editor de tablas del sistema',
  GT_ALERT2: 'Trabajo en día no programado',
  GT_ALERT3: 'Marcas inconsistentes',
  GT_ALERT4: 'Hrs. Ext. Sin Autorizar',
  GT_ALERT5: 'Atraso en la Entrada',
  GT_ALERT6: 'Ausencias',
  GT_ALERT7: 'Retiro Anticipado',
  GT_ALERT8: 'Demora en el Break',
  GT_ALERT9: 'Cambio del horario planificado',
  TRACE_DATA: 'Activar la trazabilidad de datos',
  CONNECTED_USERS_ENABLED: "Visualizar los usuarios en línea",
  transactionLog: 'Log de transacciones',
  assistanceAnalysis: 'Análisis de asistencia',
  GT_PROC: 'Proceso especial a ejecutar para el análsisis de asistencia',
  GT_HIGHLIGHT_YELLOW: 'Horas y minutos de asistencia adicional para destacar en amarillo',
  GT_HIGHLIGHT_RED: 'Horas y minutos de asistencia adicional para destacar en rojo',
  GT_SELECT_WORKPLACE: 'Permite seleccionar lugar de trabajo al marcar desde la plataforma WEB',
  CON_BENEF: 'Consentimiento para uso de beneficios',
  PERM_BENEF: 'Permiso para los días ganados',
  CTO_BENEF: 'Concepto para pago directo de beneficios en dinero',
  recognitionPlans: 'Planes de reconocimiento',
  feedbackPurpose: 'Propósitos de feedback',
  reasonForPointAwarding: 'Motivos para la entrega de puntos',
  myBenefits: 'Mis beneficios',
  actions: 'Mis acciones y recordatorios',
  recognitionScreenSelector: 'Reconocimientos',
  recognitionAnalysis: 'Análisis de reconocimientos',
  actionTypes: 'Tipos de acciones',
  pollComparison: 'Comparación de encuestas',
  feed: 'Social',
  HABPUBMURO: 'Habilitar muro social',
  DURPUBMURO: 'Duración de publicaciones en muro social',
  feedback: 'Feedback',
  communicationChannel: 'Canal de comunicaciones',
  communicationClassification: 'Clasificación de comunicaciones',
  userMenus: 'Definición de menús de usuario',
  socialBlacklist: 'Usuarios bloqueados en Social',
  performanceDefinition: 'Definiciones de desempeño',
  periodsForObjectives: 'Períodos para objetivos',
  typesOfObjectives: 'Tipos de objetivos',
  positionGroupsForEvaluations: 'Grupos de cargos para evaluaciones',
  typesOfSkillsSpecific: 'Competencias específicas',
  typesOfSkillsTransversal: 'Competencias transversales',
  goalsAndObjectivesTitle: 'Metas y objetivos',
  myGoalsAndObjectivesTitle: 'Mis metas y objetivos',
  talentAcquisitionSettings: "Definiciones de adquisición del talento",
  performanceForms: 'Formularios de desempeño',
  evaluationProcessDefinition: 'Procesos de evaluación',
  USER_TO_PUBLISH_RECOGNITION: 'Usuario para publicar reconocimientos en muro',
  performanceAnalytics: 'Analítica del desempeño y talento',
  performanceAnalyticsEvaluations: 'Analítica de evaluaciones del desempeño',
  performanceAnalyticsHistoric: 'Análisis histórico del desempeño',
  performanceAnalyticsTalent: 'Analítica del talento',
  ALLOW_CALIBRATION_IN_TALENT_CLASSIFICATION: 'Se permite calibrar en la clasificación del talento',
  ALLOW_ACCEPT_REJECT: 'Se permite que el evaluado acepte u objete su evaluación',
  utils: 'Descargas de utilidad',
  onboardingMaterials: 'Materiales y recursos de onboarding',
  onboardingScreenSelector: 'Onboarding',
  onboardingProcesses: 'Procesos de onboarding',
  onboardingProcessesAssignment: 'Asignación a procesos de onboarding',
  onboardingTracking: 'Seguimiento de onboarding',
  myOnboardingTracking: 'Mis procesos de onboarding',
};

export default stringsES;
