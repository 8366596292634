const paths = {
    home: "/",
    aboutUs: "/aboutUs",
    /*path de grupo de menu*/
    settings: "/settings",
    personal: "/personal",
    performanceManagement: "/performanceManagement",
    talentAcquisition: "/talentAcquisition",
    requests: "/requests",
    remunerations: "/remunerations",
    timeManagement: "/timeManagement",
    management: "/management",
    calculationManagement: "/calculationManagement",
    positions: "/positions",
    extensibility: "/extensibility",
    engagement: "/engagement",
    /*fin path de grupo*/

    /*paths especiales*/
    fiscalization: "/fiscalization",
    registerAssistanceTotem: "/registerAssistanceTotem",
    /*fin paths especiales*/

    /*path de pantallas*/
    myAssistance: "/myAssistance",
    assistance: "/assistance",
    queries: "/queries",
    myPeople: "/myPeople",
    employeeMaster: "/peopleMaster",
    kpiIndicators: "/kpiIndicators",
    myQueries: "/myQueries",
    myPending: "/myPending",
    myPendingEmployer: "/myPendingEmployer",
    myDigitalFolder: "/myDigitalFolder",
    myAnnotations: "/myAnnotations",
    requestControl: "/requestControl",
    requestControlHistorical: "/requestControlHistorical",
    delegations: "/delegations",
    myRequests: "/myRequests",
    myRequestsManager: "/myRequestsManager",
    actions: "/actions",
    receipts: "/receipts",
    digitalReceipts: "/digitalReceipts",
    loans: "/loans",
    directory: "/directory",
    alerts: "/alerts",
    pollReview: "/pollReview",
    certificates: "/certificates",
    digitalCertificates: "/digitalCertificates",
    myPersonalData: "/myPersonalData",
    myCertificates: "/myCertificates",
    myReceipts: "/myReceipts",
    myHistoricalReceipts: "/myHistoricalReceipts",
    menuSettings: "/menuSettings",
    changeSociety: "/changeSociety",
    changeTheme: "/changeTheme",
    logOut: "/logOut",
    myPIN: "/myPIN",
    signers: "/signers",
    loansDetailEmployer: "/loansDetailEmployer",
    loansDetailCollaborator: "/loansDetailCollaborator",
    myPendingSecondGrid: "/myPendingSecondGrid",
    myRequestsSecondGrid: "/myRequestsSecondGrid",
    totems: "/totems",
    vacations: "/vacations",
    myVacations: "/myVacations",
    vacationsDetailDebit: "/vacationsDetailDebit",
    vacationsDetailCredit: "/vacationsDetailCredit",
    digitalSignature: "/digitalSignature",
    requestDocuments: "/requestDocuments",
    requestedDocuments: "/requestedDocuments",
    signedDocuments: "/signedDocuments",
    showDocs: "/showDocs",
    myGroups: "/myGroups",
    notes: "/notes",
    notesDialog: "/notesDialog", //para la grilla en el modal
    generalSettings: "/generalSettings",
    digitalFolderSettings: "/digitalFolderSettings",
    externalDocuments: "/externalDocuments",
    digitalDocuments: "/digitalDocuments",
    publications: "/publications",
    pollSettings: "/pollSettings",
    accessSettings: "/accessSettings",
    annotationsAnalysis: "/annotationsAnalysis",
    historicalProcesses: "/historicalProcesses",
    currentProcesses: "/currentProcesses",
    currentProcessesEmployees: "/currentProcessesEmployees", //para la grilla de empleados del modal
    digitalFolderAnalysis: "/digitalFolderAnalysis",
    digitalFolderAnalysisNotAvailable: "/digitalFolderAnalysisNotAvailable", //para el segundo columnDef
    employeesProcesses: "/employeesProcesses", //para la pantalla de consulta de empleados de proceso
    employeesProcessesResults: "/employeesProcessesResults", //para modal de la pantalla de consulta de empleados de proceso
    employeesProcessesOthers: "/employeesProcessesOthers", //para modal de la pantalla de consulta de empleados de proceso
    employeesProcessesDetails: "/employeesProcessesDetails", //para modal de la pantalla de consulta de empleados de proceso
    employeesProcessesReferences: "/employeesProcessesReferences", //para modal de la pantalla de consulta de empleados de proceso
    geoanalysis: "/geoanalysis",
    publicationSettings: "/publicationSettings",
    centersSettings: "/centersSettings",
    permissionsSettings: "/permissionsSettings",
    documentPublication: "/documentPublication",
    positionEditor: "/positionEditor",
    familySettings: "/familySettings",
    functionsSettings: "/functionsSettings",
    advancesSettings: "/advancesSettings",
    requestsSettings: "/requestsSettings",
    contractTypesSettings: "/contractTypesSettings",
    conceptGroups: "/conceptGroups",
    contractorSettings: "/contractorSettings",
    functionaryTypesSettings: "/functionaryTypesSettings",
    locationSettings: "/locationSettings",
    locationSettingsBox: "/locationSettingsBox",
    worksAndTasksSettings: "/worksAndTasks",
    divisionsSettings: "/divisionsSettings",
    conceptsAndFormulasScreenSelector: "/conceptsAndFormulasScreenSelector",
    conceptsAndFormulas: "/conceptsAndFormulas",
    calculationsFunctions: "/calculationsFunctions",
    accumulatorSettings: "/accumulatorSettings",
    organizationalDefinitions: "/organizationalDefinitions",
    categoriesSettings: "/categoriesSettings",
    specialtiesSettings: "/specialtiesSettings",
    payrollTypesSettings: "/payrollTypes",
    allergiesExamsDiseases: "/allergiesExamsAndDiseases",
    allergiesSettings: "/allergiesSettings",
    examsSettings: "/examsSettings",
    diseasesSettings: "/diseasesSettings",
    calculationReferences: "/calculationReferences",
    entryExitTypes: "/entryExitTypes",
    entryTypes: "/entryTypes",
    exitTypes: "/exitTypes",
    thirdPartySettings: "/thirdPartySettings",
    organizationStructure: "/organizationStructure",
    orgChart: "/orgChart",
    geographicalStructure: "/geographicalStructure",
    issueTypesSettings: "/issueTypesSettings",
    absenceTypes: "/absenceTypes",
    permissionTypes: "/permissionTypes",
    licenceTypes: "/licenceTypes",
    positionsClassification: "/positionsClassification",
    pollReviewSentiments: "/pollReviewSentiments",
    managementDefinition: "/managementDefinition",
    queryDefinition: "/queryDefinition",
    queryDefinitionParameters: "/queryDefinitionParameters", //para la grilla, no se usa en router
    geoQuery: "/geoQuery",
    geoQueryParameters: "/geoQueryParameters", //para la grilla, no se usa en router
    generalValues: "/generalValues",
    kpiDefinition: "/kpiDefinition",
    typesOfBenefits: "/typesOfBenefits",
    alertsSettings: "/alertsSettings",
    userAlertsSettings: "/userAlertsSettings",
    systemAlertsSettings: "/systemAlertsSettings",
    conceptStructure: "/conceptStructure",
    conceptsSet: "/conceptSet",
    extraHoursAuthorization: "/extraHoursAuthorization",
    thirdPartyClassification: "/thirdPartyClassification",
    thirdParty: "/thirdParty",
    laborUnions: "/laborUnions",
    paymentTypes: "/paymentTypes",
    documentTemplates: "/documentTemplates",
    assetsAndDiscountsScreenSelector: "/assetsDiscounts",
    createAssetsDiscounts: "/createAssetsDiscounts",
    assetsDiscountsSummary: "/assetsDiscountsSummary",
    attendanceReport: "/attendanceReport",
    entryInInstallments: "/entryInInstallments",
    entryPerConceptSet: "/entryPerConceptSet",
    entryPerSociety: "/entryPerSociety",
    entryPerConcept: "/entryPerConcept",
    digitalWarehouseFileTypes: "/digitalWarehouseFileTypes",
    trainingData: "/trainingData",
    schooling: "/schooling",
    studies: "/studies",
    specializations: "/specializations",
    languages: "/languages",
    holidayTable: "/holidayTable",
    extraHoursTable: "/extraHoursTable",
    festivitiesTable: "/festivitiesTable",
    benefitsDefinitions: "/benefitsDefinitions",
    benefitsForPlans: "/benefitsForPlans",
    organizationalBenefits: "/organizationalBenefits",
    affiliationInstitutionDefinition: "/affiliationInstitutionDefinition",
    retirementDefinition: "/retirementDefinition",
    healthDefinition: "/healthDefinition",
    digitalWarehouse: "/digitalWarehouse",
    myDigitalWarehouse: "/myDigitalWarehouse", //para ocultar columnas del aggrid
    ratingsAndGeneralValues: "/ratingsAndGeneralValues",
    familyAsignations: "/familyAsignations",
    ratings: "/ratings",
    taxTable: "/taxTable",
    massEntry: "/massEntry",
    entryByImport: "/entryByImport",
    dataCapture: "/dataCapture",
    specialProcessDefinition: "/specialProcessDefinition",
    accountingImputations: "/accountingImputations",
    processScheduling: "/processScheduling",
    myDesktop: "/myDesktop",
    mySchedule: "/mySchedule",
    specialProcessExecution: "/specialProcessExecution",
    myPeopleSchedule: "/myPeopleSchedule",
    rebuildAccumulators: "/rebuildAccumulators",
    timeDefinition: "/timeDefinition",
    turnDefinition: "/turnDefinition",
    schedulesDefinition: "/schedulesDefinition",
    schedulesProgramming: "/schedulesProgramming",
    historicAccumulators: "/historicAccumulators",
    alertsTemplates: "/alertsTemplates",
    calendarDefinition: "/calendarDefinition",
    issueManagementScreenSelector: "/issueManagementScreenSelector",
    issueSummary: "/issueSummary",
    issueSummaryGrouping: "/issueSummary/grouping",
    issueSummaryMatrix: "/issueSummary/matrix",
    issueLicences: "/issueLicences",
    issuePermissions: "/issuePermissions",
    issueAbsences: "/issueAbsences",
    issueVacations: "/issueVacations",
    consentTypes: "/consentTypes",
    benefitsCenter: "/benefitsCenter",
    projects: "/projects",
    consentPublication: "/consentPublication",
    familyGroup: "/familyGroup",
    historicalPeriods: "/historicalPeriods",
    distributionCost: "/distributionCost",
    myApprovedConsents: "/myApprovedConsents",
    documentsScreenSelector: "/documentsScreenSelector",
    consentsScreenSelector: "/consentsScreenSelector",
    pollScreenSelector: "/pollScreenSelector",
    userTablesDefinition: "/userTablesDefinition",
    userTablesFieldsDefinition: "/userTablesFieldsDefinition",
    catalogs: "/catalogs",
    catalogFields: "/catalogFields", // para aggrid
    userTables: "/userTables",
    relatedTablesDefinition: "/relatedTablesDefinition",
    extensibilityScreenSelector: "/extensibilityScreenSelector",
    systemTablesDefinition: "/systemTablesDefinition",
    systemTablesFieldsDefinition: "/systemTablesFieldsDefinition",
    systemTables: "/systemTables",
    transactionLog: "/transactionLog",
    assistanceAnalysis: "/assistanceAnalysis",
    assistanceAnalysisIndividual: "/assistanceAnalysis/individual",
    recognitionPlans: "/recognitionPlans",
    feedbackPurpose: "/feedbackPurpose",
    groupClassification: "/groupClassification",
    reasonForPointAwarding: "/reasonForPointAwarding",
    recognitionPlansAssignment: "/recognitionPlansAssignment",
    myBenefits: "/myBenefits",
    recognitionScreenSelector: "/recognitionScreenSelector",
    recognitionAnalysis: "/recognitionAnalysis",
    actionTypes: "/actionTypes",
    talentClassification: "/talentClassification",
    performanceEvaluations: "/performanceEvaluations",
    performanceEvaluationsCalibration: "/performanceEvaluationsCalibration",
    performanceSummaryAndResults: "/performanceSummaryAndResults",
    forms: "/forms",
    performanceForms: "/performanceForms",
    nineBoxSettings: "/nineBoxSettings",
    nineBoxClassification: "/nineBoxClassification",
    pollComparison: "/pollComparison",
    feed: "/feed",
    feedback: "/feedback",
    communicationChannel: "/communicationChannel",
    userMenus: "/userMenus",
    userMenu: "/userMenu",
    socialBlacklist: "/socialBlacklist",
    performanceDefinition: "/performanceDefinition",
    typesOfObjectives: "/typesOfObjectives",
    typesOfScore: "/typesOfScore",
    ratingScales: "/ratingScales",
    positionGroupsForEvaluations: "/positionGroupsForEvaluations",
    periodsForObjectives: "/periodsForObjectives",
    typesOfSkillsSpecific: "/typesOfSkillsSpecific",
    typesOfSkillsTransversal: "/typesOfSkillsTransversal",
    goalsAndObjectives: "/goalsAndObjectives",
    talentAcquisitionSettings: "/talentAcquisitionSettings",
    classificationApplicantsTypes: "/classificationApplicantsTypes",
    typesOfPublicationChannels: "/typesOfPublicationChannels",
    typesOfPublicationFormats: "/typesOfPublicationFormats",
    typesOfOnboardingActions: "/typesOfOnboardingActions",
    onboardingProfiles: "/onboardingProfiles",
    evaluationProcessDefinition: "/evaluationProcessDefinition",
    evaluationProcessDefinitionAssignment: "/evaluationProcessDefinitionAssignment",
    performanceAnalytics: "/performanceAnalytics",
    performanceAnalyticsEvaluations: "/performanceAnalyticsEvaluations",
    performanceAnalyticsTalent: "/performanceAnalyticsTalent",
    communicationClassification: "/communicationClassification",
    utils: "/utils",
    onboardingMaterials: "/onboardingMaterials",
    onboardingScreenSelector: "/onboardingScreenSelector",
    onboardingProcesses: "/onboardingProcesses",
    onboardingProcessesAssignment: "/onboardingProcessesAssignment",
    onboardingTracking: "/onboardingTracking",
    myOnboardingTracking: "/myOnboardingTracking",
    performanceAnalyticsHistoric: "/performanceAnalyticsHistoric",
}

export const PATHS_TO_IGNORE_IN_SEARCH = [
    paths.recognitionPlansAssignment,
    paths.evaluationProcessDefinitionAssignment,
    paths.onboardingProcessesAssignment,
]

export const PATHS_TO_IGNORE_AS_VISITED = [
    paths.home,
    paths.myDesktop,
    paths.alerts,
    paths.locationSettingsBox,
    paths.entryExitTypes,
    paths.thirdPartySettings,
    paths.issueTypesSettings,
    paths.trainingData,
    paths.allergiesExamsDiseases,
    paths.organizationalDefinitions,
    paths.affiliationInstitutionDefinition,
    paths.benefitsDefinitions,
    paths.requestsSettings,
    paths.publications,
    paths.alertsSettings,
    paths.conceptsAndFormulasScreenSelector,
    paths.ratingsAndGeneralValues,
    paths.assetsAndDiscountsScreenSelector,
    paths.managementDefinition,
    paths.userMenu,
    paths.recognitionPlansAssignment,
    paths.evaluationProcessDefinitionAssignment,
]

export default paths;