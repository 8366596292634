const stringsEN = {
  spanish: "Español",
  english: "English",
  Personal: "Personal",
  create: "Create",
  chat: "Chat",
  assignPIN: "PIN assignation",
  roleAccess: "Access roles",
  icariusTech: "ICARIUS technology",
  createdBy: "Created by",
  contractEndDate: "Contract end date",
  maxCharPossible: "Max. allowed:",
  cellphoneVisible: "Your cellphone number will be visible",
  cellphoneVisibleShort: "Cellphone number",
  civilStateVisible: "Your marital status will be visible",
  civilStateVisibleShort: "Marital status",
  bornDateVisible: "Your birth date will be visible",
  bornDateVisibleShort: "Birth date",
  personalAddressVisible: "Your address will be visible",
  personalAddressVisibleShort: "Address",
  showUnreadAlerts: "Unread",
  showAllAlerts: "All",
  alertsFilterBy: "Filter by",
  references: "References",
  comingSoon: "Coming soon",
  completeFields: "There are not complete values ​​in the form",
  offsetY: "Y offset",
  offsetX: "X offset",
  alertChangeProfilePhoto:
    "Dear collaborator: If you wish to upload or change your photo, please consider choosing a personal picture for corporative use. Thank you.",
  zoom: "Zoom",
  rotation: "Rotation",
  news: "News",
  uploadNewPhoto: "Upload new photo",
  nineBoxSettings: "Definición de 9-Boxes",
  talentClassification: "Clasificación del talento",
  performanceEvaluations: "Evaluaciones del desempeño",
  performanceEvaluationsCalibration: "Calibración del desempeño",
  performanceSummaryAndResults: "Resumen y resultados",
  forms: "Formularios",
  performanceManagement: "Gestión del desempeño",
  talentAcquisition: "Adquisición del talento",
  modifyMyPhoto: "Modify profile picture",
  correctlyModifiedPhoto: "Your photo has been updated successfully",
  dataUpdate: "Data Update",
  correctlyModifiedPersonalData: "Your personal information has been updated successfully",
  myPersonalData: "Personal Data",
  rightPannelSwitchIndication: "The following data will be visible in Directory",
  help: "Help",
  personales: "Personal",
  contrato: "Contract",
  remuneracion: "Remuneration",
  ubicacion: "Ubication",
  tiempos: "Time",
  timeZones: "Time Zones",
  //Chat
  selectContactToStartConversation: "Select a contact",
  startConversation: "Start a conversation",
  messagePlaceholder: "Type a message...",
  sombrear: "Highlight",
  //Color
  selectColorTitle: "Pick a color",
  theme: "Theme and colors",
  color: "Color",
  green: "Green",
  red: "Red",
  lightBlue: "Light Blue",
  blue: "Blue",
  gold: "Gold",
  orange: "Orange",
  silver: "Silver",
  teal: "Teal",
  purple: "Purple",
  pink: "Pink",
  brown: "Brown",

  of: "of",
  pinWillChange: "PIN change or assignation",
  recoverPinDialogText: "PIN for the user {user} and client {client} will be updated or assigned",
  albanyTechnologies: "Albany Technologies",

  //contacto
  contact: "Contact",
  undefinedContact: "No contact data has been set",
  aboutUsTitle: "ICARIUS (ODESA)",

  //My Prople
  thisPage: "Export page",
  allPages: "Export all",
  myPeopleTableTitle: "My people",
  everyone: "Everyone",
  activeAndPlantPersonnel: "Active and plant Personnel",
  id: "Id",
  code: "Employee code",
  name: "Name",
  nameAndSurname: "Name and surname",
  liquidoNeto: "Liquido/Neto",
  income: "In date",
  coin: "Coin",
  salary: "Salary",
  filter: "Filter",
  close: "Close",
  contactQr: "Contact QR",
  export: "Export",
  searchLabel: "Search",
  filterBy: "Filter by",
  noEmployeesToShow: "No employees to show",
  newAnnotation: "Enter new annotation",
  noAnnotationsOfThisType: "There are no annotations of the selected type",
  noAnnexesForThisFilter: "There are no files for the selected filters",
  selectType: "Select type / Reset",
  selectInstance: "Select instance / Reset",
  iconNewAnnotation: "New annotation",
  iconEditAnnotation: "Edit annotation",
  merit: "Merit",
  demerit: "Demerit",
  noType: "Annotation with no type",
  noSubtype: "No subtype",
  subtypeTitle: "By annotation subtype",
  categoryTitle: "By annotation category",
  meritsTitle: "By merits and demerits",
  noAnnotations: "No annotations found",
  annotationsConfirm: "Do you want to delete the annotation?",
  visibleColaborador: "Visible to the collaborator",
  invisibleColaborador: "Not visible to the collaborator",
  availableColaborador: "Available to the collaborator",
  notAvailableColaborador: "Not available to the collaborator",
  maxChar: "Max. allowed: 512",
  currentChar: "Character count:",
  enterText: "Enter text",
  fieldRequeriment: "All fields are required.",
  type: "Type",
  instance: "Instance",
  subtype: "Subtype",
  lastStatus: "Last status",
  status: "Status",
  viewAnnotationTitle: "Annotation number, category and subtype",
  viewAnnotationTime: "Creation time and date",
  viewAnnotationNewTime: "Update time and date",
  viewAnnotationVisibility: "Visibility",
  viewAnnotationContent: "Annotation content",
  viewAnnexVisibility: "Visibility",
  iconNewAnnex: "New file",
  viewAnnexTitle: "File number, instance and type",
  viewAnnexContent: "File text",
  selectAnnex: "Select file",
  viewAnnexFile: "File document",
  annexConfirm: "Do you want to delete the file?",
  iconEditAnnex: "Edit file",
  annexNoType: "No type",
  maxCharAnnex: "Max. allowed: 250",
  documentsListTitle: "File List",
  noAnnexYet: "No file selected",
  clicIndication: "Click on graphics to display list",
  downloadAnnexFolder: "Download folder",
  downloadAnnexFolderConfirm: "Do you want to download the folder?",
  noAnnexes: "No files found",
  annexFechaVencimiento: "Expiration date",
  annexDateHelper: "If you enter an expiration date, it must be greater than the current date",
  annexRequired: "Required",
  annexNotRequired: "Not required",
  expires: "Expires on:",
  by: ", by ",
  notAvailable: "No available",
  newEmployee: "New employee",
  scheduleProgrammed: "Schedule",
  assistanceStatus: "Assistance status",
  //
  documents: "Documents",
  contracts: "Contracts",
  payroll: "Payroll",
  absences: "Absences",
  times: "Times",
  familiarGroup: "Familiar group",
  fatherLastName: "Paternal last name",
  firstName: "First name",
  employeeNumber: "Employee number",
  officePhone: "Office phone number",
  particularPhone: "Personal phone number",
  bloodType: "Blood type",
  age: "Age",
  passportNumber: "Passport number",
  expirationPassport: "Passport expiration",
  motherLastName: "Maternal last name",
  secondName: "Second name",
  extension: "Extension",
  donante: "Donor",
  contractCode: "Contract code",
  contractAmount: "Contract amount",
  antiquityDate: "Antiquity date",
  contractMotive: "Hiring motive",
  legalMotive: "Legal motive",
  calculationMotive: "Calculation motive",
  familyAllowanceSection: "Family allowance section",
  additionalMonthVacation: "Additional months vacation",
  additionalVacationDate: "Additional vacation date",
  additionalDaysVacation: "Additional days vacation",
  previsionInstitution: "Prevision institution",
  affiliationDate: "Previsional affiliation date",
  healthInstitution: "Health institution",
  heavyWork: "Heavy work",
  extremeZoneReduction: "Extreme zone reduction",
  extremeZonePercentage: "Extreme zone percentage",
  taxOtherCountry: "Tax in other country",
  valueOtherCountry: "Value in other country",
  ineClassif: "INE clasification",
  specialCapabilities: "Special capabilities",
  businessSalary: "Business salary",
  icariusMenuProfile: "Profile menu ICARIUS",
  calendarType: "Calendar type",
  scheduleControlType: "Schedule control type",
  cardCode: "Credential/Card code",
  freedExtraHours: "Extra hours freed",
  canMarkMobile: "Can mark from mobile",
  canMarkICARIUS: "Can mark from ICARIUS",
  canCapturePosition: "Can capture geoposition",
  updateOK: "Data updated succesfully",
  createdOK: "Employee created succesfully",
  namesAndLastnames: "Full name",
  employeeMasterSidebarTitle: "Complementary data",
  accessPerm: "Access permission",
  otherData: "Other",
  contratacionType: "Hiring type",
  creationUserPhotoInfo: "Changes will be applied after user is created",
  icariusProfile: "ICARIUS profile",
  groups: "Groups",
  personalesTitle: "personal",
  ubicacionTitle: "ubication",
  tiemposTitle: "time",
  contratoTitle: "contract",
  remuneracionTitle: "remuneration",

  //Receipts
  receiptsTableTitle: "Receipts",
  myreceiptsMessage: "Your receipt has been download and you already have registed its acceptance on {accepted_date}, at {accepted_time} hrs",
  receipts: "Receipts",
  certificates: "Certificates",
  receiptsViewAll: "All",
  receiptsViewSignPending: "Documents to sign",
  receiptSignNotFound: "Requested documents not found",
  receiptSignFiltered: "Digital sign data has been filtered successfully",
  receiptUnauth: "You are not authorized as document signer",
  receiptWrongPing: "Incorrect PIN",
  receiptWrongDocument: "There has been a problem with one of the selected documents, please try again",
  receiptSuccess: "Receipts signed successfully",

  // Certificates
  certificatesViewAll: "All",
  certificatesViewSignPending: "Documents to sign",
  certificateSignNotFound: "Requested documents not found",
  certificateSignFiltered: "Digital sign data has been filtered successfully",
  certificateUnauth: "You are not authorized as document signer",
  certificateWrongPing: "Incorrect PIN",
  certificateWrongDocument: "There has been a problem with one of the selected documents, please try again",
  certificateSuccess: "Certificates signed successfully",

  //People grid
  hrefState: "",
  employeeCode: "Employee code",
  names: "Names",
  internalNumber: "Intern number",
  state: "Status",
  email: "Email",
  fType: "Position type",
  position: "Position",
  positionDate: "Position assignment date",
  function: "Function",
  functionDate: "Function assignment date",
  civilState: "Civil state",
  sex: "Sex",
  nationality: "Nationality",
  category: "Category",
  specialty: "Specialty",
  bornDate: "Birth date",
  linkedin: "LinkedIn",
  photo: "",
  direcMaps: "",
  address: "Address",
  comuna: "Commune",
  provicia: "Province",
  region: "Region",
  country: "Country",
  postalCode: "Postal code",
  cellphone: "Cellphone number",
  phoneExtension: "Phone extention",
  contractType: "Contract type",
  incomeDate: "Income date",
  outcomeDate: "Outcome date",
  paymentLocation: "Payment location",
  jobLocation: "Job location",
  management: "Management",
  department: "Department",
  section: "Section",
  paymentMethod: "Payment method",
  bank: "Bank",
  bankAccount: "Bank account",
  bankAccountType: "Bank account type",
  salaryUnit: "Salary unit",
  jornadaType: "Working day type",
  hoursPerDay: "Hours per day by contract",
  hoursPerMonth: "Hours per month by contrato",
  laborUnion: "Labor union",
  retired: "Retired",
  shift: "Shift",
  shiftDate: "Shift assignment date",
  comments: "Comments",
  opinion: "Opinion",
  workPhone: "Work phone number",
  phone: "Phone number",
  responsibleEmail: "Responsible to answer Email",
  responsiblePhone: "Responsible to answer Phone number",
  responsibleName: "Responsible to answer",
  requester: "Requester",
  toExcel: "to Excel",
  toJson: "to JSON",
  toXml: "to XML",
  toTxt: "to TXT",
  toPdf: "to PDF",
  plantPersonnel: "Plant personnel",
  contratista: "Contractor",
  noFaceFound: "Face not found in the picture",
  fileSameName: "There already is a file with the same name",
  fileTypeNotValid: "Invalid file type",
  orderByUnread: "Order by unread",
  visualizationGrid: "Visualization Grid",
  vigente: "Active",
  noVigente: "Inactive",
  allAnnotations: "Every annotation",
  annotationsByDate: "From last contract date",
  allAnnexes: "Every annex",
  annexesByDate: "From last contract date",

  //Change pin
  changingPin: "Change PIN",
  currentPin: "Current PIN",
  newPin: "New PIN",
  newPinConfirmation: "Repeat your new PIN",
  pinCorrectlyChanged: "PIN changed successfully. Use your new PIN the next time you login, either on the WEB Platform or on the APP",
  pinRegenerationConfirmed:
    "Check your email box, you've been sent a provisional access PIN.",
  mailSent:
    "Indications to restore your access PIN have been sent to your email.",
  pinChangedLogoutRequest: "El PIN ha sido modificado con éxito. Si tienes acceso a más de una sociedad para el mismo cliente, considera que el nuevo PIN asignado deberá ser utilizado para ingresar en todas las sociedades a partir de este momento. Si deseas permanecer en ICARIUS, por favor, vuelve a ingresar al sistema.",
  //Dialog
  portal: "{role} Portal",
  toolbarTitle: "Albany Technologies",
  disagree: "Cancel",
  agree: "Accept",
  atention: "Atention",
  yes: "Yes",
  no: "No",
  sentTo: "Sent to",
  managerPhone: "Receiver's phone number",
  managerEmail: "Receiver's email",
  lastActionTime: "Last action time",
  lastActionDate: "Last action date",
  //Login
  loginSubtitle: "Personnel's collaborative self-consultation",
  version: "Version",
  loginAs: "Login as",
  collaborator: "Collaborator",
  collaborators: "Collaborators",
  manager: "Manager",
  employer: "Employer",
  recoverPinAccessCode: "Recover access PIN",
  copyright: "Copyright (C) {year} ICARIUS",
  allRightsReserved: "All rights reserved",
  icariusMail: "soporte@icariusglobal.com",
  albanyWebsite: "albany.company",
  icariusWebsite: "www.icariusglobal.com",
  icariusForoWebsite: "forum.icariusglobal.com",
  client: "Client",
  user: "User",
  pin: "PIN",
  key: "Key",
  selectSocietyTitle: "Select a society",
  society: "Society",

  onboardingProfiles: "Perfiles de onboarding",
  //Main - Sliders
  favouriteSliderTitle: "Utility links",
  downloadSliderTitle: "Documents of interest",
  pollSliderTitle: "Polls",
  countersSliderTitle: "Numbers",
  //Default Nav
  directory: "Directory",
  alerts: "Alerts and notices",
  notices: "Notices",
  myPin: "My PIN",
  closeSession: "Log Out",
  menuConfigurator: "Menu settings",
  //Nav
  loginTitle: "Log in",
  mainTitle: "Home",
  typesOfPublicationFormats: "Tipos de formatos para las publicaciones",
  typesOfOnboardingActions: "Tipos de acciones de onboarding",
  typesOfPublicationChannels: "Tipos de canales para las publicaciones",
  classificationApplicantsTypes: "Tipos de clasificación para postulantes",
  myAssistanceTitle: "My assistance",
  assistanceTitle: "Assistance registry",
  myPeopleTitle: "My people",
  nineBoxClassification: "Clasificación 9-Boxes",
  chatTitle: "Chat",
  certificateFullTitle: "Certificates and vouchers",
  myCertificateFullTitle: "My Certificates and vouchers",
  myCertificatesConfirmationTitle: "Do you accept the generated certificate?",
  receiptsFullTitle: "Receipts and payment vouchers",
  receiptsShortTitle: "Payment vouchers",
  myReceiptsFullTitle: "My receipts and payment vouchers",
  myReceiptsConfirmationTitle: "Do you accept the generated receipt?",
  confirmReceipt: "Enter the reason why you don't accept the generated receipt",
  certificateTitle: "Certificates",
  myCertificateTitle: "My certificates",
  noCertificates: "No certificates found",
  selectCertificate: "Certificate",
  noReceipts: "No receipts with digital signature available to download",
  selectReceipt: "Receipt",
  selectCalcMethod: "Calculation method",
  confirmCertificateReceipt:
    "Enter the reason why you don't accept the generated certificate",
  holidaysTitle: "Vacations transaction account",
  loansTitle: "Loans transaction account",
  loansTitleLong: "Loans transaction account",
  charts: "Charts",
  myAnnotationsTitle: "My annotations",
  myAnnexesTitle: "My digital folder",
  myInfoTitle: "My information",
  receiptsTitle: "Receipts",
  myReceiptsTitle: "My receipts",
  myHistoricalReceiptsTitle: "My historical receipts",
  requestsTitle: "Requests",
  requestsControlTitle: "Request control",
  schedulerTitle: "Scheduler",
  decisionCubes: "Decision cubes",
  kpiIndicators: "KPI",
  query: "Queries",
  eventsIncome: "Events income",
  eventsAuth: "Events authorization",
  employeeMaster: "People master",
  myKpiIndicators: "My KPI",
  myPending: "My pending",
  myPendingEmployer: "My pending",
  myQueries: "My queries",
  queries: "Queries",
  myDigitalSign: "My digital signature",
  myDecisionCubes: "My decision cubes",
  groupEvents: "Group events",
  individualEvents: "Individual events",
  parametrization: "Parametrization",
  myRequests: "My requests",
  myRequestsManager: "My requests",
  myRequestsSecondGrid: "myRequestsSecondGrid",
  myPendingSecondGrid: "myPendingSecondGrid",
  timeManagement: "Time management",
  engagement: "Engagement",
  managementNav: "Management",
  calculationManagement: "Calculation management",
  Remunerations: "Remunerations",
  settings: "Settings",
  changeTheme: "Change theme",
  requestedDocuments: "Requested documents",
  signedDocuments: "Signed documents",
  digitalReceipts: "Digital receipts",
  digitalCertificates: "Digital certificates",
  themeAndColors: "Corporate color",
  generalSettings: "General settings",

  //Polls
  next: "Next",
  previous: "Previous",
  pollInfo: "Question {actualNumber} of {totalNumber}",
  submit: "Submit",
  gratitudMessage: "Thank you for participating in our poll!",
  alreadyAnswered: "You already answered in this poll",
  aboveMaximumPoll: "There are answers that surpass the limit",
  required: "Required",
  errorConnection: "There has been a connection problem. Please, try again.",
  anonymousPoll: "This is an anonymous poll.",
  //Grid
  gridCopy: "Copy",
  gridCopyWithHeaders: "Copy with headers",
  gridExport: "Export",
  group: "Group",
  //Directorio
  directoryTableTitle: "Directory",
  tarjetero: "Cards",
  directAccess: "Direct access",
  messageFrom: "Message from ",
  read: "Read",
  markAsRead: "Mark as read",
  markAsUnread: "Mark as unread",

  annotations: "Annotations",
  annex: "Digital folder",
  confirmDelete: "Do you want to delete the template?",
  confirmUpdate: "Do you want to update the template?",
  save: "Save",
  template: "Template name",
  createTemplate: "Create template",
  deleteTemplate: "Delete template",
  exportTemplate: "Export template",
  updateTemplate: "Update template",
  exportQuery: "Export query",
  importQuery: "Import query",
  importTemplate: "Import template",
  selectTemplate: "Select template / reset",
  templateExclusiveToMe: "Exclusive to me",
  templateAllCollaborators: "All collaborators",
  templateAllManagers: "All managers",
  templateAllEmployers: "All employers",
  templateOnlyEmployersPlantPersonnel: "Plant personnel employers only",
  templateOnlyProvidersEmployers: "Provider employers only",
  templateCreate: "Template created succesfully",
  templateImport: "Template imported succesfully",
  templateUpdate: "Template updated succesfully",
  templateDelete: "Template deleted succesfully",
  //encuestas
  pollReview: "Poll review",
  question: "Question",
  visualizationRol: "Visualization role",
  delete: "Delete",
  confirmDeletAlert: "Do you want to delete the alert?",
  shrink: "Shrink all",
  expand: "Expand all",
  answerPollNotice: "You must answer in order to continue",
  myAssistenceTitle: "My assistance",
  data: '"{pregunta}" answers',
  countVoters: "Voters count",
  cant: "Amount",
  questions: "Questions",
  noPollReviewData: "No poll data registered yet.",
  hour: "Hour",
  assistancePlace: "Assistance place",
  date: "Date",
  votos: "Votes",
  noAlertsOrNotification: "No alerts or notices.",
  noUnreadAlertsOrNotification: "No alerts or notices unread.",
  info: "Information",
  clickHereForDetailedAnswers: "Click here to read detailed answers",
  respuestaParentesis: " answers)",
  respuestasParentesis: " answers)",
  answer: "Answer",
  responsible: "Responsible",
  //KPIs
  noKPIdata: "No KPI available at the moment.",
  aboutUs: "About ICARIUS",
  inDate: "In date",
  inHour: "In hour",
  outOfZoneIN: "Out of zone (IN)",
  outDate: "Out date",
  outHour: "Out hour",
  outOfZoneOUT: "Out of zone (OUT)",
  generate: "Generate",
  total: "Total",
  permissions: "Permissions",
  //Loans
  loansDetailEmployer: "loansDetailEmployer",
  loansDetailCollaborator: "loansDetailCollaborator",
  numberLoansGranted: "Amount of loans granted",
  totalValueLoansGranted: "Total value of loans granted",
  totalPaid: "Total paid",
  totalPendingBalance: "Total pending balance",
  totalInstallments: "Total installments",
  totalPaidInstallments: "Total paid installments",
  totalPendingInstallments: "Total pending installments",
  referenceCode: "Reference code",
  referenceInstallment: "Reference installment",
  installmentValue: "Installment value",
  periodDiscount: "Period discount",
  installmentState: "Installment state",
  installmentNumber: "Installmente number",
  grantedValueLoan: "Granted loan value",
  noLoansToShow: "Grid current format doesn't allow graphics. To do so, restore grid to its initial state",
  value: "Value",
  //Solicitudes
  vacationRequestTitle: "Vacations",
  permissionRequestTitle: "Permissions",
  loanRequestTitle: "Loans",
  expenseRequestTitle: "Expense reimbursement",
  permissionType: "Permission type",
  vacationsType: "Vacation type",
  holidays: "Vacations",
  expenses: "Expenses",
  advance: "Loans",
  permission: "Permisions",
  raise: "Raise",
  firing: "Firing",
  requestRequested: "Requested",
  requestCancelled: "Cancelled",
  requestChangedProposed: "Change proposed",
  requestChangeAccepted: "Change accepted",
  requestChangeRejected: "Change rejected",
  requestApproved: "Approved",
  requestRejected: "Rejected",
  loanType: "Loan type",
  loanType2: "Loan type",
  startDate: "Start date",
  newDelegation: "New delegation",
  startDateValidation: "Start date must be greater than current date",
  delegationEndDateValidation: "End date must be greater than start date",
  editStartDateValidation: "Date must be greater than original date",
  addressee: "Addressee",
  endDate: "End date",
  amountOfDays: "Amount of days",
  timePerDay: "Time per day",
  detail: "Detail",
  loanDetail: "Loan detail",
  amountLoanRequested: "Requested loan value",
  amountLoanRequestedValidation: "Value must be greater than 0 an less than ",
  expectedDateOfPay: "Expected payment date",
  fieldRequired: "Field required",
  expenseDeleteConfirmation: "Do you want to delete the expense?",
  newExpenseItem: "New expense",
  noExpenses: "No expenses created yet.",
  expenseAmount: "Expense value",
  requestsInHistory: "Historical requests",
  requestsInProgress: "Requests in progress",
  requestHistory: "Request history",
  cantCreateNewRequest: "It is not possible to create a new request of this type while there is another one of the same type in progress.",
  legalVacation: "Legal vacations",
  additionalVacation: "Additional vacations",
  fullDay: "Full day",
  halfDay: "Half day",
  cancelRequest: "Cancel",
  answerPropose: "Answer proposal",
  approveRequest: "Approve",
  rejectRequest: "Reject",
  editRequest: "Edit",
  optionalField: "Optional",
  maxCharRequests: "Max. allowed: 1024",
  howToSelectMoreDays: "If you want to enter more days than requested, cancel the request indicating the amounts of day proposed in the Detail field.",
  requestEditTitle: "Change proposal in Request of Type",
  requestCreateTitle: "Request of type",
  requestBy: "Last status responsible",
  sendMessage: "Send message",
  youNeedAtLeastOneRow: "You must select at least one row.",
  requestMailConfirm: "Do you want to send a message to the selected responsibles asking for their atention on their requests?",
  mailsHaveBeenSent: "The emails have been successfully sent",
  delegatorName: "Delegator name",
  delegatorCode: "Delegator code",
  delegatorEmail: "Delegator email",
  delegatedName: "Receiver name",
  delegatedCode: "Receiver code",
  delegatedEmail: "Receiver email",
  delegationDateFrom: "Date from",
  delegationDateTo: "Date to",
  delegationSent: "Delegation sent",
  delegationReceived: "Delegation received",
  delegation: "Delegation",
  delegationsTitle: "Delegate requests",
  noDelegationsRows: "You have no delegations sent or received.",
  confirmDeleteDelegation: "Do you want to delete the delegation?",
  proposeChange: "Propose change",
  answerChangeTitle: "Do you approve the proposed change?",
  acceptRequest: "Do you approve the request?",
  cancelRequestTitle: "Do you want to cancel the request?",
  insertComment: "Enter a comment",
  delegationAlready: "There already is another delegation for the user in that date",
  matches: "Matches",
  datesCollide: "Date range selected collides with dates from previous requests",
  days: "days",

  //Query myQueries
  reply: "Answer",
  goBack: "Go back",
  errorExecutingQuery: "Error executing user query",
  noQueryGroups: "There are no query groups available at the moment",
  invalidData: "There is invalid or incomplete data",
  validData: "Data is valid",

  //GRILLAS
  moreOrEqualThan: "More or Equal Than",
  lessOrEqualThan: "Less or Equal Than",
  notContains: "Doesn't cointain",
  contains: "Contains",
  notEqual: "Not equal",
  equal: "Equal",
  year: "Year",
  month: "Month",
  localidad: "Locality",

  gridVideo: "Grids – Part {}",

  //Parameters,
  parameters: "Parameters",
  // Society
  changeSociety: "Change society",
  viewMoreData: "View data security",

  //Notes
  tagName: "Tag name",
  tagTitle: "Tags",
  createTag: "Create tag",
  editTags: "Edit tags",
  orderByNotes: "Order by",
  ascOrder: "Ascendant",
  desOrder: "Descendant",
  archived: "Archived",
  createNote: "New task",
  labelCorrectlyDeleted: "Tag correctly deleted",
  labelCorrectlyModified: "Tag correctly modified",
  labelCorrectlyCreated: "Tag correctly created",
  title: "Title",
  writeHereYourNote: "Write here your task",
  edited: "Last Modification",
  archive: "Archive",
  unarchive: "Unarchive",
  changeLabels: "Change tags",
  addChecklist: "Add checklist",
  addImage: "Add image",
  addReminder: "Add reminder",
  deleteNote: "Delete task",
  cancel: "Cancel",
  clear: "Clear",
  today: "Today",
  ok: "Ok",
  addItem: "Add an item",
  addLabel: "Add tag",
  confirmDeleteNote: "Do you want to delete the task?",
  totemManagementTitle: "Totem Management",
  noTotems: "There are no totems available",
  home: "Home",
  myNotes: "Notes and tasks",
  notes: "Tareas",
  entrada: "Entry",
  salida: "Exit",
  totemCorrectlyUpdated: "Totem correctly updated",
  totemCorrectlyDeleted: "Totem correctly deleted",
  totemCorrectlyAdded: "Totem correctly added",
  promptDeleteTotem: "Do you want to delete the totem?",
  totem: "Totem",
  assistanceTotem: "Assistance totem",
  newAssistanceTotem: "New totem",
  totemCode: "Code",
  totemID: "Totem ID",
  workplace: "Assistance location",
  editTotem: "Edit totem",
  createTotem: "Create totem",
  newTotem: "New totem",
  totemAsociatedInThisDevice: "This is the totem associated to this device",
  noTotemFound: "This totem has been disabled.",
  registryOK: "{Move} has been registered succesfully.",
  invalidAssistanceCredentials: "You can not update your assistance with Client, User and PIN entered.",
  assistanceRegistryDisabled: "Assistance Registry has been disabled. Please contact an administrator.",
  assistanceLicenceExpired: "ICARIUS licence expired. Please contact an administrator",
  cantRegisterAssistance: "Your user is not enabled to register assistance.",
  cantRegisterAssistanceVacation: "You can not update your assistance, because today you are on licence/vacations",
  registerBlocked: "Assistance Registry has been blocked. Please contact an administrator.",
  registerMarkedInasistance: "No puede marcar asistencia porque ya existen inasistencias registradas para el día de hoy",
  availableTotalDays: "Available total days",

  //Holidays
  vacationsTitle: "Vacations Account",
  myVacationsTitle: "Vacations Account",
  vacationsTitleLong: "Vacations Account",
  viewVacationAccountability: "View balances",
  totalAvailableBalance: "Total available balance",
  totalPlusUnusedAvailableBalance: "Saldo total disponible en caso de desvinculación (a la fecha de consulta)",
  additionalBalance: "Additional balance",
  legalOthersBalance: "Lebal balance and others",
  totalDebitUsedDays: "Total debits (Days used)",
  totalCredit: "Total credits (Days earned)",
  totalAdditionalDays: "Total additional days",
  usedAdditionalDays: "Additional days used",
  totalOtherDays: "Total other days",
  usedLegalDays: "Days used (legal and others)",
  totalOtherLegalDays: "Total legal days",
  usedTotalDays: "Total used days",
  availableLegalDays: "Total legal available days",
  availableAdditionalDays: "Additional days available",
  legalDays: "Legal days",
  additionalDays: "Additional days",
  otherDays: "Others days",
  debits: "Debits",
  description: "Description",
  pendingDays: "Pending days",
  movement: "Movements",
  dailyQuantity: "Daily quantity",
  daysQuantity: "Days quantity",
  // Firma digital
  digitalSignature: "Digital signature",
  DigitalSignatureTitle: "Signers",
  newSigner: "New signer",
  promptDeleteSigner: "Do you want to delete the signer?",
  authorizedDocuments: "Authorized documents",
  dateFrom: "Date from",
  hourFrom: "Time from",
  dateEnd: "Date to",
  hourEnd: "Time to",
  activeSigner: "Active signer",
  inactiveSigner: "Inactive signer",
  editSigner: "Update signer",
  noSigners: "There are no signers registered",
  signer: "Signer",
  active: "Active",
  inactive: "Inactive",
  signerCorrectlyDeleted: "Correctly deleted signer",
  signerCorrectlyAdded: "Correctly created signer",
  signerCorrectlyUpdated: "Correctly updated signer",
  edit: "Edit",
  autoGeneratedBySign: "Generated by Digital Sign - Signer: {signer}",
  autoGeneratedBySystem: "Generated by System",
  requestsColabInstructivo: "Colaborator Requests",
  ctaCteVacacionesInstructivo: "Instructive vacations account",
  credits: "Credits",
  confirmDeleteAllReadAlerts: "Do you confirm that you want to delete all read Alerts and Notices?",
  deleteAllReadAlerts: "Dell all read",
  nameSurnameLegalSigner: "Legal signer",
  captionLegalName: "If the legal signer is different from the selected signer, enter surname and first names",
  expectedDateOfDisengagement: "Expected date for disengagement",
  disengagementOperativeType: "Reason for registration in the collaborator's annotations",
  disengagementLegalType: "Type of legal disengagement",
  detailDisengagement: "Detail the reason for the disengagement request",
  mailRequestedDocuments: "Do you want to send the addressee a message regarding their attention on the documents pending of approval?",
  noDocumentsForMail: "No documents pending of approval found",

  //Request Documents
  requestDocuments: "Request documents",
  requestDocumentsTitle: "Request document with digital sign",
  request: "Request",
  noDocumentsRequest: "No documents with digital sign available at the time",
  selectDocument: "Select document",
  documentRequestDuplicated: "There is an on going request for this type of document",
  documentRequestOK: "Request sent",

  requestedDocumentsTitle: "Requested documents",
  requestDate: "Request date",
  requestHour: "Request time",
  typeRequestedDoc: "Type of document requested",
  codeRequestedDoc: "Requested document code",
  sign: "Sign",
  confirmPin: "Confirm PIN",

  //Signed Documents
  download: "Download",
  signDate: "Sign date",
  signTime: "Sign time",
  signDocType: "Document type",
  signDocCod: "Document code",
  signDocName: "Document name",
  signRef: "Reference",
  signerCode: "Signer code",
  signerFullName: "Signer full name",
  signerCodeTo: "Addressee code",
  signerToFullName: "Addressee full name",
  succesfullySignedDocs: "{cant} documents have been successfully signed",


  // Solicitudes II
  requestReceptor: "Request recipient",
  disengagementRequestedFor: "Disengagement requested for",
  reasonToRegisterInDigitalFolder: "Reason for posting to contributor annotations",
  disengagement: "disengagement",
  payRise: "pay rise",
  disengagementTitle: "Disengagement",
  payRiseTitle: "Pay rise",
  payRiseRequestedFor: "Pay rise requested for",
  myReceiptsConfirmDocNotExist: "Aún no está disponible el documento firmado digitalmente. ¿Desea descargarlo de todos modos sin firma digital?",
  previewDocuments: "Preview",
  previewDocumentsTooltip: "Preview before signing",
  signTooltip: "Sign preview documents",
  confirmAcceptRequestedDocuments: "Do you confirm the digital signature of the selected documents?",
  confirmDeleteRequestedDocuments: "Do you confirm the removal of the selected documents?",
  reason: "Reason",
  legalReason: "Legal reason",
  expectedDate: "Expected date",
  newProposedSalary: "Requested value",
  requestedBy: "Requested by",
  derivate: "Delegate",
  derivationSuccessfull: "Request has been delegated successfully",
  derivateRequestTitle: "Request delegation",
  firingLegalType: "Firing legal type",
  multipleSeverances: "Se grabaron los datos de la Desvinculación en el Maestro de Personas, sin embargo se encontró más de UN TIPO DE FINIQUITO habilitado como NOMINA y se HABILITÓ AL COLABORADOR en todos los tipos encontrados, para revisar esta situación, acceda a la función: MAESTRO DE PERSONAS – Datos complementarios – Botón: Nóminas",
  frequency: "Frequency",
  actualSalary: "Current salary",
  salaryCoin: "Salary currency",
  payRiseExpectedDate: "Pay rise expected date",
  newSalaryValue: "New salary value",

  signedDocumentsMailErrorTitle: "These mails could not be sent",
  signedDocumentsDeleteErrorTitle: "These documents could not be deleted",
  signedDocumentsDocumento: "Document",
  signedDocumentsDestinatario: "Addressee",
  signedDocumentsMotivo: "Motive",
  signedDocumentsNotRequired: "Approval not required",
  signedDocumentsInvalidDocument: "Invalid document",
  errorAwaitingColaboratorConfirmationDeleteDocs: "No es posible eliminar éste documento porque pertenece a una solicitud que ya ha sido aprobada y se está esperando el conforme del Colaborador",

  settingDocuments: "Setting documents",

  labelRowsPerPage: "Rows per page",
  nextPage: "Next page",
  previousPage: "Previous page",
  moreThan: "more than",
  deletedDocumentsMotivo: "Motive",
  confirmDeleteDocument: "Document deletion confirmation",
  docsHaveBeenDeleted: "{numberOfDocuments} documents pending approval were successfully deleted",
  invalidDataDeleteDocs: "Invalid data",
  invalidStatusDeleteDocs: "The document is not pending signature",
  invalidSignerDeleteDocs: "Invalid signer",

  generateAndDownloadNoSign: "Generate and download",
  generateAndDownloadWithSign: "Generate and download with digital sign",
  youCanOnlyDeleteDocumentsWithPendingApproval: "Only documents pending approval can be deleted",
  qrExampleDigitalSignCertificatesReceipt: "Example of your digital signature that will be inserted into the document - Acceptance or Rejection",

  socketDisconnect: "Due to server disconnect, Icarius will refresh in a moment.",
  youNeedAtLeastOneRowAprovalPending: "You must select at least one row. The status can only be PENDING APPROVAL.",
  deniedDocumentMessage: "Please enter the reason for the rejection of the document",

  requestCreatedOK: "Request created successfully",
  requestDeletedOK: "Request deleted successfully",
  requestChangeSentSnack: "Change proposed sent succesfully",
  requestChangeAcceptedSnack: "Change proposed accepted",
  requestChangeRejectedSnack: "Change proposed rejected",
  requestAccetedSnack: "Request accepted",
  requestRejectedSnack: "Request rejected",
  newSalaryMustBeGreaterThanPreviousSalary: "The proposed salary must be higher than the current salary of the Collaborator",

  signUnauthDocuments: "You are not authorized to sign the selected documents",
  idFiscal: "Tax ID",
  companyName: "Company name",
  quantityDocsSigned: "Signed documents",
  dateTimeSign: "Date time sign",
  idFiscalReceptor: "Receiver Tax ID",
  receiver: "Receiver",
  viewCompanies: "View companies",
  documentInspection: "Document inspection",

  registerAssistance: "Register assistance",
  yourLastAssistanceEntryIs: "Your last attendance record was the day:",
  lastAssistanceEntryOrigin: "The origin of this record was:",
  lastAssistanceEntryOriginTotemICARIUS: "ICARIUS totem",
  lastAssistanceEntryOriginWebICARIUS: "ICARIUS website",
  lastAssistanceEntryOriginAppICARIUS: "ICARIUS Assistance App",
  lastAssistanceEntryOriginClock: "Third-party capture clock",
  lastAssistanceEntryOriginManualManager: "Manager manual record",
  lastAssistanceEntryOriginManualEmployer: "Employer manual record",
  exit: "EXIT",
  entrance: "ENTRANCE",

  //Fiscalizacion
  fiscalizationLoginTitle: "Access to digital documents fiscalization",
  fiscalizationIngresar: "Enter",
  fiscalizationCambiar: "Change",
  fiscalizationRecuperarPass: "Recover password",
  fiscalizationEnd: "Finish fiscalization",
  fiscalizationMailMsg: "You can access fiscalization from a link in an email we sent you",
  fiscalizationInvalidToken: "Invalid token. Programmed access time has expired.",
  fiscalizationPassMail: "You can access pass recovery from a link in an email we sent you",
  fiscalizationPassUpdated: "Password updated",
  fiscalizationRecoveryCodeInvalid: "Recovery code invalid",
  fiscalizationPinReq: "PIN must be between 6 and 20 characters long",

  collabData: "Collaborator data",
  viewButton: "View",

  noNotes: "No tasks found",

  headerSaluteWithName: "Hi {name}!",
  headerSaluteNoName: "Hi!",

  selectTypeOfDocument: "Select type of document",
  noSignable: "You are not authorized to sign documents",
  certificateDigitalFullTitle: "Certificates and vouchers - Digital Signature",
  receiptsDigitalFullTitle: "Receipts and payment vouchers - Digital Signature",

  noDocumentsToGenerate: "No documents to generate found",
  sendNote: "Send task",
  shareNote: "Share task",
  commentAction: "Comment",
  writeHereYourComment: "Write here your comment...",
  editCommentAction: "Save",
  attachments: "Attachments",
  attachFileToNote: "Attach file",
  accept: "Accept",
  refuse: "Refuse",
  newSharedNote: "New shared task",
  newSentNote: "New received task",
  numberOfComments: "{number} comments",
  numberOfCommentsSingular: "{number} comment",
  sharedWith: "Shared with",


  //myGroups
  myGroupsTitle: "My groups",
  newGroup: "New group",
  noGroups: "You're not part of any group yet",
  groupMembers: "Members",
  groupCreateTitle: "Create new group",
  groupEditTitle: "Edit group",
  groupName: "Group name",
  groupDescription: "Description / function",
  groupAddMembers: "Add members",
  groupDeleteQuestion: "Are you sure you want to delete the group?",
  groupCheckValues: "Invalid data, please check your input",
  groupSearchLabel: "Find collaborator",
  groupCurrentMembers: "Current members",
  groupLeave: "Leave group",
  groupValidationError: "Invalid data", //VALIDATION_ERROR
  groupInvalidGroup: "Selected group not found", //INVALID_GROUP
  groupInvalidParticipants: "There's been an error with selected users. Please, try again", //INVALID_PARTICIPANTS
  groupUnauth: "User is not the owner of the group", //UNAUTHORIZED
  groupAtLeastOneUser: "Para crear o modificar, el grupo debe tener al menos un integrante",
  groupLeaveQuestion: "Are you sure you want to leave the group?",
  groupLeaveExplanation: "As you are its owner, group will be deleted for everyone",
  groupMembersTitle: "Group members",
  groupImagesTitle: "Select image",
  groupSelectedMembers: "selected members",
  groupsExplanation: "Groups will help you be even more organized",
  groupsNewGroup: "Create new group",
  societyPeopleAndGroupsSearchLabel: "Find group or collaborator",

  viewComments: "View comments",
  viewCollaborators: "Shared with",
  unauth: "You are not authorized to perform the action",
  attachmentFailedOperation: "An error occurred trying to delete the attachment",
  fileLimitExceeded: "Limit of 12 files per task exceeded",
  fileSizeExceeded: "Attachment exceeds 5MB",
  imageSizeExceeded: "Image exceeds 5 MB",
  invalidFile: "File not supported",
  noFilesUploaded: "File upload error",
  invalidColaboratorsGroups: "Check the list of collaborators and/or groups",
  invalidColaborators: "Check the list of collaborators",
  invalidGroups: "Check the list of groups",
  validationError: "Invalid data",
  invalidParticipants: "There's been an error with selected users. Please, try again",
  commentNotFound: "Comment not found",
  noteNotFound: "Task not found",
  assignResponsible: "Assign responsible",
  cantAssignResponsible: "Can't assign responsible",
  changeResponsible: "Change responsible",
  cantChangeResponsible: "Can't change responsible",
  subTasks: "Subtasks",
  noSubtasks: "No subtasks created",
  noSubtasksTooltip: "No subtasks defined",
  openSubtasks: "Open subtasks",
  tasks: "Progress",
  subtask: "Subtask",
  subtaskProgress: "Subtask progress",
  task: "Task",
  taskProgress: "Task progress",
  taskUpdateDate: "Update date",
  note: "Task",
  progressFilter: "Progress filter",
  progressFilterNoFilter: "No filter",
  progressFilterAbove40: "Above 40%",
  progressFilterAbove7: "Above 70%",
  progressFilterLessEqualThan40: "Below or equal to 40%",
  notAssigned: "No responsible assigned",
  exportQueryOnly: "Export query only",
  exportQueryAndTemplates: "Export query and templates",
  queryImport: "Query successfully imported",
  selectQuery: "Select query",
  subitemEndDateWarning: "End date of each subtask can not be smaller than its progress date, or greater than the parent task's end date",
  itemEndDateWarning: "End date of each task can not be smaller than its progress date, or its subtasks' end date or progress date",
  taskFinished: "Finished",
  taskEndsToday: "Deadline is today",
  taskDelayed: "Delayed",
  taskDelay: "Delay days",
  taskInTime: "With time left",
  taskDaysRemaining: "Days remaining",
  taskNoFilter: "No filter",
  noteInvalidDates: "There are invalid dates in tasks/subtasks",
  noteInvalidResponsible: "You are not authorized to modifiy some of the tasks/subtasks",
  deleteSubtaskConfirm: "Do you want to delete the subtask?",
  deleteTaskConfirm: "Do you want to delete the task?",

  notaSharedOk: "Task shared successfully",
  notaSentOk: "Task sent successfully",
  noteSendAction: "Send",
  noteShareAction: "Share",

  //Parametros generales
  colaboratorsCanModify: "Colaborators will be able to edit:",
  theirPic: "Their photography",
  theirSexCivilNationalityCell: "Their sex, civil state, nationality and cellphone",
  theirBancAndAccount: "Their bank and bank account",
  theirAddress: "Their personal address",
  appCanMarkOutside: "Assistance APP can mark outside of area",
  webLogo: "Logo for web platform",
  documentsLogo: "Logo for document templates",
  mailsLogo: "Logo for mail",
  useDigitalSign: "Use digital signature",
  acceptFiscalization: "Accept remote fiscalization",
  typeOfSignature: "Colaborators' digital signature type",
  accessGroupAdmin: "Group for administraros access",
  rrhhEmail: "HR email for general purpose",
  latLongSociety: "Society's latitude/longitude",
  footerRRHHMail: "HR email footer",
  contactoPhone: "Contact phone",
  contactMail: "Contact email",
  contactCell: "Contact cellphone",
  contactAddress: "Contact address",
  twitter: "Twitter",
  instagram: "Instagram",
  facebook: "Facebook",
  digitalSignDisclaimer: "Digital signature disclaimer",
  latLongCities: "Cities' latitude/longitude",
  employerMasterOrderFields: "Employer master order of fields",

  //ABM Carpeta Digital
  digitalFolderSettingsTitle: "Digital folder definitions",
  digitalFolderSettingsPageTitle: "Digital folder definitions (Mandatory documents)",
  generics: "Generic documents",
  genericsUpdatedOK: "Documents updated succesfully",
  byPosition: "By position",
  documentPosition: "Position",
  documentInstance: "Instance",
  documentMandatory: "Mandatory document",
  selectPosition: "Select position / Reset",
  documentDeletedOK: "Document deleted succesfully",
  documentDeleteQuestion: "Are you sure you want to delete the document's obligatoriness?",
  noDocumentsForPosition: "No documents found",
  createNewDocument: "Create by position",
  editDocument: "Edit by position",
  documentCreatedOK: "Document created succesfully",
  documentUpdatedOK: "Document updated succesfully",
  digitalFolderValidation: "All fields are required",
  documentSamePositionInstanceError: "Document type is already assigned to the same position and instance",
  taskControl: "TASK CONTROL",

  uploadImage: "Upload image",
  fileMustBeImage: "File must be image (.jpg, .jpeg, .png, .bpm or .gif)",

  externalDocuments: "External documents",
  externalDocumentsLong: "External documents to sign",
  documentRole: "Role",
  uploadExternalDocuments: "Upload documents",
  previewExternalDocuments: "Preview documents",
  signExternalDocuments: "Sign documents",
  deleteExternalDocuments: "Delete documents",
  confirmDeleteExternalDocuments: "Do you want to delete the selected documents?",
  confirmSignExternalDocumentsTitle: "Document sign confirmation",
  confirmSignExternalDocuments: "Do you want to sign the selected documents?",
  selectExternalFiles: "Select files to upload",
  filesMustBePdf: "Files must be .pdf",
  filesMustStartWithCode: "Files must start with their employee code followed by underscore (Ie. 12345_file_name.pdf)",
  selectDocumentRole: "Select addressee role",
  externalDocumentTitle: "Enter document title",
  uploadButtonText: "Upload",
  documentUploadedOK: "Documents uploaded succesfully",
  errorUploadFile: "There are documents that don't belong to the {role}s of your dependency.",
  managers: "managers",
  employers: "empleadores",

  //Digital Documents
  myDigitalDocuments: "My digital documents",
  digitalDocuments: "Digital documents",
  noDigitalDocuments: "From the Digital Documents functionality, you can download any document that require your digital signature. This includes any documents you have requested to be signed to employers, as well as those the employer signed on his own and made available to download. At this moment, there are no documents available to be downloaded.",
  digitalDocumentConfirmationTitle: "Do you accept the generated document?",
  rejectDocument: "Enter the reason why you don't accept the generated document",

  documentInvalidTemplate: "Document template not found",
  dateApprobation: "Approbation date",
  timeApprobation: "Approbation time",

  permissionTimeRange: "Valid range time es from 00:10 minutes to {max}:{maxMin} hours",
  permissionTimeNotGreater: "Time can not be greater than original requested time",

  activationDeactivation: "Activation and deactivation ICARIUS",
  PG: "Enable ICARIUS for this society",
  CLIENTE: "Client code",
  STOP: "Service stopped",
  STOPTIME: "Resume time to display",
  STOPMSG: "Message to display",
  requests: "Requests",
  VACMAXCTACTE: "Request vacations only up to the balance",
  RECEP: "Receivers by position or by groups",
  VACPLUSCTACTE: "Máximo de vacaciones adicionales al saldo",
  identificationAndSocietyData: "Identification and data of the company",
  decimalFieldsDefinition: "DEFINICIÓN DE DECIMALES PARA CAMPOS NUMÉRICOS",
  EMAILRRHH: "HR email for generic emails",
  GO_LOCALTIME: "Latitude/Longitude time of the company",
  GO_DTO_RRHH: "HR Email footer",
  GO_TELEFONO: "Contact phone",
  GO_EMAIL: "Contact email",
  GO_CELULAR: "Contact cellphone",
  GO_ADDRESS: "Contact address",
  GO_TWITTER: "X (Twitter)",
  GO_FACEBOOK: "Facebook",
  GO_INSTAGRAM: "Instagram",
  JUSTIFY_QUALIFICATIONS: "El evaluador debe justificar las calificaciones mínimas y máximas?",
  SHOW_BEHAVIOR_SKILLS: "Mostrar comportamiento de indicadores en competencias",
  WEIGHT_DISTRIBUTION_FOR_NON_EXISTENT: "Distribución de ponderados para evaluaciones no existentes",
  SHOW_RECOGNITION_POINTS_IN_ANALYTICS: "Mostrar puntos de reconocimientos en analítica del desempeño",
  SHOW_SALARY_DATA_IN_ANALYITICS: "Mostrar datos salariales en analítica del desempeño",
  ALLOW_COMPLAINTS_CHANNEL: "Habilitar el envío de denuncias",
  GO_LINKEDIN: "Linkedin",
  digitalSign: "Digital signature",
  FIRMADIGITAL: "Use digital signature",
  FISCALIZACION: "Allow remote inspection",
  FIRMACOL: "Collaborator digital signature type",
  DISCLAMER: "Disclaimer for digital signature",
  goalsAndObjectives: "DE LAS METAS Y OBJETIVOS DE DESEMPEÑO",
  COLMETASYOBJ: "Puede el colaborador definir metas y objetivos",
  timezones: "Timezones",
  GO_CITYHOUR: "Latitude/Longitude cities timezones",
  SOCIETYNAME: "Nombre de la sociedad",
  TAXID: "Identificador fiscal",
  QTY_DEC_AMOUNTS: "Importes",
  QTY_DEC_PRICES: "Precios",
  QTY_DEC_RATES: "Tasas",
  QTY_DEC_QUANTITIES: "Cantidades",
  QTY_DEC_PERCENTAGES: "Porcentajes",
  logosAndCustomization: "Logos and customization",
  lightWebsiteLogo: "Logo for web platform with light theme",
  darkWebsiteLogo: "Logo for web platform with dark theme",
  templateLogo: "Logo for document templates",
  emailLogo: "Logo for emails",
  peopleMasterGeneralParameters: "DEL MAESTRO DE PERSONAS",
  SHOW_CONTRACT_DATA: "Muestra datos del CONTRATO",
  SHOW_ABSENT_COLABORATORS_GRID_TO_COLABORATORS: "Mostrar cuadro de colaboradores ausentes en rol del colaborador",
  SHOW_SALARY: "Muestra datos de la REMUNERACION",
  SHOW_TIME_DATA: "Muestra datos de TIEMPOS",
  SHOW_SPECIAL_CLASSIFICATIONS: "Muestra datos de CLASIFICACIONES ESPECIALES",
  ASSUME_DATES: "Asumir fecha de contratación para otras fechas",
  SHOW_OFFICE_PHONE: "Teléfono de oficina",
  SHOW_PERSONAL_PHONE: "Teléfono particular",
  SHOW_PASSPORT_DATA: "Número de pasaporte y expiración",
  SHOW_DOMICILIARY_DATA: "Datos domiciliarios (Excepto la dirección)",
  SHOW_CONTRACT_CODE: "Código de contrato",
  geographicStructureSettings: "De la organización geográfica",
  GEO_DIVISION1: "División geográfica 1",
  GEO_DIVISION2: "División geográfica 2",
  GEO_DIVISION3: "División geográfica 3",
  SHOW_CONTRACT_MOTIVE: "Motivo de contratación",
  SHOW_SECTION: "Sección",
  SHOW_PUBLICATION: "Centro de publicación",
  SHOW_IS_RETIRED: "Jubilado",
  SHOW_WORKER_TYPE: "Tipo de trabajador",
  SHOW_FAMILY_ALLOWANCE_DATA: "Tramo de asignación familiar",
  SHOW_EXTREME_ZONE: "Rebaja por zona extrema y porcentaje",
  SHOW_ADDITIONAL_VACATIONS_DATA: "Meses adicionales de vacaciones, fecha y días",
  SHOW_BUSSINESS_SALARY: "Sueldo empresarial",
  SHOW_HARD_WORK: "Trabajo pesado",
  SHOW_IMPOSED_IN_ANOTHER_COUNTRY: "Impone en otro país y su valor cotizado",
  SHOW_SCHEDULED_NOTICE: "Marcar con aviso programado",
  permissionsGeneralParameters: "Permissions and access to data",
  hideFieldsPeopleMasterGeneralParameters: "Ocultar:",
  colaboratorsWillBeAbleToModifiy: "Collaborators will be able to modify",
  GO_UPDATEPHOTO: "Your photograph",
  EDIT_SEX: "Su sexo",
  EDIT_CIVILSTATUS: "Su estado civil",
  EDIT_NATIONALITY: "Su nacionalidad",
  EDIT_CELLPHONE: "Su celular",
  EDIT_LINKEDIN: "Su LinkedIn",
  EDIT_PERSONAL_EMAIL: "Su Email personal",
  GO_UPDATEBANK: "Your bank and bank account",
  GO_UPDATEDIR: "Your personal address",
  ORDFIELDMAFU: "Employee Master Fields Order",
  GRUPOEMP: "Group for administrator access",
  mobileApps: "Mobile Applications",
  HISTORICAL_DATA_TRACEABILITY: "Histórico de trazabilidad de datos",
  historicalDataDuration: "De las vigencias de datos históricos",
  SOCIAL_FEED_PUBLICATIONS: "Publicaciones en muro social",
  ALERTS_AND_NOTICES: "Alertas y avisos",
  PENDING_REMINDERS: "Acciones pendientes",
  MARCAFUERAZONA: "Assistance APP can check out of zone",
  tooltipWebLogo: ".png min 200x200px",
  tooltipTemplateLogoLine1: "*.jpg with white background",
  tooltipTemplateLogoLine2: "max 300x200px",
  tooltipTemplateLogoLine3: "min 75x75px.",
  tooltipEmailLogoLine1: "*.jpg with white background",
  tooltipEmailLogoLine2: "max 750x750px",
  fillClientGeneralSettings: "Check client code",
  fillStopMsgGeneralSettings: "Check the message to show when the service is stopped",
  fillStopTimeGeneralSettings: "Check the resume time to show when the service is stopped",
  fillHREmailGeneralSettings: "Check HR email for generic emails",
  fillHRDTOGeneralSettings: "Check HR email footer",
  fillContactEmailGeneralSettings: "Check contact email",
  fillMaxPercentageAdvance: "Check maximum percentage of advances",
  fillDisclaimerGeneralSettings: "Check digital signature disclaimer",
  fillFirmaColGeneralSettings: "Check collaborator signature type",
  dataSucessfullySaved: "Data successfully saved",

  //ABM ENCUESTAS
  pollSettings: "Polls definition",
  newPoll: "New poll",
  pollCreation: "Poll creation",
  pollFrom: "From",
  pollTo: "To",
  pollType: "Type",
  pollRole: "Visualization role",
  pollFamily: "Family",
  pollReference: "Reference",
  pollQuestion: "Question",
  pollQuestionType: "Question type",
  pollQuestionOption: "Option",
  pollAddOption: "+ Add option",
  pollName: "Poll name",
  pollDescription: "Description",
  deletePollOption: "Delete option",
  pollOption: "Option",
  confirmDeletePoll: "Do you want to delete the poll?",
  newPollQuestion: "New question",
  pollDeletedOK: "Poll deleted succesfully",
  pollCreatedOK: "Poll created succesfully",
  pollModifiedOK: "Poll modified succesfully",
  questionAtLeastTwoOption: "Question must have at least 2 options",
  pollFieldsRequiredAndOptions: "All fields are required, and questions with options must have at least 2 of them.",
  pollDuplication: "Duplicate",
  pollDuplicationTitle: "Duplicate poll",
  pollFieldsRequired: "All fields are required",
  pollDuplicationMessage: "Please, enter poll name and dates.",
  pollDuplicationMessage2: "This will replace the original information.",
  pollDeleteDialogTitle: "Delete poll",
  pollDeleteIsInUse: "Poll to delete ({name}) already has answers. Do you want to delete it?",
  pollDeleteQuestion: "Do you want to delete poll {name}?",

  //Access Settings
  accessSettingsTitle: "Access and properties",
  manangersAndEmployers: "Managers and employers",
  newManager: "New manager",
  selectManager: "Select manager",
  filterByManager: "Filter by manager",
  managerActive: "Active",
  accessGroups: "Access groups",
  isEmployer: "H.R. Staff (Employer)",
  noManagerFound: "Manager not found",
  managersUpdatedOK: "Update succesfull",
  managersCreatedOK: "Creation succesfull",
  managerDeletedOK: "Delete succesfull",
  errorEmployeeInUseAlready: "There are rows with that employee in use",
  errorTwoEmployersAlready: "There can't be more two rows with the same user and Employer value",
  viewAccessGroups: "View groups",
  currentGroups: "Current groups",
  groupsWarning: "En case no group is selected, access to every group will be granted",
  findGroupByName: "Find group by name",

  onlineUsers: "Online users",
  onlineCollab: "Collaborators",
  onlineManagers: "Managers",
  onlineEmployers: "Employers",

  noteGlobalProgress: "Task global progress",
  noteOwner: "Task owner",
  noteMailSent: "Mail sent successfully",
  noteMailOwner: "Solo puede enviar mensajes a los responsables de las tareas en aquellas notas que son de su propiedad",
  noteTaskGridData: "Task progress",
  noteOwner2: "Note owner",
  noteGlobalProgress2: "Note global progress",
  note2: "Note",

  mailMyPeopleWillBeSent: "Will be sent to {amount} collaborators",
  mailTextPlaceholder: "Write your message here",
  mailLinkPlaceholder: "Insert link",
  mailCoordPlaceholder: "Insert map coordinates",
  mailUploadFile: "Upload file",
  mailLinkText: "Activate Link",
  mailUndoLinkText: "Deactivate Link",
  mailUndoCoordText: "Deactivate Coordinates",
  mailSend: "Send",
  mailFieldInvalid: "Field can not be empty",
  mailCoordInvalid: "Valid coordinates example: -33.42560642629101, -70.6139463736171",
  mailInvalidFileType: "File cannot be .exe",
  mailInvalidFileSize: "File cannot be bigger than 5mb",
  mailFileAttached: "1 file attached",
  mailMyPeopleSent: "Mails sent successfully",

  //Annotations Analysis
  annotationsAnalysisTitle: "Annotations Analysis",
  annoText: "Annotations text",
  noDataToChart: "Grid current format doesn't allow graphics. To do so, restore grid to its initial state",

  sendingMail: "Sending mail...",

  duplicate: "Duplicate",

  selectColabFire: "Select collaborator: Disengagement request",
  selectColabRise: "Select collaborator: Rise request",
  selectedColab: "Selected collaborator",

  //Historical processes
  historicalProcessesTitle: "Historical processes",
  currentProcessesTitle: "Current processes",
  processCode: "Code",
  processDescription: "Description",
  processType: "Type",
  processSubtype: "Subtype",
  processDateFrom: "Date from",
  processDateTo: "Date to",
  processPeriod: "Period",
  processPaymentDate: "Payment date",
  processStatus: "Status",
  processDateLast: "Last process date",
  processTimeLast: "Last process time",
  processNew: "Create process",
  processEdit: "Edit process",
  processCodeChar: "No se permiten espacios ni los siguientes caracteres especiales: / \\ _ : * ¿ ? “ | < > = +",
  processCodeUse: "Code cannot be in use",
  processGridLabel: "Select collaborators related to the calculation process. If none is selected, all will be considered",
  processCreatedOK: "Calculation process created successfully",
  processEditedOK: "Calculation process modified successfully",
  processModifyConfirm: "An already processed calculation process will be modified, therefore it must be processed again with the new data. Do you wish to continue?",
  processNoExist: "Calculation process to modify does not exist",
  processDeletedOK: "Calculation processes deleted successfully",
  processDeleteConfirm: "Are you sure you wish to delete the selected processes?",
  processCalculate: "Calculate",
  calcProcessTraceability: "Traceability of the calculation process",
  processExecutePartialConfirm: "Do you wish to execute a partial calculation process for {amount} collaborators?",
  processExecuteTotalConfirm: "Do you wish to execute a total calculation process for {amount} collaborators?",

  youNeedOneRow: "You must select only one row.",

  //Digital folder Analysis
  digitalFolderAnalysisTitle: "Digital folder analysis",
  digitalFolderNotAvailable: "Available files",
  digitalFolderAvailable: "Missing files",
  digitalFolderSelectInstance: "Select instance",
  digitalFolderEveryInstance: "Every instance",
  digitalFolderInstanceReq: "Debe seleccionar Al ingreso, Al egreso o Ciclo de vida",
  digitalFolderFechadecarga: "Load date",
  digitalFolderHoradecarga: "Load time",
  digitalFolderInstancia: "Instance",
  digitalFolderTipodedocumentooarchivo: "File type",
  digitalFolderTipodedocumentooarchivoFaltante: "Missing file type",
  digitalFolderTextodescriptivo: "Description",
  digitalFolderNombredelarchivo: "File name",
  digitalFolderFechadevencimiento: "Expiration date",
  digitalFolderResponsabledelacarga: "Load responsible",
  digitalFolderSituacion: "Situation",

  accessByGroups: "Access by groups",
  tasksAndTelecommuting: "Tasks and telecommuting",

  //consulta empleados procesos
  employeesProcessesTitle: "Processed collaborators",
  employeesProcessDeleteConfirm: "Are you sure you want to delete selected colaborators from the process?",
  employeesProcessDeletedOK: "Employees from the calculation processes deleted successfully",
  employeesProcessResult: "Result",
  employeesProcessOthers: "Otherss",
  employeesProcessDetail: "Detail",
  employeesProcessReferences: "References",
  employeesProcessDialogTitle: "Queries",
  employeesProcessName: "Nombre",
  employeesProcessAmount: "Cantidad",
  employeesProcessBaseCalculo: "Base de cálculo",
  employeesProcessHaberesAportes: "Haberes con aportes",
  employeesProcessDescuentosLegales: "Descuentos legales",
  employeesProcessHaberesSinAporte: "Haberes sin aportes",
  employeesProcessDescuentosVarios: "Descuentos varios",
  employeesProcessConcepto: "Concepto",
  employeesProcessCodigoConcepto: "Código del concepto",
  employeesProcessNombreConcepto: "Nombre del concepto",
  employeesProcessPorcentaje: "Porcentaje",
  employeesProcessValor: "Valor",
  employeesProcessFecha: "Fecha",
  employeesProcessValueType: "Tipo de valor",
  employeesProcessAnnexComment: "Comentario anexo",
  employeesProcessPeriodoImputacion: "Período de imputación",
  employeesProcessCentroBeneficio: "Centro de costo",
  employeesProcessProyecto: "Proyecto",
  employeesProcessDimension2: "Dimensión 2",
  employeesProcessDimension3: "Dimensión 3",
  employeesProcessDimension4: "Dimensión 4",
  employeesProcessDimension5: "Dimensión 5",
  employeesProcessCodigoReferencia: "Código de referencia",
  employeesProcessReferencia1: "Referencia 1",
  employeesProcessReferencia2: "Referencia 2",
  employeesProcessCantidadConvertida: "Cantidad convertida",
  employeesProcessPeriodoReliquidacion: "Período de reliquidación",
  employeesProcessMesesReliquidar: "Meses a reliquidar",
  employeesProcessEntidad: "Entidad",
  employeesProcessTipoComprobante: "Tipo de comprobante",
  employeesProcessSerieComprobante: "Serie del comprobante",
  employeesProcessNumeroComprobante: "Número del comprobante",
  employeesProcessOrigen: "Origen",
  employeesProcessValorNumerico: "Valor numérico",
  employeesProcessValorAlfanumerico: "Valor alfanumérico",
  employeesProcessValorFecha: "Valor fecha",
  employeesProcessComentarios: "Comentarios",
  employeesProcessTipoValor: "Tipo de valor",
  employeesProcessCodigoAccion: "Código de acción",
  employeesProcessObjetoAccion: "Objeto de acción",
  employeesProcessFechaAccion: "Fecha de la acción",
  employeesProcessAccionesSQL: "Acciones de SQL",

  //geoanalysis
  geoanalysisTitle: "Geoanalysis",
  noGeoanalysisGroups: "There are no query groups available at the moment",
  openMap: "Open map",
  closeMap: "Close map",
  exportAsExcel: "Export as Excel",

  accessosSegmentadoPorGrupos: "Accesos segmentado por grupos",

  selectColumn: "Select column",

  deleteManagerQuestion: "Are you sure you want to delete the manager from this list?",
  deleteEmployerQuestion: "Are you sure you want to delete the employer from this list?",

  publicationSettings: "Home Publications",
  newPublication: "New publication",
  publicationName: "Name",
  publicationFrom: "From",
  publicationTo: "To",
  publicationRole: "Visualization role",
  publicationText: "Text",
  publicationLink: "Link",
  publicationDownload: "Download",
  publicationType: "Type",
  publicationNumber: "Number",
  publicationImage: "Image",
  confirmDeletePublication: "Do you want to delete the publication?",
  publicationDeleteDialogTitle: "Delete publication",
  publicationCreation: "Publication creation",
  publicationDeletedOK: "Publication deleted succesfully",
  publicationCreatedOK: "Publication created succesfully",
  publicationModifiedOK: "Publication modified succesfully",
  publicationExplanation: "Publications will help you keep your organization well informed",
  noPublications: "There are no publications created yet",

  publicationNewsModal: "Highlighted news",
  validationURLPreview: "URL has no preview image. Please, upload an image.",

  publicationInvalidFileType: "Invalid file type",
  publicationFileTypeExplanation: "Must be one of the following: jpg, bmp, png, jpeg, rar, zip, gz, tar, xls, xlsx, txt, rtf, doc, docx, pdf, html",

  downloadAttachment: "Download attachment",
  answerPoll: "Answer poll",

  generatePublications: "Generate publications",

  //filters
  filterbranches: "Sucursales",
  filtercategory: "Categorías",
  filtercontractType: "Tipo de contratos",
  filterfunction: "Funciones",
  filterfunctionType: "Tipo de funcionarios",
  filtergender: "Sexo",
  filtermanagement: "Gerencias",
  filtermaritalStatus: "Estado civil",
  filterpositions: "Cargos",
  filterretiredPersonal: "Jubilados",
  filtershifts: "Turnos",
  filterspecialty: "Especialidades",
  filterunion: "Sindicatos",
  filterworkplaces: "Lugares de trabajo",
  filtermanagementCenters: "Centros de publicación",

  filtersOK: "Filters updated successfully",

  orgChart: "Organigrama",

  //Centers Settings
  centersSettings: "Centros de publicación",
  newCenter: "Nuevo Centro de publicación",
  centerCode: "Código",
  centerName: "Nombre",
  cantDeleteCenter: "No es posible eliminar el Centro de publicación porque está en uso",
  deleteCenterQuestion: "¿Esta seguro que desea eliminar al Centro de publicación de la lista?",
  noCentersFound: "No se encontraron Centros de publicación",
  centerCodePlaceholder: "Ingrese código",
  centerNamePlaceholder: "Ingrese nombre",
  centerDuplicatedData: "No puede existir más de un centro de publicación que compartan el mismo código o nombre",
  centersUpdatedOK: "Actualización finalizada con éxito",
  centersCreatedOK: "Creación finalizada con éxito",
  centerDeletedOK: "Eliminación finalizada con éxito",

  //Permissions settings
  permissionsSettings: "Permisos para solicitudes",
  newPermission: "Nuevo permiso",
  permissionName: "Nombre del permiso",
  permissionCode: "Código del permiso",
  confirmDeletePermission: "¿Desea eliminar el permiso?",
  permissionDeleteDialogTitle: "Eliminar permiso",
  permissionCreation: "Creación de permiso",
  permissionDeletedOK: "Permiso eliminado con éxito",
  permissionCreatedOK: "Permiso creado con éxito",
  permissionModifiedOK: "Permiso editado con éxito",
  noPermissions: "Aún no hay permisos creados",
  permissionDuplicatedData: "No puede existir más de un permiso que comparta el mismo código o nombre",

  //Family Settings
  familySettings: "Definición de familiares",
  newFamily: "Nuevo tipo de familiar",
  familyCode: "Código",
  familyName: "Nombre",
  cantDeleteFamily: "No es posible eliminar el tipo de familiar porque está en uso",
  deleteFamilyQuestion: "¿Está seguro que desea eliminar el tipo de familiar de la lista?",
  noFamilyFound: "No se encontraron definición de familiares",
  familyCodePlaceholder: "Ingrese código",
  familyNamePlaceholder: "Ingrese nombre",
  familyDuplicatedData: "No puede existir más de un tipo de familiar que comparta el mismo código o nombre",
  familyUpdatedOK: "Actualización finalizada con éxito",
  familyCreatedOK: "Creación finalizada con éxito",
  familyDeletedOK: "Eliminación finalizada con éxito",

  //Functions Settings
  functionsSettings: "Definición de funciones",
  newFunction: "Nueva función",
  deleteFunctionQuestion: "¿Esta seguro que desea eliminar la función de la lista?",
  noFunctionsFound: "No se encontraron funciones",
  functionCode: "Código",
  functionCodePlaceholder: "Ingrese código",
  functionName: "Nombre",
  functionNamePlaceholder: "Ingrese nombre",
  functionsUpdatedOK: "Actualización finalizada con éxito",
  functionsCreatedOK: "Creación finalizada con éxito",
  functionsDeletedOK: "Eliminación finalizada con éxito",
  functionsDuplicatedData: "No puede existir más de una función que comparta el mismo código o nombre",
  cantDeleteFunction: "No es posible eliminar la función porque está en uso",

  //Advances settings
  advancesSettings: "Anticipos para solicitudes",
  newAdvance: "Nuevo anticipo",
  noAdvances: "Aún no hay anticipos creados",
  advanceCreation: "Creación de anticipos",
  advanceName: "Nombre del anticipo",
  advanceType: "Tipo del anticipo",
  advanceCode: "Código del anticipo",
  advanceDeleteDialogTitle: "Eliminar anticipo",
  confirmDeleteAdvance: "¿Desea eliminar el anticipo?",
  advanceDeletedOK: "Anticipo eliminado con éxito",
  advanceCreatedOK: "Anticipo creado con éxito",
  advanceModifiedOK: "Anticipo editado con éxito",
  advanceDuplicatedData: "No puede existir más de un anticipo que comparta el mismo código o nombre",

  //Document publication
  documentPublication: "Publicación de documentos",
  newDocumentPublication: "Nuevo documento",
  documentReceipts: "Recibos",
  documentCertificates: "Certificados y Comprobantes",
  documentRequests: "Documentos de solicitudes",
  documentExternal: "Documentos externos",
  documentAssistance: "Informes de asistencia",
  documentByRequest: 'Documentos a pedido',
  documentsGenerated: 'Documentos auto generados durante descarga de carpeta digital',
  documentPublicationName: "Nombre del documento",
  documentPublicationRole: "Rol de visualización",
  documentPublicationType: "Tipo",
  confirmDeleteDocumentPublication: "¿Desea eliminar el documento publicado?",
  documentPublicationDeleteDialogTitle: "Eliminar publicación",
  documentPublicationCreation: "Publicación de documento",
  documentPublicationDeletedOK: "Publicación eliminada con éxito",
  documentPublicationCreatedOK: "Publicación creada con éxito",
  documentPublicationModifiedOK: "Publicación editada con éxito",
  noDocumentPublication: "Aún no hay documentos creados",
  documentIncludedFolder: "Incluido en descarga de carpeta digital",
  documentPublicationWorkflow: "Relacionado al workflow",
  documentPublicationCode: "Plantilla relacionada",
  documentPublicationSign: "Con firma digital",

  //Contract type settings
  contractTypesSettings: "Tipos de contrato",
  newContractType: "Nuevo tipo de contrato",
  noContractTypes: "No se encontraron tipos de contrato",
  contractTypeCode: "Código de contrato",
  contractTypeName: "Nombre de contrato",
  contractTypeCalification: "Calificación",
  contractTypeDeleteDialogTitle: "Eliminar tipo de contrato",
  confirmDeleteContractType: "¿Desea eliminar el tipo de contrato?",
  contractTypeCreation: "Creación de tipo de contrato",
  contractTypeDuplicatedData: "No puede existir más de un tipo de contrato que comparta el mismo código o nombre",
  contractTypeDeletedOK: "Tipo de contrato eliminado con éxito",
  contractTypeCreatedOK: "Tipo de contrato creado con éxito",
  contractTypeModifiedOK: "Tipo de contrato editado con éxito",
  cantDeleteContractType: "No es posible eliminar el tipo de contrato porque está en uso",

  //Concept groups
  conceptGroups: "Grupos para conceptos",
  newConceptGroup: "Nuevo grupo",
  conceptGroupNamePlaceholder: "Ingrese nombre",
  conceptGroupName: "Nombre",
  conceptGroupCode: "Número",
  noConceptGroupsFound: "No se encontraron grupos para conceptos",
  conceptsInUse: "No se puede modificar el concepto por estar en uso",

  //Contractor settings
  contractorCode: "Código de contratista",
  contractorName: "Nombre de contratista",
  contractorAddress: "Dirección",
  contractorContactName: "Nombre de contacto",
  contractorContactMail: "Mail de contacto",
  contractorContactPhone: "Telefono de contacto",
  contractorType: "Tipo",
  contractorSettings: "Contratistas",
  newContractor: "Nuevo contratista",
  contractorDeleteDialogTitle: "Eliminar contratista",
  confirmDeleteContractor: "¿Desea eliminar al contratista?",
  contractorCreation: "Creación de contratista",
  noContractors: "No se encontraron contratistas",
  contractorDuplicatedData: "No puede existir más de un contratista que comparta el mismo código o nombre",
  contractorDeletedOK: "Contratista eliminado con éxito",
  contractorCreatedOK: "Contratista creado con éxito",
  contractorModifiedOK: "Contratista editado con éxito",
  cantDeleteContractor: "No es posible eliminar al contratista porque está en uso",

  //Functionary type settings
  functionaryTypesSettings: "Tipos de funcionario",
  newFunctionaryType: "Nuevo tipo de funcionario",
  noFunctionaryTypes: "No se encontraron tipos de funcionario",
  functionaryTypeCode: "Código de funcionario",
  functionaryTypeName: "Nombre de funcionario",
  functionaryTypeProcess: "Procesos automáticos",
  functionaryTypeRequiresClient: "Requiere cliente/proveedor",
  functionaryTypePlant: "Personal de planta",
  functionaryTypeNoPlant: "Externos o Subcontratos",
  functionaryTypeDeleteDialogTitle: "Eliminar tipo de funcionario",
  confirmDeleteFunctionaryType: "¿Desea eliminar el tipo de funcionario?",
  functionaryTypeCreation: "Creación de tipo de funcionario",
  functionaryTypeDuplicatedData: "No puede existir más de un tipo de funcionario que comparta el mismo código o nombre",
  functionaryTypeDeletedOK: "Tipo de funcionario eliminado con éxito",
  functionaryTypeCreatedOK: "Tipo de funcionario creado con éxito",
  functionaryTypeModifiedOK: "Tipo de funcionario editado con éxito",
  cantDeleteFunctionaryType: "No es posible eliminar el tipo de funcionario porque está en uso",

  locationSettings: "Definición de lugares de trabajo",

  //Works and tasks
  worksAndTasksSettings: "Definición de obras/faenas",

  divisionsSettings: "Unidades de negocio",

  //Concepts Formulas Functions
  conceptsAndFormulas: "Conceptos y fórmulas",
  conceptsFormulasFunctions: "Conceptos, fórmulas y funciones",

  //accumulatorSettings
  accumulatorSettings: "Definición de acumuladores",

  //organizationalDefinitions
  organizationalDefinitions: "Definición de la organización",

  //allergies exams diseases
  allergiesExamsDiseases: "Definición de perfil médico",

  //calculationReferences
  calculationReferences: "Referencias de cálculo",

  //entry exit types
  entryExitTypes: "Definición de contrataciones",
  thirdPartySettings: "Definición de terceros",
  locationSettingsBox: "Definición de ubicaciones",

  organizationStructure: "Estructura departamental",
  geographicalStructure: "Organización geográfica",
  //issue Types Settings
  issueTypesSettings: "Definición de incidencias",
  absenceTypes: "Tipos de inasistencia",
  permissionTypes: "Tipos de permiso",
  licenceTypes: "Tipos de licencia",

  //positionsClassification
  positionsClassification: "Clasificaciones de cargos",

  managementDefinition: "Definición de gestión",

  alertsSettings: "Definición de Alertas/Avisos",

  conceptsSetSettingsTitle: "Conjunto de conceptos",
  thirdPartyClassification: "Clasificación de terceros",
  thirdParty: "Terceros",
  laborUnions: "Sindicatos",
  paymentTypes: "Formas de pago",

  documentTemplates: "Plantillas para documentos",
  assetsAndDiscountsScreenSelector: "Haberes y descuentos",
  assetsAndDiscountsCreation: "Creación de haberes y descuentos",

  digitalWarehouseFileTypes: "Tipos de archivo de bodega digital",

  trainingData: "Definición de formación",
  schooling: "Escolaridades",
  studies: "Estudios",
  specializations: "Especializaciones",
  languages: "Idiomas",
  affiliationInstitutionDefinition: "Definición de instituciones de afiliación",
  retirementDefinition: "Previsión",
  healthDefinition: "Salud",

  ratingsAndGeneralValues: "Índices y valores generales",
  familyAsignations: "Asignaciones familiares",
  ratings: "Índices",
  taxTable: "Tabla de impuestos",

  entryByImport: "Ingreso via importación",
  processScheduling: "Programación de procesos actuales",
  digitalWarehouse: 'Bodega digital',
  kpiDefinition: 'Definición de KPI',
  geoQuery: 'Consultas para geoanálisis',
  queryDefinition: 'Definición de consultas',
  generalValues: 'Valores generales',
  conceptStructure: 'Estructura de ingreso de conceptos',
  conceptsSet: 'Conjunto de conceptos',
  extraHoursAuthorization: "Autorización de horas extras",
  holidayTable: 'Tabla de vacaciones',
  extraHoursTable: 'Tipos de horas extras',
  festivitiesTable: 'Feriados y días festivos',
  groupClassification: 'Clasificación de grupos',
  payrollTypesSettings: 'Tipos de nómina',
  entryPerConcept: 'Ingreso por concepto',
  entryPerSociety: 'Ingreso por sociedad',
  assetsDiscountsSummary: 'Resumen de haberes y descuentos',
  attendanceReport: 'Consulta de presentismo',
  entryPerConceptSet: 'Ingreso por conjunto de conceptos',
  entryTypes: 'Motivos de ingreso',
  exitTypes: 'Motivos de egreso',
  allergiesSettings: 'Alergias',
  examsSettings: 'Exámenes',
  diseasesSettings: "Enfermedades",
  categoriesSettings: 'Categorías',
  specialtiesSettings: 'Especialidades',
  userAlertsSettings: 'Alertas y avisos del usuario',
  systemAlertsSettings: 'Alertas y avisos del sistema',

  massEntry: "Ingreso masivo",
  conceptsAndFormulasScreenSelector: 'Conceptos, fórmulas y funciones',
  calculationsFunctions: 'Funciones de cálculo',

  CALC_ONLINE: 'Habilitar procesos en línea',
  SHOW_9BOXES_C: 'Muestra clasificación 9-Boxes al colaborador',
  MANAGER_GESPER: 'Habilitar a managers la gestión directa de permisos',
  ORGCHART: 'Publicar organigrama',
  MANAGER_SEE_UNPUBLISHED_PROCESSES: 'Manager pueden ver procesos de cálculo sin publicar',
  SHOW_POINTS_TO_REDEEM: 'Muestra puntos para canje en el Medallero',
  MANAGER_GESVAC: 'Habilitar a managers la gestión directa de vacaciones',
  specialProcessDefinition: 'Definición de procesos especiales',
  accountingImputations: "Imputaciones contables",
  typesOfBenefits: "Tipos de beneficios",
  ratingScales: "Escalas para calificaciones",
  typesOfScore: "Notas para evaluaciones",
  benefitsForPlans: "Beneficios para planes",
  recognitionPlansAssignment: "Asignación de reconocimientos",
  organizationalBenefits: "Beneficios organizacionales",
  dataCapture: "Ingreso de datos vía API",
  myDesktop: "Mi escritorio",
  specialProcessExecution: 'Ejecución de procesos especiales',
  myPeopleSchedule: 'Gestión de horarios',
  mySchedule: 'Mis horarios programados',
  rebuildAccumulators: "Recomposición de acumuladores",

  timeDefinition: "Definición de tiempos",
  turnDefinition: "Definición de turnos",
  schedulesDefinition: "Definición de horarios",
  schedulesProgramming: "Programación de horarios",
  historicAccumulators: "Acumuladores históricos",
  assetsDiscountsEntryByInstallments: "Ingreso por cuotas",
  alertsTemplates: "Plantillas de WhatsApp",
  calendarDefinition: "Definición de calendarios",
  issueManagementScreenSelector: "Gestión de incidencias",
  issueSummary: "Resumen de incidencias",
  issueLicences: "Gestión de licencias médicas",
  issuePermissions: "Gestión de permisos",
  issueAbsences: "Gestión de inasistencias",
  issueVacations: "Gestión de vacaciones",
  consentTypes: "Tipos de consentimiento",
  benefitsCenter: "Centros de costo",
  projects: "Proyectos",
  consentPublication: "Publicación de consentimientos",

  VACATIONS_ENABLED: 'Habilitar solicitud de vacaciones',
  PERMISSIONS_ENABLED: 'Habilitar solicitud de permisos',
  LOANS_ENABLED: 'Habilitar solicitud de anticipos',
  historicalProcesses: "Procesos históricos",
  myApprovedConsents: "Mis consentimientos aprobados",
  consentsScreenSelector: "Consentimientos",
  documentsScreenSelector: "Documentos",
  pollScreenSelector: "Encuestas",
  PROCESS_USER: 'Código de usuario para cálculos en alertas',
  userTablesDefinition: 'Editores de tablas de usuario',
  extensibility: 'Extensibilidad',
  catalogs: 'Catálogo de base de datos',
  ESQVAC: 'Esquema de cálculo de vacaciones',
  userTables: 'Editor de tablas de usuario',
  relatedTablesDefinition: 'Definición de tablas relacionadas',
  extensibilityScreenSelector: 'Modelamiento',
  systemTablesDefinition: 'Editores de tablas del sistema',
  systemTables: 'Editor de tablas del sistema',
  GT_ALERT2: 'Trabajo en día no programado',
  GT_ALERT3: 'Marcas inconsistentes',
  GT_ALERT4: 'Hrs. Ext. Sin Autorizar',
  GT_ALERT5: 'Atraso en la Entrada',
  GT_ALERT6: 'Ausencias',
  GT_ALERT7: 'Retiro Anticipado',
  GT_ALERT8: 'Demora en el Break',
  GT_ALERT9: 'Cambio del horario planificado',
  TRACE_DATA: 'Activar la trazabilidad de datos',
  CONNECTED_USERS_ENABLED: "Visualizar los usuarios en línea",
  assistanceAnalysis: 'Análisis de asistencia',
  GT_PROC: 'Proceso especial a ejecutar para el análsisis de asistencia',
};

export default stringsEN;