export const errorStringsES = {
  //APP ERRORS
  invalidInfo:
    "Para recuperar o asignar el PIN de acceso es necesario que ingrese el código de CLIENTE y su USUARIO personal",
  invalidPin: "El pin debe contener entre 4 y 10 caracteres",
  templateAlreadyExists: "Ya existe una plantilla con el nombre indicado",
  selectSociety: "Seleccione una sociedad para continuar",
  selectColor: "Seleccione un color para continuar",
  loginError: "Usted no puede ingresar como {role} con el Cliente, Usuario y PIN que ha ingresado",
  noCurrentPin: "Falta ingresar el PIN actual",
  noNewPin: "Falta ingresar el nuevo PIN",
  noNewPinConfirm: "Falta re-ingresar el nuevo PIN",
  pinsMustBeEqual: "No coinciden los nuevos PINs ingresados",
  pinsMustBeDifferent: "El pin actual no debe coincidir con el nuevo",
  pinMustHaveMinCharacters: "El PIN ingresado debe tener entre 4 y 10 caracteres",
  pinMustHaveMaxCharacters: "El PIN ingresado debe tener entre 4 y 10 caracteres",
  motiveMustHaveMinCharacters: "El motivo ingresado debe tener hasta 254 caracteres",
  motiveMustHaveMaxCharacters: "El motivo ingresado debe tener hasta 254 caracteres",
  noMotive: "Falta ingresar el motivo",
  noPin: "Falta ingresar el PIN",
  pinMustBeAlphanumeric: "El nuevo PIN no puede contener caracteres que no sean números y/o letras",
  noEmployeesFound: "No se ha encontrado personal de acuerdo con los datos de búsqueda ingresados",
  noLoansFound: "No se han encontrado prestamos",
  invalidTitle: "El titulo obtenido es inválido",
  invalidSubtitle: "El subtitulo obtenido es inválido",
  invalidData: "La información obtenida es inválida",
  invalidAlt: "La imagen obtenida es inválida",
  //SV ERRORS
  defaultException: "Ocurrió un problema, intente nuevamente más tarde",
  invalidParams: "Ocurrió un problema, intente nuevamente más tarde",
  invalidToken: "Su sesión no es válida, intente logearte de nuevo",
  tokenMissing: "Ocurrió un problema, debe relogearse en la aplicación",
  userNotFound: "Su usuario no tiene acceso, debe solicitar permiso al Administrador de la plataforma",
  googleError: "Error al intentar ingresar con google",
  defaultErrorDialogTitle: "Mensaje",
  defaultError: "Ocurrió un problema, intente nuevamente más tarde",
  invalid: "Inválido",
  pinValidationError: "El PIN ingresado como actual es incorrecto",
  completeWithValidInformation: "Complete con información válida",
  serverError: "Error de servidor",
  societyNotFound: "Sociedad no encontrada",
  loginInvalidCredentials: "Credenciales inválidas",
  userAlreadyExists: "El usuario ya existe",
  googleDataError: "Error de google",
  unAuthorized: "No autorizado",
  emptyEmployees: "Sin empleados",
  invalidImages: "Imágenes inválidas",
  clientNotExist: "El cliente no existe",
  validationError: "Error de validación",
  noPollError: "Error al obtener la encuesta",
  nameNotValid: "Ingrese el nombre de la plantilla",
  templateMustHaveMinCharacters: "El nombre de la plantilla debe tener entre 4 y 60 caracteres",
  templateMustHaveMaxCharacters: "El nombre de la plantilla debe tener entre 4 y 60 caracteres",
  mustSelectPermissions: "Seleccione el rol de visualización",
  noAssistanceFound: "No se han encontrado registros de asistencia",
  tooManyLoginRequests:
    "Su usuario ha sido bloqueado por haber realizado demasiados intentos de inicio de sesión fallidos. Vuelva a intentarlo en {minutos} minutos",
  certificateNotCreated: "Certificado no creado.",
  certificatesNotAvailable: "No hay certificados disponibles",
  receiptsNotAvailable: "No hay recibos disponibles",
  selectCertificateType: "Seleccione un tipo de certificado",
  receiptNotCreated: "Recibo no creado.",
  receiptNoMail: "No se pueden seleccionar documentos pertenecientes a usuarios sin mail",
  selectReceiptType: "Seleccione un tipo de recibo",
  selectCalcProcess: "Seleccione un proceso de cálculo",
  noRequests: "No se encontraron solicitudes",
  dateErrorRequests: "Las fechas recibidas no estan permitidas",
  validationErrorRequests: "Ingrese datos válidos",
  errorDaysQuantityRequests: "Se recibió una cantidad de días incorrecta",
  overlapErrorRequests: "Las fechas recibidas se solapan con otra solicitud de vacaciones del usuario",
  requiredFieldEmpty: "Hay datos obligatorios vacíos",
  tagRepeated: "Ya existe una etiqueta con el mismo nombre",
  totemAlreadyExists: "Ya existe un totem con el mismo código",
  //Firmantes
  verifyDateTime: "Verifique las fechas y horas",
  invalidUser: "Usuario inválido",
  signerAlreadyExists: "Firmante duplicado",
  invalidDocs: "Documentos inválidos",
  errorGettingDocsType: "Error al obtener los tipos de documentos",
  noRequestedDocuments: "No existen documentos solicitados",
  incorrectPin: "PIN incorrecto",
  appealExistsRiseErrorRequest: "No se pudo dar de alta la solicitud de aumento de sueldo ya que el colaborador seleccionado posee una en curso",
  appealExistsFireErrorRequest: "No se pudo dar de alta la solicitud de desvinculación ya que el colaborador seleccionado posee una en curso",
  certificateNoMail: "Se firmarán {amount} documentos de un total de {total} porque hay Colaboradores sin email asignado",
  assistanceAlreadyDone: "Usted no puede marcar asistencia el día de hoy ya que tiene un permiso o vacaciones en esta fecha",
  assistanceBlocked: "Usted no puede marcar asistencia, por favor póngase en contacto con un administrador",
  incorrectQuery: "La consulta que está intentando importar se encuentra dañada",
  noNextSigner: 'Debe seleccionar al siguiente firmante',
};
export default errorStringsES;
