export const errorStringsEN = {
  //APP ERRORS
  invalidInfo:
    "You need to enter your user and client code to recover or assign the access PIN",
  invalidPin: "PIN must contain between 4 and 10 characters",
  templateAlreadyExists: "There is already a template with that name",
  selectSociety: "Select a society to continue",
  selectColor: "Select a color to continue",
  loginError: "You cannot login as a {role} with the Client, User and PIN entered",
  noCurrentPin: "You need to enter the current PIN",
  noNewPin: "You need to enter the new PIN",
  noNewPinConfirm: "You need to enter the new PIN again",
  pinsMustBeEqual: "Both PINs entered do not match",
  pinsMustBeDifferent: "Current PIN cannot be the same as the new one",
  pinMustHaveMinCharacters: "New PIN entered must contain between 4 and 10 characters",
  pinMustHaveMaxCharacters: "New PIN entered must contain between 4 and 10 characters",
  motiveMustHaveMinCharacters: "Motive entered must contain 254 characters maximum",
  motiveMustHaveMaxCharacters: "Motive entered must contain 254 characters maximum",
  noMotive: "You need to enter the motive",
  noPin: "You need to enter the PIN",
  pinMustBeAlphanumeric: "New PIN cannot contain characters other than numbers and/or letters",
  noEmployeesFound: "No employees have been found.",
  noLoansFound: "No loans have been found.",
  invalidTitle: "Title is invalid",
  invalidSubtitle: "Subtitle is invalid",
  invalidData: "Information is invalid",
  invalidAlt: "Image is invalid",
  //SV ERRORS
  defaultException: "There has been a problem, please try again later",
  invalidParams: "There has been a problem, please try again later",
  invalidToken: "Your session is not valid, try logging in again",
  tokenMissing: "There has been a problem, you must log in again",
  userNotFound: "Ask the administrator for permission in order to access",
  googleError: "Error trying to access with Google",
  defaultErrorDialogTitle: "Message",
  defaultError: "There has been a problem with the server, please try again later",
  invalid: "Invalid",
  pinValidationError: "Current PIN entered is invalid",
  completeWithValidInformation: "Complete with valid information",
  serverError: "Server Error",
  societyNotFound: "Society not found",
  loginInvalidCredentials: "Invalid Credentials",
  userAlreadyExists: "User already exists",
  googleDataError: "Google Error",
  unAuthorized: "Unauthorized",
  emptyEmployees: "No employees",
  invalidImages: "Invalid images",
  clientNotExist: "Client does not exist",
  validationError: "Validation Error",
  noPollError: "Error obtaining poll",
  nameNotValid: "Enter template name",
  templateMustHaveMinCharacters: "Template name must contain between 4 and 60 characters",
  templateMustHaveMaxCharacters: "Template name must contain between 4 and 60 characters",
  mustSelectPermissions: "Select visualization role",
  noAssistanceFound: "No assistance registries found",
  tooManyLoginRequests:
    "Your user has been blocked after too many failed login attempts. Try again in {minutos} minutes",
  certificateNotCreated: "Certificate not created.",
  certificatesNotAvailable: "No certificates available",
  receiptsNotAvailable: "No receipts available",
  selectCertificateType: "Select a certificate type",
  receiptNotCreated: "Receipt not created.",
  receiptNoMail: "Documents from users with no email can't be selected",
  selectReceiptType: "Select a receipt type",
  selectCalcProcess: "Select a calculation method",
  noRequests: "No requests found",
  dateErrorRequests: "Dates not allowed",
  validationErrorRequests: "Enter valid data",
  errorDaysQuantityRequests: "Invalid Amount of days",
  overlapErrorRequests: "Dates overlap with another vacation request",
  requiredFieldEmpty: "There are empty required fields",
  tagRepeated: "There is a tag with the same name",
  totemAlreadyExists: "There is already a totem with the same code entered",
  //Firmantes
  verifyDateTime: "Verify dates and times",
  invalidUser: "Invalid user",
  signerAlreadyExists: "Duplicated signer",
  invalidDocs: "Invalid documents",
  errorGettingDocsType: "Error getting document types",
  noRequestedDocuments: "There are no requested documents",
  incorrectPin: "Wrong PIN",
  appealExistsRiseErrorRequest: "The request for a salary increase could not be registered since the selected collaborator has one in progress",
  appealExistsFireErrorRequest: "The termination request could not be registered as the selected collaborator has one in progress",
  certificateNoMail: "{amount} documents from a total of {total} will be signed",
  assistanceAlreadyDone: "You cannot register attendance today as you have a leave or vacation on this date",
  assistanceBlocked: "You cannot register your assistance, please contact an administrator",
  incorrectQuery: "The query you are trying to import is corrupt"
};
export default errorStringsEN;
