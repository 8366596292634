import stringsES from "./stringsES";
import stringsEN from "./stringsEN";
import errorStringsES from "./errorsES";
import errorStringsEN from "./errorsEN";

let language = "es";

export const getLanguage = () => {
  return language;
}

export const setLanguage = lang => {
  language = lang;
};

export const getLocalizedString = string => {
  switch (language) {
    case "es":
      return stringsES[string];
    case "en":
      return stringsEN[string];
    default:
      return stringsES[string];
  }
}

export const getLocalizedErrorString = string => {
  switch (language) {
    case "es":
      return errorStringsES[string];
    case "en":
      return errorStringsEN[string];
    default:
      return errorStringsES[string];
  }
}
